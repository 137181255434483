import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { APIFactoryEndPoints } from 'src/app/common/apifactory-end-points';
import { FileService } from 'src/app/common/File-Service';
import { LocalStorageService } from 'src/app/common/LocalStorageService';
import { Constant } from 'src/app/shared/models/constant';
import { GoogleCustomer } from 'src/app/shared/models/googlecustomer';
import { Product } from 'src/app/shared/models/product';
import { WebOrderLocationSetup } from 'src/app/shared/models/weborderlocationsetup';
import { PinDialogService } from 'src/app/shared/services/pin-dialog.service';
import { ToastService } from 'src/app/shared/services/toastr.service';
import { UserService } from 'src/app/shared/services/user.service';
import { getWindow } from 'ssr-window';
import { PinDialogComponent } from '../pin-dialog/pin-dialog.component';
import { ProductService } from 'src/app/shared/services/product.service';

@Component({
  selector: 'app-store-pickup-dialog',
  templateUrl: './store-pickup-dialog.component.html',
  styleUrls: ['./store-pickup-dialog.component.scss']
})
export class StorePickupDialogComponent implements OnInit {

  selectedOption: string = '';
  storeLocationList: WebOrderLocationSetup[] = [];
  nearestLocationList: WebOrderLocationSetup[] = [];
  storeLocation: WebOrderLocationSetup;
  url: string;
  currentUrl: string;
  googleCustomer: GoogleCustomer;
  availableOptions = ['storePickup'];
  deliveryType: string;
  latitudeMap: number | null = null;
  longitudeMap: number | null = null;

  private cartSetUpSubscription: Subscription;
  private getStorePickupSubscription: Subscription;

  constructor(
    public dialogRef: MatDialogRef<StorePickupDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: any,
    private localStorage: LocalStorageService,
    private fileService: FileService,
    private apiFactory: APIFactoryEndPoints,
    private toastService: ToastService,
    private userService: UserService,
    private productService: ProductService,
    private navRoute: Router,
    private constant: Constant,
    private pinDialogService: PinDialogService,
    public dialog: MatDialog,
  ) {
    if (dialogData && dialogData.type) {
      this.selectedOption = dialogData.type;
    }
  }

  ngOnInit(): void {
    this.url = getWindow().location.hostname;
    this.currentUrl = this.navRoute.url.replace("/", "");
    console.log(this.currentUrl)
    this.googleCustomer = JSON.parse(this.localStorage.getItem("google_loggedIn_details"));
    this.deliveryType = JSON.parse(this.localStorage.getItem("delivery_type"));
    this.availableOptions = ['storePickup']; // Set available options based on your conditions

    // Check if only one option is available
    if (this.availableOptions.length === 1 && !this.selectedOption) {
      this.selectedOption = this.availableOptions[0];
    }
    // this.storeLocation = JSON.parse(this.localStorage.getItem("pickup_location"));
    this.setCurrentLocation();
    // this.getStoreLocationList();
  }

  private setCurrentLocation(): void {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          // Location permission granted, get coordinates
          this.latitudeMap = position.coords.latitude;
          this.longitudeMap = position.coords.longitude;
          console.log('Latitude:', this.latitudeMap, 'Longitude:', this.longitudeMap);

          // Call API with the fetched location
          this.getStoreLocationList();
        },
        (error) => {
          // Handle errors
          switch (error.code) {
            case error.PERMISSION_DENIED:
              this.toastService.showErrorToast('Location permission denied. Please allow location access in your browser settings.');
              break;
            case error.POSITION_UNAVAILABLE:
              this.toastService.showErrorToast('Location information is unavailable. Please try again later.');
              break;
            case error.TIMEOUT:
              this.toastService.showErrorToast('The request to get user location timed out. Please try again.');
              break;
            default:
              this.toastService.showErrorToast('An unknown error occurred. Please try again.');
              break;
          }

          // Call API with null latitude & longitude (Location disabled case)
          this.latitudeMap = null;
          this.longitudeMap = null;
          this.getStoreLocationList();
        },
        {
          enableHighAccuracy: true,
          timeout: 30000,
          maximumAge: 0
        }
      );
    } else {
      // If browser does not support geolocation
      this.toastService.showErrorToast('Geolocation is not supported by your browser.');

      // Call API with null values
      this.latitudeMap = null;
      this.longitudeMap = null;
      this.getStoreLocationList();
    }
  }

  getStoreLocationList() {
    // this.storeLocationList = this.fileService.getFileData("locationWithLongLat");
    this.getStorePickupSubscription = this.pinDialogService.getStoreLocationList(this.apiFactory.getActiveStorePickupLocationByUrl,
      this.url, this.latitudeMap != null ? this.latitudeMap.toString() : null, this.longitudeMap != null ? this.longitudeMap.toString() : null)
      .subscribe(data => {
        console.log(data);
        if (data.length > 0) {
          this.storeLocationList = data;
          this.nearestLocationList = data.length > 0 ? data.slice(0, 5) : [];
          this.filteredLocations = this.storeLocationList;
        } else {
          this.toastService.showErrorToast('No store for pickup');
        }
      });
  }

  getDistanceInKm(distanceInMeters: any): string {
    if (!distanceInMeters || isNaN(Number(distanceInMeters))) {
      return 'Distance not available';  // Show a friendly message
    }

    const distanceInKm = Number(distanceInMeters) / 1000; // Convert meters to km

    if (distanceInKm <= 1.75) {
      return 'about 1 km';
    } else if (distanceInKm <= 2.75) {
      return 'about 2 km';
    } else if (distanceInKm <= 3.75) {
      return 'about 3 km';
    } else if (distanceInKm <= 4.75) {
      return 'about 4 km';
    } else if (distanceInKm <= 5.75) {
      return 'about 5 km';
    } else {
      return 'more than 5 km';
    }
  }


  onOptionChange(option: string): void {
    this.selectedOption = option;
    if (option === 'delivery') {
      this.storeLocation = null;
    }
  }

  showNearestLocation: boolean = false;
  toggleNearestLocation() {
    this.showNearestLocation = !this.showNearestLocation; // Toggle state
    this.storeLocation = null;
  }

  onLocationSelect(storeLocation: WebOrderLocationSetup) {
    console.log(storeLocation)
    this.storeLocation = storeLocation;
  }

  searchText: string = '';
  filteredLocations: any[] = [];
  filterLocations(event: Event) {
    event.stopPropagation(); // Prevents dropdown from closing
    const searchLower = this.searchText.toLowerCase();
    const allLocations = this.showNearestLocation ? this.nearestLocationList : this.storeLocationList;

    this.filteredLocations = allLocations.filter(loc =>
      loc.locationName.toLowerCase().includes(searchLower) ||
      loc.cityName.toLowerCase().includes(searchLower)
    );
  }

  onContinueClick(selectedOption: string) {
    if (selectedOption == 'storePickup') {
      if (!this.storeLocation) {
        this.toastService.showErrorToast('Select Location');
        return;
      }

      this.localStorage.setItem("delivery_type", JSON.stringify(selectedOption));
      this.localStorage.setItem("location_code", JSON.stringify(this.storeLocation.locationCode));
      this.localStorage.setItem("company_code", JSON.stringify(this.storeLocation.companyCode));
      this.localStorage.setItem("state_code", JSON.stringify(this.storeLocation.stateCode));
      this.localStorage.setItem("min_selflife", JSON.stringify(this.storeLocation.minSelfLife));
      this.localStorage.setItem("parent_location_code", JSON.stringify(this.storeLocation.parentLocationCode));
      this.localStorage.setItem("pickup_location", JSON.stringify(this.storeLocation));

      const cartProducts: Product[] = JSON.parse(this.localStorage.getItem("avct_item")) || [];

      if (cartProducts.length > 0) {
        // Step 1: Move all cart products to wishlist
        // this.productService.addFavouriteProducts(cartProducts);

        // Step 2: Empty the cart
        // if (this.googleCustomer && this.googleCustomer.email) {
        //   // If user is logged in, call API to empty cart in backend
        //   this.cartSetUpSubscription = this.userService.emptyProductCart(this.apiFactory.emptyProductCart, this.googleCustomer.email)
        //     .subscribe((data: any) => {
        //       this.toastService.showSuccessToast("Your cart is now empty due to the store change.");
        //       this.localStorage.removeItem("avct_item"); // Clear cart locally
        //       if (this.constant.isStorePickupRoute(this.currentUrl)) {
        //         window.location.reload();
        //       }
        //       this.dialogRef.close('storePickup');
        //     });
        // } else {
        //   // If user is not logged in, just clear cart locally
        //   this.toastService.showSuccessToast("Your cart is now empty due to the store change.");
        //   this.localStorage.removeItem("avct_item"); // Clear cart locally
        //   if (this.constant.isStorePickupRoute(this.currentUrl)) {
        //     window.location.reload();
        //   }
        //   this.dialogRef.close('storePickup');
        // }
        this.dialogRef.close('storePickup');
      } else {
        this.dialogRef.close('storePickup');
      }
    } else if (selectedOption == 'delivery') {
      this.dialogRef.close('delivery');
      this.storeLocation = null;
    } else {
      this.toastService.showSuccessToast("Pickup or Delivery");
    }
  }


  openPinChangeDialogCustom() {
    let pincode = this.localStorage.getItem('pincode_item');

    if (!pincode) {
      const dialogRef = this.dialog.open(PinDialogComponent, {
        width: '300px',
        panelClass: 'pin-dialog-container',
      });

      dialogRef.afterClosed().subscribe((result) => {
        console.log(result);
        if (result?.result) {
          this.toastService.showSuccessToast('Pin changed successfully');

        }
      });
    } else {
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

}
