import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormArray, FormGroup, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import moment from 'moment';
import { Subscription } from 'rxjs';
import { APIFactoryEndPoints } from 'src/app/common/apifactory-end-points';
import { FileService } from 'src/app/common/File-Service';
import { LocalStorageService } from 'src/app/common/LocalStorageService';
import { Color } from 'src/app/shared/models/color';
import { MakeToOrderDetails } from 'src/app/shared/models/maketoorderdetails';
import { Product } from 'src/app/shared/models/product';
import { ProductComposition } from 'src/app/shared/models/productcomposition';
import { ProductShape } from 'src/app/shared/models/productshape';
import { WebUserInfo } from 'src/app/shared/models/web-user-info';
import { WebOrderCustomizeGroupSetup } from 'src/app/shared/models/webordercustomizegroupsetup';
import { WebOrderMinimumDateTimeSetup } from 'src/app/shared/models/weborderminimumdatetimesetup';
import { CommonService } from 'src/app/shared/services/CommonService';
import { ProductService } from 'src/app/shared/services/product.service';
import { ToastService } from 'src/app/shared/services/toastr.service';
import { getWindow } from 'ssr-window';
import { StorePickupDialogComponent } from '../store-pickup-dialog/store-pickup-dialog.component';
import { PinDialogComponent } from '../pin-dialog/pin-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { GoogleCustomer } from 'src/app/shared/models/googlecustomer';
import { UserService } from 'src/app/shared/services/user.service';
import { WebOrderCustomProduct } from 'src/app/shared/models/web-order-custom-product';
import { WebOrderMakeToOrderRate } from 'src/app/shared/models/web-order-make-to-order-rate';
import { Cess } from 'src/app/shared/models/cess';
import { Tax } from 'src/app/shared/models/tax';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { ProductsWithWebOrderOffers } from 'src/app/shared/models/productswithweborderoffers';
import { ViewOfferSetUpDto } from 'src/app/shared/models/viewoffersetupdto';
import { OfferDetailsDialogComponent } from '../offer-details-dialog/offer-details-dialog.component';
import { OfferDialogComponent } from '../offer-dialog/offer-dialog.component';
import { BulkDiscountDto } from 'src/app/shared/models/bulkdiscountdto';
import { WebOrderLocationSetup } from 'src/app/shared/models/weborderlocationsetup';

@Component({
  selector: 'app-customize-product',
  templateUrl: './customize-product.component.html',
  styleUrls: ['./customize-product.component.scss']
})
export class CustomizeProductComponent implements OnInit {

  categorieList = [
    { name: 'photoCakes', label: 'Photo Cakes', img: 'assets/img/Photocakes.png' },
    { name: 'customCakes', label: 'Custom Cakes', img: 'assets/img/Customcakes.png' },
    { name: 'photoFrames', label: 'Photo Frames', img: 'assets/img/Photoframes.png' },
    { name: 'chocolate', label: 'Chocolate', img: 'assets/img/Chocolate.png' }
  ];

  selectedCategory: string | null = null;
  selectedCategoryName: string = '';
  displayedProducts: Product[] = [];
  selectedProduct: Product = null;
  allProductList: Product[] = [];
  createSetUpForm: UntypedFormGroup;
  currentUrl: string;
  productCompositionList: ProductComposition[] = [];
  productColorList: Color[] = [];
  productShapeList: ProductShape[] = [];
  customizeList: WebOrderCustomizeGroupSetup[] = [];
  showColor: boolean = false;
  showComposition: boolean = false;
  showShape: boolean = false;
  showDimension: boolean = false;
  makeToOrderDetailsList: MakeToOrderDetails;
  webOrderMinimumDateTimeList: WebOrderMinimumDateTimeSetup = new WebOrderMinimumDateTimeSetup();
  timeSlot2: string[][] = [];
  timeSlotAvl: string[] = [];
  todayDate: Date = new Date();
  maxDate: string;
  minTimeInHr: string;
  qty: number;
  discount: number;
  rate1: any;
  taxRate1: any;
  cessRate1: any;
  minimumQty: any;
  showUpdate: boolean = false;
  pincode: string;
  googleCustomer: GoogleCustomer;
  resp: boolean = false;
  customCakeList: WebOrderCustomProduct[] = [];
  customCake: WebOrderCustomProduct;
  createSetUpFormCustomCake: UntypedFormGroup;
  webOrderMakeToOrderRate: WebOrderMakeToOrderRate;
  productWithOffers: ProductsWithWebOrderOffers[];
  offerTypeArray: string[] = [];
  offerObj: any;
  productOffer: Product;
  discountOffer: number;
  bulkDiscountOffer: BulkDiscountDto;

  categories = {
    photoCakes: [],
    customCakes: [],
    photoFrames: [],
    chocolate: [],
  };

  fileInputs = []; // Number of file inputs
  files: { [key: string]: File } = {}; // Store files dynamically

  //Subscription
  private customizeDetailsSubcription: Subscription;
  private webOrderTimeSlotSubcription: Subscription;
  private makeToOrderSubcription: Subscription;
  private saveWebUserInfoSubscription: Subscription;
  private webOrderMinimumDateTimeSubcription: Subscription;
  private getCustomCakeImageSubscription: Subscription;
  private offerSubscription: Subscription;
  private productOfferSubscription: Subscription;
  private productDiscountSubscription: Subscription;
  private productBulkDiscountSubscription: Subscription;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private fileService: FileService,
    private localStorage: LocalStorageService,
    private apiFactory: APIFactoryEndPoints,
    private commonService: CommonService,
    private productService: ProductService,
    private toastrService: ToastService,
    public dialog: MatDialog,
    private navRoute: Router,
    private userService: UserService,
    private domSanitizer: DomSanitizer
  ) { }

  ngOnInit(): void {
    this.currentUrl = getWindow().location.hostname;
    this.googleCustomer = JSON.parse(this.localStorage.getItem("google_loggedIn_details"));
    this.onCreateSetUp();
    this.onCreateSetUpCustomCake();
    this.getAllProducts();
    this.getWebOrderCustomizeDetails();
    this.getWebOrderTimeSlotSetUpList();
  }

  onCreateSetUp() {
    this.createSetUpForm = this.formBuilder.group({
      qty: ["", Validators.required],
      composition: [""],
      color: [""],
      shape: [""],
      dimension: [""],
      deliveryDate: ["", Validators.required],
      deliveryTime: ["", Validators.required],
      note: ["", [Validators.maxLength(40)]],
      subTotal: [""],
      productPrice: [0],
      productName: [""],
      minimum_Order_Time: [""],
      basePremiumFlavourPrice: [0],
      premiumFlavourPrice: [0]
    });

    // Add controls for multiple images dynamically
    // this.fileInputs.forEach((_, i) => {
    //   this.createSetUpForm.addControl('fileImage' + i, this.formBuilder.control(null));
    // });
  }

  getAllProducts() {
    this.allProductList = this.fileService.getFileData("allProductListCustomize");
    console.log(this.allProductList)
    this.categories.photoCakes = this.allProductList
      ?.filter(e => e.customizeType === 'Photo Cakes') // Filter for Photo Cakes
      .sort((a, b) => {
        // Define the order for sorting
        const order = ['Mini', 'Medium', 'Large'];

        // Find the index of the keywords in the product names
        const aIndex = order.findIndex(keyword => a.product_Name.includes(keyword));
        const bIndex = order.findIndex(keyword => b.product_Name.includes(keyword));

        // Sort by the found index (products not matching will be sorted to the end)
        return (aIndex !== -1 ? aIndex : order.length) - (bIndex !== -1 ? bIndex : order.length);
      });
    this.categories.photoFrames = this.allProductList?.filter(e => e.customizeType == 'Photo Frames');
    this.categories.chocolate = this.allProductList?.filter(e => e.customizeType == 'Caricatures');
    this.getOfferDetails();
  }

  getOfferDetails() {
    let productId: number[] = this.allProductList.map(element => element.product_Code);
    if (productId.length === 0) return;

    let type = this.deliveryType ? (this.deliveryType === 'storePickup' ? 'Pickup' : 'Delivery') : null;

    if (!type) {
      this.fetchOfferDetails(this.getLocationId(), productId);
      return;
    }

    this.offerSubscription = this.productService.getOfferAllowFor(
      this.apiFactory.getOfferAllowFor,
      this.currentUrl,
      type
    ).subscribe((data: string) => {
      if (data === 'Not Available') {
        console.log("No offers available.");
        return;
      }

      let locId = data === 'allowForWebsite'
        ? this.googleCustomer?.locationCode || 0
        : data === 'allowForLocation'
          ? this.getLocationId()
          : 0;

      this.fetchOfferDetails(locId, productId);
    });
  }

  fetchOfferDetails(locId: number, productId: number[]) {
    this.offerSubscription = this.productService.getOfferDetails(
      this.apiFactory.getOfferDetails,
      locId,
      productId,
      this.currentUrl
    ).subscribe((data: any) => {
      this.productWithOffers = data;
      console.log(this.productWithOffers);

      this.offerTypeArray = [];

      this.productWithOffers.forEach(element => {
        element.offerDetails.forEach(offerDetails => {
          if (!this.offerTypeArray.includes(offerDetails.schemeType)) {
            this.offerTypeArray.push(offerDetails.schemeType);
          }
        });
      });

      this.allProductList.forEach(element => {
        let offerData = this.productWithOffers.find(offer => offer.productCode === element.product_Code);
        if (offerData) {
          element.offerDetails = offerData.offerDetails;
          element.offers = offerData.offers;
        }
      });

      console.log(this.allProductList);
    });
  }

  getLocationId(): number {
    return JSON.parse(this.localStorage.getItem('location_code')) || 0;
  }

  viewOfferDetail(offers: ViewOfferSetUpDto[]) {
    console.log(offers);
    let dialogRef = this.dialog.open(OfferDetailsDialogComponent, {
      width: '550px',
      minHeight: '18%',
      maxWidth: '90%',
      maxHeight: '70%',
      autoFocus: false,
      data: { offerData: offers },
    });

    dialogRef.afterClosed().subscribe((res) => { });
  }

  // Method to handle category selection
  selectCategory(category: string) {
    this.selectedCategory = category;
    this.displayedProducts = this.categories[category];
    console.log(this.displayedProducts)
    this.selectedCategoryName = this.formatCategoryName(category);
    this.selectedProduct = null;
    this.resetFormAndFiles();
    if (this.selectedCategoryName == 'Custom Cakes') {
      this.openStorePickupDialogCustom();
    }
  }

  openStorePickupDialog(product: Product) {
    let deliveryType = JSON.parse(this.localStorage.getItem('delivery_type'));
    if (!deliveryType) {
      const dialogRef = this.dialog.open(StorePickupDialogComponent, {
        width: '300px',
        autoFocus: false,
        panelClass: 'store-dialog-container'
      });

      dialogRef.afterClosed().subscribe(result => {
        console.log(result)
        if (result) {
          if (result === 'storePickup') {
            this.toastrService.showSuccessToast("Store changed sucessfully");
            let storeLocation: WebOrderLocationSetup = JSON.parse(this.localStorage.getItem('pickup_location'));
            this.webOrderMinimumDateTimeList.deliveryTime = storeLocation?.minOrderTime;
            this.webOrderMinimumDateTimeList.maxDeliveryTime = storeLocation?.maxOrderTime;
            this.selectProduct(product);
          } else if (result === 'delivery') {
            this.openPinChangeDialog(product);
          }
        }
      });
    } else {
      if (deliveryType === 'storePickup') {
        let storeLocation: WebOrderLocationSetup = JSON.parse(this.localStorage.getItem('pickup_location'));
        this.webOrderMinimumDateTimeList.deliveryTime = storeLocation?.minOrderTime;
        this.webOrderMinimumDateTimeList.maxDeliveryTime = storeLocation?.maxOrderTime;
        this.selectProduct(product);
      } else if (deliveryType === 'delivery') {
        this.openPinChangeDialog(product);
      }
    }
  }

  openPinChangeDialog(product: Product) {
    this.pincode = this.localStorage.getItem("pincode_item");

    if (this.pincode == null) {
      const dialogRef = this.dialog.open(PinDialogComponent, {
        width: "300px",
        // data: product,
        panelClass: "pin-dialog-container",
      });

      dialogRef.afterClosed().subscribe((result) => {
        console.log(result)
        if (result?.result == true) {
          this.toastrService.showSuccessToast("Pin changed sucessfully");
          this.selectProduct(product);
          // window.location.reload();
        }
      });
    } else {
      this.selectProduct(product);
    }
  }

  selectProduct(product: Product) {
    this.getWebOrderTimeSlotSetUpList();
    var orderDuration = new Date();
    orderDuration.setHours(orderDuration.getHours() + Number.parseInt(this.webOrderMinimumDateTimeList?.deliveryTime?.split(":")[0]));
    orderDuration.setMinutes(orderDuration.getMinutes() + Number.parseInt(this.webOrderMinimumDateTimeList?.deliveryTime?.split(":")[1]));

    var maxOrderDuration = new Date();
    maxOrderDuration.setHours(maxOrderDuration.getHours() + Number.parseInt(this.webOrderMinimumDateTimeList?.maxDeliveryTime?.split(":")[0]));
    maxOrderDuration.setMinutes(maxOrderDuration.getMinutes() + Number.parseInt(this.webOrderMinimumDateTimeList?.maxDeliveryTime?.split(":")[1]));

    let minOrderDate = new Date();
    minOrderDate.setDate(minOrderDate.getDate() + product.minimum_Order_Time);

    this.maxDate = moment(maxOrderDuration).format("YYYY-MM-DD");
    this.selectedProduct = product;
    this.selectedProduct.maxDeliveryDate = this.maxDate;

    // Set todayDate to the later (greater) of orderDuration or minOrderDate
    this.todayDate = orderDuration > minOrderDate ? orderDuration : minOrderDate;
    this.minTimeInHr = moment(new Date()).format("hh:mm a");

    // Remove all existing file controls before adding new ones
    Object.keys(this.createSetUpForm.controls).forEach(controlName => {
      if (controlName.startsWith('fileImage')) {
        this.createSetUpForm.removeControl(controlName);
      }
    });

    // Clear fileInputs array
    this.fileInputs = [];

    // Dynamically add controls for the selected product's images
    for (let i = 0; i < this.selectedProduct.no_Of_Images; i++) {
      const controlName = 'fileImage' + i;
      this.createSetUpForm.addControl(controlName, this.formBuilder.control(null));
      this.fileInputs.push({ controlName }); // Track the new control in fileInputs
    }

    this.resetFormAndFiles();

    this.createSetUpForm.patchValue({
      productPrice: Number(this.selectedProduct.net_Sale_Price.toFixed(2)),
      productName: this.selectedProduct.product_Name,
      minimum_Order_Time: this.selectedProduct.minimum_Order_Time,
      qty: this.selectedProduct.minimum_Order_Quantity,
      subTotal: Number((this.selectedProduct.minimum_Order_Quantity * this.selectedProduct.net_Sale_Price).toFixed(2)),
      deliveryDate: this.todayDate,
      // deliveryTime: this.minTimeInHr
    });
    this.fileInputs.forEach((_, i) => {
      this.createSetUpForm.addControl('fileImage' + i, this.formBuilder.control(null));
    });
    this.setWebOrderTimeSlotSetUpList();
    this.onRateShow(this.selectedProduct);
    // this.setMtoData(this.selectedProduct.product_Code);
    // this.saveWebUserInfo();
  }

  setWebOrderTimeSlotSetUpList() {
    this.timeSlotAvl = [];

    // Get selected and today's date
    let selectedDeliveryDate = moment(this.createSetUpForm.get("deliveryDate").value);
    let todayDate = moment(this.todayDate);

    let deliveryDateStr = selectedDeliveryDate.format("YYYY-MM-DD");
    let todayDateStr = todayDate.format("YYYY-MM-DD");

    // Use current time if today, otherwise use selected time
    let deliveryTime = deliveryDateStr === todayDateStr
      ? todayDate.format("HH:mm") // Use current time if today
      : selectedDeliveryDate.format("HH:mm");

    // Convert deliveryTime into total minutes
    let [hour, minute] = deliveryTime.split(":").map(Number);
    let totTime = hour * 60 + minute;

    // If delivery is today, filter only future slots
    if (deliveryDateStr === todayDateStr) {
      this.timeSlotAvl = this.timeSlot2
        .filter(([fromTime]) => {
          let [slotHour, slotMinute] = fromTime.split(":").map(Number);
          return totTime < slotHour * 60 + slotMinute;
        })
        .map(([fromTime, toTime]) => `${fromTime}-${toTime}`);
    } else {
      // Show all slots for future dates
      this.timeSlotAvl = this.timeSlot2.map(([fromTime, toTime]) => `${fromTime}-${toTime}`);
    }

    // Ensure UI updates
    this.timeSlotAvl = [...this.timeSlotAvl];
  }

  dateChanged() {
    this.createSetUpForm.get("deliveryTime").reset();
    this.setWebOrderTimeSlotSetUpList();
  }

  onRateShow(product: Product) {
    console.log(product);
    this.rate1 = Number(product.net_Sale_Price);
    this.taxRate1 = product.tax.taxRate;
    this.cessRate1 = product.cess.cessRate;
    this.minimumQty = product.minimum_Order_Quantity;
  }

  setMtoData(id: number) {
    const products: Product[] = JSON.parse(this.localStorage.getItem("avct_item"));

    if (products.some((temp) => temp.product_Code == id && temp.cartProductType == "Make To Order")) {
      let itemIndex = products.findIndex((item) => item.product_Code == id);
      this.showUpdate = true;

      this.createSetUpForm.patchValue({
        qty: products[itemIndex].orderQuantityKgs,
        deliveryDate: products[itemIndex].deliveryDate,
        deliveryTime: products[itemIndex].deliveryTime,
        color: Number(products[itemIndex].color),
        shape: Number(products[itemIndex].shape),
        composition: Number(products[itemIndex].composition),
        dimension: products[itemIndex].dimension,
        note: products[itemIndex].note,
        basePremiumFlavourPrice: products[itemIndex].basePremiumFlavourPrice,
        premiumFlavourPrice: Number(products[itemIndex].premiumFlavourPrice.toFixed(2)),
        subTotal: products[itemIndex].orderQuantityKgs * (products[itemIndex].net_Sale_Price + products[itemIndex].premiumFlavourPrice),
      });
    }
  }

  getWebOrderCustomizeDetails() {
    this.createSetUpForm.get('composition').clearValidators();
    this.customizeDetailsSubcription = this.productService.getWebOrderCustomizeList(this.currentUrl).subscribe((data) => {
      this.customizeList = data;
      console.log(this.customizeList);
      this.customizeList.forEach((element) => {
        if (element.customizableParameter == "Product Color" && element.allowCustomize == 1) {
          this.showColor = true;
        }
        if (element.customizableParameter == "Product Composition" && element.allowCustomize == 1) {
          this.showComposition = true;
          this.createSetUpForm.get('composition').setValidators(Validators.required);
        }
        if (element.customizableParameter == "Product Dimension" && element.allowCustomize == 1) {
          this.showDimension = true;
        }
        if (element.customizableParameter == "Product Shape" && element.allowCustomize == 1) {
          this.showShape = true;
        }
      });
      this.getMakeToOrderDetails();
    });
  }

  getMakeToOrderDetails() {
    this.makeToOrderSubcription = this.productService.getMakeToOrderList(this.currentUrl).subscribe((data) => {
      this.makeToOrderDetailsList = data;
      this.productCompositionList = data.productCompositions;
      this.productColorList = data.colors;
      this.productShapeList = data.productShapes;
      this.webOrderMakeToOrderRate = data.webOrderMakeToOrderRate;
      console.log(data);
    });
  }

  getWebOrderTimeSlotSetUpList() {
    let deliveryType = JSON.parse(this.localStorage.getItem("delivery_type"));
    let storeLocation = JSON.parse(this.localStorage.getItem('pickup_location'));

    if (deliveryType) {
      // Reset time slots
      this.timeSlotAvl = [];
      this.timeSlot2 = [];

      const apiUrl = deliveryType === 'storePickup' ? this.apiFactory.getTimeSlotDetailsByUrlAndLocId : this.apiFactory.getTimeSlotDetailsByUrl;
      const locId = deliveryType === 'storePickup' ? storeLocation?.locationCode : null;
      this.webOrderTimeSlotSubcription = this.productService.getTimeSlotList(apiUrl, this.currentUrl, locId)
        .subscribe((data) => {
          // Populate available slots
          this.timeSlot2 = data.map((element) => [element.fromTime, element.toTime]);

          // Get selected and today's date
          let selectedDeliveryDate = moment(this.createSetUpForm.get("deliveryDate").value);
          let todayDate = moment(this.todayDate);

          let deliveryDateStr = selectedDeliveryDate.format("YYYY-MM-DD");
          let todayDateStr = todayDate.format("YYYY-MM-DD");

          // Use current time if today, otherwise use selected time
          let deliveryTime = deliveryDateStr === todayDateStr
            ? todayDate.format("HH:mm") // Use current time if today
            : selectedDeliveryDate.format("HH:mm");

          // Convert deliveryTime into total minutes
          let [hour, minute] = deliveryTime.split(":").map(Number);
          let totTime = hour * 60 + minute;

          // If delivery is today, filter only future slots
          if (deliveryDateStr === todayDateStr) {
            this.timeSlotAvl = this.timeSlot2
              .filter(([fromTime]) => {
                let [slotHour, slotMinute] = fromTime.split(":").map(Number);
                return totTime < slotHour * 60 + slotMinute;
              })
              .map(([fromTime, toTime]) => `${fromTime}-${toTime}`);
          } else {
            // Show all slots for future dates
            this.timeSlotAvl = this.timeSlot2.map(([fromTime, toTime]) => `${fromTime}-${toTime}`);
          }

          // Ensure UI updates
          this.timeSlotAvl = [...this.timeSlotAvl];
          this.getWebOrderMinimumDateTimeDetails();
        });
    }
  }

  getWebOrderMinimumDateTimeDetails() {
    this.webOrderMinimumDateTimeSubcription = this.productService.getWebOrderMinimumDateTimeDetails(this.currentUrl).subscribe((data) => {
      this.webOrderMinimumDateTimeList = data;
      var maxOrderDuration = new Date();
      maxOrderDuration.setHours(maxOrderDuration.getHours() + Number.parseInt(this.webOrderMinimumDateTimeList?.maxDeliveryTime?.split(":")[0]));
      maxOrderDuration.setMinutes(maxOrderDuration.getMinutes() + Number.parseInt(this.webOrderMinimumDateTimeList?.maxDeliveryTime?.split(":")[1]));

      this.maxDate = moment(new Date(maxOrderDuration)).format("YYYY-MM-DD");
      // this.selectedProduct.maxDeliveryDate = this.maxDate;
      if (this.googleCustomer) {
        this.getCustomCakeByEmail();
      }
    });
  }

  // Utility method to format the category name
  formatCategoryName(category: string): string {
    switch (category) {
      case 'photoCakes':
        return 'Photo Cakes';
      case 'customCakes':
        return 'Custom Cakes';
      case 'photoFrames':
        return 'Photo Frames';
      case 'chocolate':
        return 'Chocolate';
      default:
        return '';
    }
  }

  onQtySelect(event: any) {
    this.discount = 0;
    this.qty = Number(event.target.value);
    if (this.qty >= this.minimumQty) {
      let rate: number = this.rate1;
      let premiumFlavourPrice: number = this.createSetUpForm.get("premiumFlavourPrice").value;
      let subTotal: number = this.qty * (Number(rate) + Number(premiumFlavourPrice));
      let subTot = Number(subTotal.toFixed(2));

      this.createSetUpForm.patchValue({ subTotal: subTot });
      this.onDiscountSelect(this.discount);
    } else {
      this.createSetUpForm.get("qty").setValue(this.minimumQty);
      this.toastrService.showErrorToast("Minimum order quantity - " + this.minimumQty + "Kgs");
    }
  }

  onDiscountSelect(discount: number) {
    // let subTotal = this.calculationSetUpForm.get('subTotal').value;
    // let taxRate = this.calculationSetUpForm.get('taxrate').value;
    // let cessRate = this.calculationSetUpForm.get('cessrate').value;

    let subTotal = this.createSetUpForm.get("subTotal").value;
    let taxRate = this.taxRate1;
    let cessRate = this.cessRate1;

    let discountAmount = discount;
    let taxableAmount = subTotal - discountAmount;
    let taxAmount = (taxRate / 100) * taxableAmount;
    let cessAmount = (cessRate / 100) * taxableAmount;
    let netAmount = taxableAmount + taxAmount + cessAmount;

    // this.calculationSetUpForm.patchValue({
    //   discountAmt: discountAmt,
    //   taxableAmt: taxableAmt,
    //   taxAmt: taxAmt,
    //   cessAmt: cessAmt,
    //   netAmt: netAmt
    // })
  }

  onFlavourSelect(name: any) {
    let premiumPrice = 0;
    let basePremiumPrice = 0;
    if (name) {
      basePremiumPrice = this.productCompositionList.filter((e) => e.refNo == name)[0].premiumFlavourPrice;
      premiumPrice = basePremiumPrice + (this.taxRate1 / 100) * basePremiumPrice + (this.cessRate1 / 100) * basePremiumPrice;
    }
    this.createSetUpForm.get("basePremiumFlavourPrice").setValue(Number(basePremiumPrice));
    this.createSetUpForm.get("premiumFlavourPrice").setValue(Number(premiumPrice.toFixed(2)));
    let rate: number = this.rate1;
    let qty: number = this.createSetUpForm.get("qty").value;
    let premiumFlavourPrice: number = this.createSetUpForm.get("premiumFlavourPrice").value;
    let subTotal: number = qty * (Number(rate) + Number(premiumFlavourPrice));
    let subTot = Number(subTotal.toFixed(2));
    this.createSetUpForm.get("subTotal").setValue(subTot);
  }

  triggerFileInput(fileInputId: string): void {
    const fileInput = document.getElementById(fileInputId) as HTMLInputElement;
    if (fileInput) {
      fileInput.click(); // Trigger the file input click event
    }
  }

  onChangeUploadFile(event: any, fileControl: string, imageId: string): void {
    const file = event.target.files[0];
    if (file) {
      if (file.size > 10485760)
        this.toastrService.showErrorToast("10Mb Max File Size..");
      else {
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = (event) => {
          let result = (<FileReader>event.target).result;
          this.files[fileControl] = file; // Save file
          this.createSetUpForm.get(fileControl).setValue(result); // Optional: Save file in the form
        }
      }
    }
  }

  getFileData(fileControl: string): any {
    if (!this.createSetUpForm.get(fileControl).value) {
      return false;
    } else {
      return this.createSetUpForm.get(fileControl).value;
    }
  }

  onRemoveFileClick(fileControl: string, imageId: string): void {
    this.createSetUpForm.get(fileControl).setValue(null);
    delete this.files[fileControl]; // Remove file from stored files
    const fileInput = document.getElementById(imageId) as HTMLInputElement;
    if (fileInput) {
      fileInput.value = ''; // Clear input field
    }
  }

  resetFormAndFiles(): void {
    // Reset the form fields
    this.createSetUpForm.reset();
    // this.createSetUpFormCustomCake.reset();

    // Clear the file inputs
    this.files = {}; // Clear file objects

    // Reset all file input fields
    this.fileInputs.forEach((_, index) => {
      const fileInput = document.getElementById('image' + index) as HTMLInputElement;
      if (fileInput) {
        fileInput.value = ''; // Clear the input field
      }
    });
  }

  addToCart(product: Product, goToCartPage: boolean) {
    console.log(this.createSetUpForm);
    if (this.createSetUpForm.invalid || Object.keys(this.files).length < 1) {
      this.toastrService.showErrorToast("Invalid form");
      return;
    }

    product.cartProductType = "Make To Order";
    product.subTotal = this.createSetUpForm.get("subTotal").value;
    product.orderQuantityKgs = this.createSetUpForm.get("qty").value;
    product.deliveryDate = moment(this.createSetUpForm.get("deliveryDate").value).format("YYYY-MM-DD");;
    product.deliveryTime = this.createSetUpForm.get("deliveryTime").value;
    product.color = this.createSetUpForm.get("color").value;
    product.shape = this.createSetUpForm.get("shape").value;
    product.composition = this.createSetUpForm.get("composition").value;
    product.dimension = this.createSetUpForm.get("dimension").value;
    product.note = this.createSetUpForm.get("note").value;
    product.minimum_Order_Time = this.createSetUpForm.get("minimum_Order_Time").value;
    product.basePremiumFlavourPrice = this.createSetUpForm.get("basePremiumFlavourPrice").value;
    product.premiumFlavourPrice = this.createSetUpForm.get("premiumFlavourPrice").value;

    // Generate file names and new File objects
    const fileArray = Object.keys(this.files).map(key => this.files[key]);
    const fileNames = fileArray.map((file, index) => {
      const timestamp = Date.now();  // Get the current time in milliseconds
      const productName = product.product_Name;
      // Set file extension to .webp
      const fileExtension = '.webp';
      return `${productName}_${index}_${timestamp}${fileExtension}`; // Preserve original file extension
    });

    // Create new File objects with updated names
    const updatedFileArray = fileArray.map((file, index) => {
      const newFileName = fileNames[index];
      return new File([file], newFileName, { type: file.type });
    });

    // Set the generated file names in the product object
    product.fileNames = fileNames;

    // Log the product to verify
    console.log(product);
    console.log(updatedFileArray);

    // Pass the updated files and product to the next method
    this.openDeliveryTypeDialog(product, goToCartPage, updatedFileArray);
  }

  openDeliveryTypeDialog(product: Product, goToCartPage: boolean, files?: File[]) {
    let deliveryType = JSON.parse(this.localStorage.getItem('delivery_type'));
    product.subMtoType = this.selectedCategoryName;
    console.log(deliveryType)
    if (!deliveryType) {
      const dialogRef = this.dialog.open(StorePickupDialogComponent, {
        width: '300px',
        autoFocus: false,
        panelClass: 'store-dialog-container'
      });

      dialogRef.afterClosed().subscribe(result => {
        console.log(result)
        if (result) {
          if (result === 'storePickup') {
            this.setStorePickup(product, goToCartPage, files);
            // this.productService.addToCartWithFiles(product, null, files);

            // if (goToCartPage) {
            //   //Added to redirect to cart
            //   this.productService.navigateToCart(product);
            // }
          } else if (result === 'delivery') {
            this.openPinDialog(product, goToCartPage, files);
          }
        }
      });
    } else {
      if (deliveryType === 'storePickup') {
        this.setStorePickup(product, goToCartPage, files);
        // this.productService.addToCartWithFiles(product, null, files);

        // if (goToCartPage) {
        //   //Added to redirect to cart
        //   this.productService.navigateToCart(product);
        // }
      } else if (deliveryType === 'delivery') {
        this.openPinDialog(product, goToCartPage, files);
      }
    }
  }

  setStorePickup(product: Product, goToCartPage: boolean, files?: File[]) {
    console.log(product)
    if (product.offerDetails?.length > 0) {
      this.openOfferDialog(product, goToCartPage, files);
    } else {
      this.productService.addToCartWithFiles(product, null, files);

      if (goToCartPage) {
        this.productService.navigateToCart(product);
      }
    }
  }

  openOfferDialog(product: Product, goToCartPage: boolean, files?: File[]) {
    console.log(product);
    const cartProducts: Product[] = JSON.parse(this.localStorage.getItem("avct_item")) || [];
    let offers: ViewOfferSetUpDto[] = product.offerDetails;
    console.log(offers)
    let appliedOfferId: string = product.offerId ? product.offerId : product.offerDetails[0].offerId;
    console.log(appliedOfferId)
    const dialogRef = this.dialog.open(OfferDialogComponent, {
      width: "500px",
      height: "300px",
      data: { offers, appliedOfferId },
    });

    dialogRef.afterClosed().subscribe((offerObj) => {
      console.log(offerObj);
      if (offerObj) {
        this.offerObj = offerObj;
        //conditions
        var orderQuantity;
        var freeByProductCode;
        var cartProductType;

        if (product) {
          product.orderQuantity = product.orderQuantityKgs;
          orderQuantity = product.orderQuantity;
          freeByProductCode = product.product_Code;
          cartProductType = product.cartProductType;
        }
        console.log(product);

        if (offerObj.schemeType == "Buy 1 get 1 Free" || offerObj.schemeType == "Buy X get Y Free") {
          this.productOfferSubscription = this.productService.getProductOfferDetails(this.apiFactory.productOfferDetails, offerObj.offerId, orderQuantity)
            .subscribe(
              (data) => {
                console.log(data);
                // offer processing
                this.productOffer = data;
                console.log(offerObj)
                //set order qty as product QTY IN CART
                product.offerId = offerObj.offerId;
                this.productOffer.offerId = offerObj.offerId;
                this.productOffer.freeByProductCode = freeByProductCode;
                this.productOffer.freeProductNetSalePrice = offerObj.netSalePrice;
                this.productOffer.taxRate = offerObj.tax?.taxRate;
                this.productOffer.cessRate = offerObj.cess?.cessRate;
                this.productOffer.rate = Number((offerObj.productPriceForCustomer / (1 + (this.productOffer.taxRate / 100) + (this.productOffer.cessRate / 100))).toFixed(2));
                this.productOffer.net_Sale_Price = offerObj.productPriceForCustomer * orderQuantity;
                this.productOffer.sale_Price = this.productOffer.net_Sale_Price;
                this.productOffer.netAmountForLocation = offerObj.productPriceForLocation * orderQuantity;
                this.productOffer.orderQuantity = orderQuantity;
                this.productOffer.subTotal = this.productOffer.rate * orderQuantity;
                this.productOffer.taxableAmount = this.productOffer.subTotal;
                this.productOffer.product_Name = this.productOffer.product_Name.concat("(").concat(offerObj.schemeType).concat(")");
                this.productOffer.cartProductType = cartProductType;
                this.productOffer.product_Unit = this.productOffer.product_Unit;
                this.productOffer.max_Discount_Percentage = offerObj.max_Discount_Percentage;
                this.productOffer.hsn_Code = offerObj.hsnCode;
                this.productOffer.subGroupCode = offerObj.subGroupCode;

                this.productOffer.discountAmt = 0;
                this.productOffer.discountRate = 0;
                this.productOffer.product_Type = this.productOffer.product_Type;
                this.productOffer.food_Type = this.productOffer.food_Type;
                this.productOffer.deliveryDate = product.deliveryDate;
                this.productOffer.deliveryTime = product.deliveryTime;
                product.promotionOfferId = offerObj.offerId;

                console.log(product);
                console.log(this.productOffer);
                if (product.orderQuantity < offerObj.minQty && product.orderQuantity > offerObj.maxQtyPerBill) {
                  this.productService.addToCartWithFiles(product, null, files);
                  if (goToCartPage) {
                    //Added to redirect to cart
                    this.productService.navigateToCart(product);
                  }
                } else {

                  this.productService.addToCartWithFiles(product, this.productOffer, files);

                  if (goToCartPage) {
                    //Added to redirect to cart
                    this.productService.navigateToCart(product);
                  }
                }
              },
              (err) => {
                if (err.error) {
                  this.toastrService.showErrorToast(err.error.message);
                  this.productService.addToCartWithFiles(product, null, files);
                  if (goToCartPage) {
                    //Added to redirect to cart
                    this.productService.navigateToCart(product);
                  }
                }
              }
            );
        } else {
          if (offerObj.schemeType == "Buy x get x percent off") {
            this.productDiscountSubscription = this.productService.getProductDiscountDetails(this.apiFactory.productDiscountDetails, offerObj.offerId, orderQuantity)
              .subscribe(
                (data) => {
                  this.discountOffer = data;
                  console.log(this.discountOffer)
                  product.discountAmt = (this.discountOffer / 100) * product.sale_Price;
                  product.discountRate = this.discountOffer;
                  var taxableAmt = product.sale_Price - (product.sale_Price * this.discountOffer) / 100;
                  taxableAmt = taxableAmt + product.basePremiumFlavourPrice;
                  var cess = product.cess.cessRate;
                  var cessAmt = (cess / 100) * taxableAmt;
                  var cgst = (product.tax.taxRate / 2 / 100) * taxableAmt;
                  var sgst = (product.tax.taxRate / 2 / 100) * taxableAmt;
                  var tot = cgst + sgst;
                  product.promotionOfferId = offerObj.offerId;
                  product.offerId = offerObj.offerId;
                  product.taxAmount = tot; //Tax Amount
                  product.cgstAmt = cgst;
                  product.sgstAmt = sgst;
                  product.cessAmount = cessAmt;
                  product.net_Sale_Price = taxableAmt + tot + cessAmt;
                  console.log(product)
                  if (product.orderQuantity < offerObj.minQty && product.orderQuantity > offerObj.maxQtyPerBill) {
                    this.productService.addToCartWithFiles(product, null, files);
                    if (goToCartPage) {
                      //Added to redirect to cart
                      this.productService.navigateToCart(product);
                    }
                  }
                },
                (err) => {
                  if (err.error) {
                    this.toastrService.showErrorToast(err.error.message);
                    this.productService.addToCartWithFiles(product, null, files);
                    if (goToCartPage) {
                      //Added to redirect to cart
                      this.productService.navigateToCart(product);
                    }
                  }
                }
              );
          } else {
            this.productBulkDiscountSubscription = this.productService.getProductBulkDiscountDetails(this.apiFactory.productBulkOfferDetails, offerObj.offerId)
              .subscribe((data) => {
                this.bulkDiscountOffer = data;
                product.discountAmt = (this.bulkDiscountOffer.discount / 100) * product.sale_Price;
                product.discountRate = this.bulkDiscountOffer.discount;
                var taxableAmt = product.sale_Price - (product.sale_Price * this.bulkDiscountOffer.discount) / 100;
                taxableAmt = taxableAmt + product.basePremiumFlavourPrice;
                var cess = product.cess.cessRate;
                var cessAmt = (cess / 100) * taxableAmt;
                var cgst = (product.tax.taxRate / 2 / 100) * taxableAmt;
                var sgst = (product.tax.taxRate / 2 / 100) * taxableAmt;
                var tot = cgst + sgst;
                product.promotionOfferId = offerObj.offerId;
                product.offerId = offerObj.offerId;
                product.taxAmount = tot; //Tax Amount
                product.cgstAmt = cgst;
                product.sgstAmt = sgst;
                product.cessAmount = cessAmt;
                product.net_Sale_Price = taxableAmt + tot + cessAmt;
                this.productService.addToCartWithFiles(product, null, files);

                if (goToCartPage) {
                  //Added to redirect to cart
                  this.productService.navigateToCart(product);
                }
              });
          }
        }
      }
    });
  }

  openPinDialog(product: Product, goToCartPage: boolean, files?: File[]) {
    this.pincode = this.localStorage.getItem("pincode_item");
    console.log(product);

    if (this.pincode == null) {
      const dialogRef = this.dialog.open(PinDialogComponent, {
        width: "300px",
        panelClass: "pin-dialog-container",
      });

      dialogRef.afterClosed().subscribe((result) => {
        if (result?.result == true) {
          if (product.offerDetails?.length > 0) {
            this.openOfferDialog(product, goToCartPage, files);
          } else {
            this.productService.addToCartWithFiles(product, null, files);

            if (goToCartPage) {
              //Added to redirect to cart
              this.productService.navigateToCart(product);
            }
          }
          // this.productService.addToCartWithFiles(product, null, files);

          // if (goToCartPage) {
          //   //Added to redirect to cart
          //   this.productService.navigateToCart(product);
          // }
        }
      });
    } else {
      if (product.offerDetails?.length > 0) {
        this.openOfferDialog(product, goToCartPage, files);
      } else {
        this.productService.addToCartWithFiles(product, null, files);

        if (goToCartPage) {
          //Added to redirect to cart
          this.productService.navigateToCart(product);
        }
      }
      // this.productService.addToCartWithFiles(product, null, files);

      // if (goToCartPage) {
      //   //Added to redirect to cart
      //   this.productService.navigateToCart(product);
      // }
    }
  }

  saveWebUserInfo() {
    let localWebUserInfo: WebUserInfo = JSON.parse(this.localStorage.getItem("web_user_info"));
    if (localWebUserInfo) {
      localWebUserInfo.productCode = this.selectedProduct.product_Code;

      this.saveWebUserInfoSubscription = this.commonService.saveWebUserInfo(this.apiFactory.inserWebUserInfo, localWebUserInfo).subscribe((data) => {
        if (data) {
        }
      });
    }
  }

  // Custom Cake
  deliveryType: string;

  openStorePickupDialogCustom() {
    let deliveryType = JSON.parse(this.localStorage.getItem('delivery_type'));

    if (!deliveryType) {
      const dialogRef = this.dialog.open(StorePickupDialogComponent, {
        width: '300px',
        autoFocus: false,
        panelClass: 'store-dialog-container',
      });

      dialogRef.afterClosed().subscribe((result) => {
        console.log(result);
        if (result) {
          if (result === 'storePickup') {
            this.toastrService.showSuccessToast('Store changed successfully');
            let storeLocation: WebOrderLocationSetup = JSON.parse(this.localStorage.getItem('pickup_location'));
            this.webOrderMinimumDateTimeList.deliveryTime = storeLocation?.minOrderTime;
            this.webOrderMinimumDateTimeList.maxDeliveryTime = storeLocation?.maxOrderTime;

            this.calculateOrderDurations(result);
          } else if (result === 'delivery') {
            this.openPinChangeDialogCustom();
          }
        }
      });
    } else {
      if (deliveryType === 'storePickup') {
        let storeLocation: WebOrderLocationSetup = JSON.parse(this.localStorage.getItem('pickup_location'));
        this.webOrderMinimumDateTimeList.deliveryTime = storeLocation?.minOrderTime;
        this.webOrderMinimumDateTimeList.maxDeliveryTime = storeLocation?.maxOrderTime;

        this.calculateOrderDurations('storePickup');
      } else if (deliveryType === 'delivery') {
        this.openPinChangeDialogCustom();
      }
    }
  }

  openPinChangeDialogCustom() {
    this.pincode = this.localStorage.getItem('pincode_item');
    console.log(this.pincode);
    if (!this.pincode) {
      const dialogRef = this.dialog.open(PinDialogComponent, {
        width: '300px',
        panelClass: 'pin-dialog-container',
      });

      dialogRef.afterClosed().subscribe((result) => {
        console.log(result);
        if (result?.result) {
          this.toastrService.showSuccessToast('Pin changed successfully');

          this.calculateOrderDurations('delivery');
        }
      });
    } else {
      this.calculateOrderDurations('delivery');
    }
  }

  // Helper function to calculate order durations
  private calculateOrderDurations(deliveryType: string) {
    this.getWebOrderTimeSlotSetUpList();
    const { deliveryTime, maxDeliveryTime } = this.webOrderMinimumDateTimeList;

    if (!deliveryTime || !maxDeliveryTime) return;

    const getTimeOffset = (timeString: string) => {
      let [hours, minutes] = timeString.split(':').map(Number);
      return { hours: hours || 0, minutes: minutes || 0 };
    };

    // Set deliveryType in the method
    this.deliveryType = deliveryType;

    let orderDuration = new Date();
    let maxOrderDuration = new Date();

    let { hours: deliveryHours, minutes: deliveryMinutes } = getTimeOffset(deliveryTime);
    orderDuration.setHours(orderDuration.getHours() + deliveryHours);
    orderDuration.setMinutes(orderDuration.getMinutes() + deliveryMinutes);

    let { hours: maxDeliveryHours, minutes: maxDeliveryMinutes } = getTimeOffset(maxDeliveryTime);
    maxOrderDuration.setHours(maxOrderDuration.getHours() + maxDeliveryHours);
    maxOrderDuration.setMinutes(maxOrderDuration.getMinutes() + maxDeliveryMinutes);

    this.maxDate = moment(maxOrderDuration).format('YYYY-MM-DD');
    this.todayDate = orderDuration;
  }

  onCreateSetUpCustomCake() {
    this.createSetUpFormCustomCake = this.formBuilder.group({
      cakes: this.formBuilder.array([this.createCakeFormGroup()])
    });
  }

  get cakes(): FormArray {
    return this.createSetUpFormCustomCake.get('cakes') as FormArray;
  }

  createCakeFormGroup(): FormGroup {
    return this.formBuilder.group({
      refNo: [],
      email: [],
      requestedQty: ["", Validators.required],
      requestedDeliveryDate: ["", Validators.required],
      requestedDeliveryTime: ["", Validators.required],
      note: ["", [Validators.maxLength(40)]],
      responseQty: [],
      responseDeliveryDate: [],
      responseDeliveryTime: [],
      rate: [0],
      totalAmount: [0],
      status: [],
      image: [],
      fileImage: [],
      selectedImageEdit: [],
      isAgreedTerms: [],
      requestedSlots: [],
      responseSlots: [],
      minimumQty: [],
      locationCode: [],
      productCode: [],
      composition: [],
      basePremiumFlavourPrice: [0],
      premiumFlavourPrice: [0]
    });
  }

  addCake() {
    if (this.createSetUpFormCustomCake.invalid) {
      this.toastrService.showErrorToast('Invalid form');
      return;
    }
    if (this.cakes.length >= 3) {
      this.toastrService.showErrorToast('Max 3 Custom cakes allowed');
      return;
    }
    this.cakes.push(this.createCakeFormGroup());
  }

  getFileDataCustomCake(fileName: string, index: number) {
    const cakeFormGroup = this.cakes.at(index) as FormGroup;

    if (!cakeFormGroup.get(fileName)?.value) {
      return false;
    } else {
      return cakeFormGroup.get(fileName).value;
    }
  }

  onFileInputClick(event: Event, i: number) {
    const cakeFormGroup = this.cakes.at(i) as FormGroup;

    // Check if terms are agreed
    if (!cakeFormGroup.get('isAgreedTerms').value) {
      this.toastrService.showErrorToast('Please agree to the terms and conditions');

      // Prevent the file dialog from opening
      event.preventDefault();
    }
  }

  onChangeUploadFileCustomCake(event: any, imageFile: string, image: string, index: number) {
    const cakeFormGroup = this.cakes.at(index) as FormGroup;

    if (event) {
      const file = event.target.files && event.target.files[0];
      if (file) {
        if (file.size > 10485760)
          this.toastrService.showErrorToast("10Mb Max File Size..");
        else {
          var reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = (event) => {
            let result = (<FileReader>event.target).result;
            cakeFormGroup.get(imageFile).setValue(result);
            cakeFormGroup.get(image).setValue(file);
          }
        }
      }
    }
  }

  onRemoveFileClickCustomCake(file: string, image: any, index: number) {
    const cakeFormGroup = this.cakes.at(index) as FormGroup;

    cakeFormGroup.get(image).reset();
    cakeFormGroup.get(file).reset();
    const fileInputElement = document.getElementById(image) as HTMLInputElement;
    if (fileInputElement) {
      fileInputElement.value = ''; // Clear the file input value
    }
  }

  customDateChanged(index: number, type: string) {
    const cakeFormGroup = this.cakes.at(index) as FormGroup;
    if (type == 'Requested')
      cakeFormGroup.get("requestedDeliveryTime").reset();
    else
      cakeFormGroup.get("responseDeliveryTime").reset();

    this.setCustomTimeSlot(index, type);
  }

  setCustomTimeSlot(index: number, type: string) {
    const cakeFormGroup = this.cakes.at(index) as FormGroup;
    let dateType = type == 'Requested' ? 'requestedDeliveryDate' : 'responseDeliveryDate';
    let slotType = type == 'Requested' ? 'requestedSlots' : 'responseSlots';
    cakeFormGroup.get(slotType).setValue([]);

    // Get selected and today's date
    let selectedDeliveryDate = moment(cakeFormGroup.get(dateType).value);
    let todayDate = moment(this.todayDate);

    console.log(selectedDeliveryDate);
    console.log(todayDate);

    let deliveryDateStr = selectedDeliveryDate.format("YYYY-MM-DD");
    let todayDateStr = todayDate.format("YYYY-MM-DD");

    // Use current time if today, otherwise use selected time
    let deliveryTime = deliveryDateStr === todayDateStr
      ? todayDate.format("HH:mm") // Use current time if today
      : selectedDeliveryDate.format("HH:mm");

    // Convert deliveryTime into total minutes
    let [hour, minute] = deliveryTime.split(":").map(Number);
    let totTime = hour * 60 + minute;

    // If delivery is today, filter only future slots
    if (deliveryDateStr === todayDateStr) {
      cakeFormGroup.get(slotType).setValue(this.timeSlot2
        .filter(([fromTime]) => {
          let [slotHour, slotMinute] = fromTime.split(":").map(Number);
          return totTime < slotHour * 60 + slotMinute;
        })
        .map(([fromTime, toTime]) => `${fromTime}-${toTime}`));
    } else {
      // Show all slots for future dates
      cakeFormGroup.get(slotType).setValue(this.timeSlot2.map(([fromTime, toTime]) => `${fromTime}-${toTime}`));
    }

    // let currDateMto = moment(new Date(cakeFormGroup.get(dateType).value)).format("YYYY-MM-DD");
    // let currTime = moment(new Date()).format("HH:mm");
    // let hour = Number(currTime.split(":")[0]);
    // let amPm = currTime.split(" ")[1];

    // if (amPm == "pm" || amPm == "PM") {
    //   hour += 12;
    // }
    // let totTime: number = Number(hour * 60) + Number(currTime.split(":")[1].split(" ")[0]);

    // this.timeSlot2.forEach((e) => {
    //   let slotHourFrom = e[0].split(":")[0];
    //   let slotMinFrom = e[0].split(":")[1];
    //   let slotHourTo = e[1].split(":")[0];
    //   let slotMinTo = e[1].split(":")[1];

    //   let fromSlotTime = Number(slotHourFrom) * 60;
    //   let toSlotTime = Number(slotHourTo) * 60;

    //   if (currDateMto == moment(this.todayDate).format("YYYY-MM-DD")) {
    //     if (totTime >= fromSlotTime && totTime <= toSlotTime) {
    //       // let tSlot: any = slotHourFrom + ":" + slotMinFrom + "-" + slotHourTo + ":" + slotMinTo;
    //       // cakeFormGroup.get(slotType).value.push(tSlot);
    //     } else if (totTime <= fromSlotTime) {
    //       let tSlot: any = slotHourFrom + ":" + slotMinFrom + "-" + slotHourTo + ":" + slotMinTo;
    //       cakeFormGroup.get(slotType).value.push(tSlot);
    //     }
    //   } else {
    //     let tSlot = slotHourFrom + ":" + slotMinFrom + "-" + slotHourTo + ":" + slotMinTo;
    //     cakeFormGroup.get(slotType).value.push(tSlot);
    //   }
    // });
  }

  onCustomFlavourSelect(name: any, index: number) {
    const cakeFormGroup = this.cakes.at(index) as FormGroup;

    let premiumPrice = 0;
    let basePremiumPrice = 0;
    if (name) {
      basePremiumPrice = this.productCompositionList.filter((e) => e.refNo == name)[0].premiumFlavourPrice;
      premiumPrice = basePremiumPrice + (this.webOrderMakeToOrderRate.gstRate / 100) * basePremiumPrice + (this.webOrderMakeToOrderRate.cessRate / 100) * basePremiumPrice;
    }
    cakeFormGroup.get('basePremiumFlavourPrice').setValue(Number(basePremiumPrice));
    cakeFormGroup.get('premiumFlavourPrice').setValue(Number(premiumPrice.toFixed(2)));
    let rate: number = cakeFormGroup.get('rate').value;
    let qty: number = cakeFormGroup.get('responseQty').value;
    let premiumFlavourPrice: number = cakeFormGroup.get('premiumFlavourPrice').value;
    let subTotal: number = qty * (Number(rate) + Number(premiumFlavourPrice));
    let subTot = Number(subTotal.toFixed(2));
    cakeFormGroup.get("totalAmount").setValue(subTot);
  }

  openDeliveryTypeDialogForCheckPrice(index: number) {
    let googleId = JSON.parse(this.localStorage.getItem("google_id"));
    let fbId = JSON.parse(this.localStorage.getItem("fb_id"));

    if (!googleId && !fbId) {
      this.navRoute.navigate(["/login", "customized-product"]);
      return;
    }
    let deliveryType = JSON.parse(this.localStorage.getItem('delivery_type'));
    if (!deliveryType) {
      const dialogRef = this.dialog.open(StorePickupDialogComponent, {
        width: '300px',
        autoFocus: false,
        panelClass: 'store-dialog-container'
      });

      dialogRef.afterClosed().subscribe(result => {
        console.log(result)
        if (result) {
          if (result === 'storePickup') {
            this.saveCustomCake(index);
          } else if (result === 'delivery') {
            this.openPinDialogForCheckPrice(index);
          }
        }
      });
    } else {
      if (deliveryType === 'storePickup') {
        this.saveCustomCake(index);
      } else if (deliveryType === 'delivery') {
        this.openPinDialogForCheckPrice(index);
      }
    }
  }

  openPinDialogForCheckPrice(index: number) {
    this.pincode = this.localStorage.getItem("pincode_item");

    if (this.pincode == null) {
      const dialogRef = this.dialog.open(PinDialogComponent, {
        width: "300px",
        panelClass: "pin-dialog-container",
      });

      dialogRef.afterClosed().subscribe((result) => {
        if (result?.result == true) {
          this.saveCustomCake(index);
        }
      });
    } else {
      this.saveCustomCake(index);
    }
  }

  saveCustomCake(index: number) {
    let locationCode = JSON.parse(this.localStorage.getItem('location_code'));
    const cakeFormGroup = this.cakes.at(index) as FormGroup;
    if (cakeFormGroup.invalid) {
      this.toastrService.showErrorToast('Invalid form');
      return;
    }

    cakeFormGroup.get('email').setValue(this.googleCustomer.email);
    cakeFormGroup.get('locationCode').setValue(locationCode);
    let formatedRequestedDate = moment(cakeFormGroup.get('requestedDeliveryDate').value).format("YYYY-MM-DD");
    cakeFormGroup.get('requestedDeliveryDate').setValue(formatedRequestedDate);
    let status = cakeFormGroup.get('status').value;
    if (status == 'Resolved') {
      cakeFormGroup.get('status').setValue('Accepted');
    } else {
      cakeFormGroup.get('status').setValue('Requested');
      cakeFormGroup.get('productCode').setValue(this.generateComplexProductCode());
    }
    let updatedFile = null;
    if (cakeFormGroup.get('status').value == 'Requested') {
      let file = cakeFormGroup.get('image').value;
      if (!file) {
        this.toastrService.showErrorToast('Choose file');
        return;
      }

      // Generate file name with timestamp and product name
      const timestamp = Date.now();
      const productName = 'CUS' + index;
      // Set file extension to .webp
      const fileExtension = '.webp';
      // const fileExtension = file.name.substring(file.name.lastIndexOf('.'));
      const newFileName = `${productName}_${timestamp}${fileExtension}`;

      // Create a new File object with the updated name
      updatedFile = new File([file], newFileName, { type: file.type });

      cakeFormGroup.get('fileImage').setValue(updatedFile.name);
    }

    if (this.checkCustomRefNo(index)) {
      cakeFormGroup.get('requestedQty').enable({ emitEvent: false });
      cakeFormGroup.get('requestedDeliveryDate').enable({ emitEvent: false });
      cakeFormGroup.get('requestedDeliveryTime').enable({ emitEvent: false });
      cakeFormGroup.get('note').enable({ emitEvent: false });
      cakeFormGroup.get('composition').enable({ emitEvent: false });
      cakeFormGroup.get('premiumFlavourPrice').enable({ emitEvent: false });
    }

    this.userService.addCustomCakeWithFile(this.apiFactory.insertCustomProduct, cakeFormGroup, updatedFile).subscribe((data) => {
      this.toastrService.showSuccessToast("Data has been successfully saved.");
      cakeFormGroup.get('refNo').setValue(data);
      this.getCustomCakeImage(index);
      this.checkCustomRefNo(index);
    })
  }

  deleteCustomCake(index: number) {
    const cakeFormGroup = this.cakes.at(index) as FormGroup;
    let refNo: number = cakeFormGroup.get('refNo').value;

    if (refNo) {
      this.userService.deleteCustomCake(this.apiFactory.deleteCustomProduct, refNo).subscribe((data) => {
        this.toastrService.showSuccessToast('Cancellation successful.');
        this.cakes.removeAt(index);
      })
    }
    else {
      this.cakes.removeAt(index);
    }
  }

  checkCustomRefNo(index: number) {
    let formCtrl: AbstractControl = this.cakes.at(index);

    if (formCtrl.get('refNo').value) {
      formCtrl.get('requestedQty').disable();
      formCtrl.get('requestedDeliveryDate').disable();
      formCtrl.get('requestedDeliveryTime').disable();
      formCtrl.get('note').disable();
      formCtrl.get('isAgreedTerms').disable();
      formCtrl.get('composition').disable();
      formCtrl.get('premiumFlavourPrice').disable();
      formCtrl.get('responseDeliveryDate').setValidators(Validators.required);
      formCtrl.get('responseDeliveryTime').setValidators(Validators.required);
      this.resp = true;
    }
    else {
      formCtrl.get('requestedQty').enable();
      formCtrl.get('requestedDeliveryDate').enable();
      formCtrl.get('requestedDeliveryTime').enable();
      formCtrl.get('note').enable();
      formCtrl.get('isAgreedTerms').enable();
      formCtrl.get('composition').enable();
      formCtrl.get('premiumFlavourPrice').enable();
      formCtrl.get('responseDeliveryDate').clearValidators();
      formCtrl.get('responseDeliveryTime').clearValidators();
      this.resp = false;
    }
    return this.resp;
  }

  setCustomCakesValues() {
    this.cakes.reset();
    this.cakes.clear();
    console.log(this.customCakeList)

    this.customCakeList.forEach((element, index) => {
      let fg = this.createCakeFormGroup();
      fg.patchValue({
        refNo: element.refNo,
        email: element.email,
        locationCode: element.locationCode,
        requestedQty: element.requestedQty,
        requestedDeliveryDate: element.requestedDeliveryDate,
        requestedDeliveryTime: element.requestedDeliveryTime,
        note: element.note,
        responseQty: element.responseQty,
        minimumQty: element.responseQty,
        responseDeliveryDate: element.responseDeliveryDate,
        responseDeliveryTime: element.responseDeliveryTime,
        rate: element.rate,
        totalAmount: element.totalAmount,
        status: element.status,
        productCode: element.productCode,
        composition: element.composition,
        basePremiumFlavourPrice: element.basePremiumFlavourPrice,
        premiumFlavourPrice: element.premiumFlavourPrice,
        fileImage: element.fileImage,
        isAgreedTerms: true,
      })
      this.cakes.push(fg);
      this.getCustomCakeImage(index);
      this.setCustomTimeSlot(index, 'Requested');
      this.onCustomFlavourSelect(element.composition, index);
      if (element.responseDeliveryTime) {
        this.setCustomTimeSlot(index, 'Response');
      }
    });
    console.log(this.cakes)

    return this.cakes;
  }

  getCustomCakeByEmail() {
    this.userService.getCustomCakeByEmail(this.apiFactory.getCustomProductByEmail, this.googleCustomer.email).subscribe((data) => {
      if (data.length > 0) {
        this.customCakeList = data;
        this.setCustomCakesValues();
      }
    })
  }

  getCustomCakeByRefNo(index: number) {
    const cakeFormGroup = this.cakes.at(index) as FormGroup;
    let refNo: number = cakeFormGroup.get('refNo').value;
    this.userService.getCustomCakeByRefNo(this.apiFactory.getCustomProductByRefNo, refNo).subscribe((data) => {
      if (data && data.status != 'Requested') {
        this.customCake = data;
        cakeFormGroup.patchValue({
          refNo: data.refNo,
          email: data.email,
          locationCode: data.locationCode,
          requestedQty: data.requestedQty,
          requestedDeliveryDate: data.requestedDeliveryDate,
          requestedDeliveryTime: data.requestedDeliveryTime,
          note: data.note,
          responseQty: data.responseQty,
          minimumQty: data.responseQty,
          responseDeliveryDate: data.responseDeliveryDate,
          responseDeliveryTime: data.responseDeliveryTime,
          rate: data.rate,
          totalAmount: data.totalAmount,
          status: data.status,
          productCode: data.productCode,
          composition: data.composition,
          basePremiumFlavourPrice: data.basePremiumFlavourPrice,
          premiumFlavourPrice: data.premiumFlavourPrice,
          fileImage: data.fileImage,
          isAgreedTerms: true,
        })
        this.onCustomFlavourSelect(data.composition, index);
        if (data.responseDeliveryTime) {
          this.setCustomTimeSlot(index, 'Response');
        }
      } else {
        this.toastrService.showWarningToast('Please try again after some time.')
      }
    })
  }

  getCustomCakeImage(index: number) {
    const cakeFormGroup = this.cakes.at(index) as FormGroup;

    this.getCustomCakeImageSubscription = this.productService.downloadImage(this.apiFactory.getServerUrl, 'cartcustomizedproduct', cakeFormGroup.get('fileImage').value).subscribe((data) => {
      if (data.toString().includes("ArrayBuffer") == true) {
        let file = new Blob([data], { type: 'image/jpeg' });
        let fl1 = URL.createObjectURL(file);
        let fl2: SafeUrl = this.domSanitizer.bypassSecurityTrustUrl(fl1);
        cakeFormGroup.get('selectedImageEdit').setValue(fl2);
      }
    });
  }

  onResponseQtySelect(event: any, index: number) {
    const cakeFormGroup = this.cakes.at(index) as FormGroup;
    let inputQty = Number(event.target.value);
    let minimumQty = cakeFormGroup.get('minimumQty').value;
    let responseRate = cakeFormGroup.get('rate').value;

    if (inputQty >= minimumQty) {
      cakeFormGroup.get('totalAmount').setValue(inputQty * responseRate);
    } else {
      cakeFormGroup.get("responseQty").setValue(minimumQty);
      this.toastrService.showErrorToast("Minimum order quantity - " + minimumQty + "Kgs");
    }
  }

  addToCartCustomCake(index: number, goToCartPage: boolean) {
    const cakeFormGroup = this.cakes.at(index) as FormGroup;
    if (cakeFormGroup.invalid) {
      this.toastrService.showErrorToast('Invalid form');
      return;
    }
    let product: Product = new Product();
    product.product_Code = cakeFormGroup.get("productCode").value;
    product.cartProductType = "Make To Order";
    product.subTotal = cakeFormGroup.get("totalAmount").value;
    product.net_Sale_Price = cakeFormGroup.get("rate").value;
    product.orderQuantityKgs = cakeFormGroup.get("responseQty").value;
    let formatedResponseDate = moment(cakeFormGroup.get('responseDeliveryDate').value).format("YYYY-MM-DD");
    product.deliveryDate = formatedResponseDate;
    product.deliveryTime = cakeFormGroup.get("responseDeliveryTime").value;
    product.composition = cakeFormGroup.get("composition").value;
    product.basePremiumFlavourPrice = cakeFormGroup.get("basePremiumFlavourPrice").value;
    product.premiumFlavourPrice = cakeFormGroup.get("premiumFlavourPrice").value;
    product.product_Picture = cakeFormGroup.get("fileImage").value;
    product.customizedProductPicture = cakeFormGroup.get("fileImage").value;
    product.note = cakeFormGroup.get("note").value;
    product.cess = new Cess();
    product.cess.cessRate = this.webOrderMakeToOrderRate.cessRate;
    product.tax = new Tax();
    product.tax.taxRate = this.webOrderMakeToOrderRate.gstRate;
    product.hsn_Code = this.webOrderMakeToOrderRate.hsnCode;
    product.max_Discount_Percentage = this.webOrderMakeToOrderRate.maxDiscountPercentage;
    product.product_Type = this.webOrderMakeToOrderRate.productType;
    product.product_Unit = this.webOrderMakeToOrderRate.productUnit;
    product.subGroupCode = this.webOrderMakeToOrderRate.subGroupCode;
    let salePrice = product.net_Sale_Price / ((product.tax.taxRate / 100) + (product.cess.cessRate / 100) + 1);
    product.sale_Price = Number(salePrice.toFixed(2));
    product.fileNames = [cakeFormGroup.get("fileImage").value];
    console.log(product);

    // Pass the updated files and product to the next method
    this.openDeliveryTypeDialog(product, goToCartPage);
  }

  generateComplexProductCode(): number {
    const timestamp = Date.now() % 1000000000; // Reducing the timestamp to fit within the range
    const randomNum = Math.floor(Math.random() * 1000000000); // Generate a random number up to 1 billion
    const uniqueCode = (timestamp ^ randomNum) % 2147483647; // XOR the values and limit it to Integer range
    return uniqueCode;
  }

}
