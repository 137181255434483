import { AfterViewInit, Component, OnInit, ViewChild } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { APIFactoryEndPoints } from "src/app/common/apifactory-end-points";
import { LocalStorageService } from "src/app/common/LocalStorageService";
import { ToastService } from "src/app/shared/services/toastr.service";
import { environment } from "src/environments/environment";
import { Product } from "../../../../shared/models/product";
import { ProductService } from "../../../../shared/services/product.service";
import { PinDialogComponent } from "../pin-dialog/pin-dialog.component";
import { Subscription } from "rxjs";
import { getWindow } from "ssr-window";
import { ProductsWithWebOrderOffers } from "src/app/shared/models/productswithweborderoffers";
import { HttpErrorResponse } from "@angular/common/http";
import { ProductsWithStock } from "src/app/shared/models/products-with-stock";
import { ProductDesignDialogComponent } from "../product-design-dialog/product-design-dialog.component";
import { ViewOfferSetUpDto } from "src/app/shared/models/viewoffersetupdto";
import { OfferDetailsDialogComponent } from "../offer-details-dialog/offer-details-dialog.component";
import { BulkDiscountDto } from "src/app/shared/models/bulkdiscountdto";
import { OfferDialogComponent } from "../offer-dialog/offer-dialog.component";
import { StorePickupDialogComponent } from "../store-pickup-dialog/store-pickup-dialog.component";
import { NgxScannerQrcodeComponent, ScannerQRCodeConfig, ScannerQRCodeResult } from "ngx-scanner-qrcode";
import { GoogleCustomer } from "src/app/shared/models/googlecustomer";

@Component({
  selector: "app-favourite-products",
  templateUrl: "./favourite-products.component.html",
  styleUrls: ["./favourite-products.component.scss"],
})
export class FavouriteProductsComponent implements OnInit {

  favoruiteProducts: Product[];
  showDataNotFound = true;
  webUrl: string;
  // Not Found Message
  messageTitle = "No Favourite Products Found";
  messageDescription = "Please, choose your favourite products";
  pincode: string;
  serverUrl: string;
  locId: number;
  currentUrl: string;
  productWithOffers: ProductsWithWebOrderOffers[];
  designProducts: Product[] = [];
  productWithStock: ProductsWithStock[] = [];

  productOffer: Product;
  discountOffer: number;
  bulkDiscountOffer: BulkDiscountDto;
  offerObj: any;
  sharingUrl: string;

  deliveryType: string;
  googleCustomer: GoogleCustomer;

  private offerSubscription: Subscription;
  private getDesignSubscription: Subscription;
  private checkAllowStockSubscription: Subscription;
  private stockSubscription: Subscription;
  private productOfferSubscription: Subscription;
  private productDiscountSubscription: Subscription;
  private productBulkDiscountSubscription: Subscription;

  constructor(
    private localStorage: LocalStorageService,
    public dialog: MatDialog,
    private productService: ProductService,
    private navRoute: Router,
    private toastrService: ToastService,
    private apiFactory: APIFactoryEndPoints
  ) { this.serverUrl = this.apiFactory.getServerUrl; }

  ngOnInit() {
    this.webUrl = environment.cartApiUrl;
    this.currentUrl = getWindow().location.hostname;
    this.sharingUrl = getWindow().location.origin;
    this.locId = JSON.parse(this.localStorage.getItem("location_code"));
    this.deliveryType = JSON.parse(this.localStorage.getItem("delivery_type"));
    this.googleCustomer = JSON.parse(this.localStorage.getItem("google_loggedIn_details"));
    this.getFavouriteProduct();
    // this.setCurrentLocation();
    // this.camerPermission1();
    // this.jrequestCameraPermission();
  }



  removeFavourite(product: any) {
    this.productService.removeLocalFavourite(product);

    this.getFavouriteProduct();
  }

  getFavouriteProduct() {
    this.favoruiteProducts = this.productService.getLocalFavouriteProducts();

    this.favoruiteProducts.forEach(f => {
      f.sharingUrl = `${this.sharingUrl}/${f.cartProductType === 'Make To Order' ? 'make-product' : 'product'}/${f.product_Code}`;
    });

    let productCode = this.favoruiteProducts.map(element => element.product_Code);
    if (productCode.length === 0) return;

    let type = this.deliveryType ? (this.deliveryType === 'storePickup' ? 'Pickup' : 'Delivery') : null;

    if (!type) {
      this.fetchOfferDetails(0, productCode);
      return;
    }

    this.offerSubscription = this.productService.getOfferAllowFor(this.apiFactory.getOfferAllowFor, this.currentUrl, type)
      .subscribe((data: string) => {
        if (data === 'Not Available') {
          console.log("No offers available.");
          return;
        }

        let locId = data === 'allowForWebsite'
          ? this.googleCustomer?.locationCode || 0
          : data === 'allowForLocation'
            ? JSON.parse(this.localStorage.getItem('location_code')) || 0
            : 0;

        this.fetchOfferDetails(locId, productCode);
      });
  }

  fetchOfferDetails(locId: number, productCode: number[]) {
    if (!locId || productCode.length === 0) return;

    this.offerSubscription = this.productService.getOfferDetails(this.apiFactory.getOfferDetails, locId, productCode, this.currentUrl)
      .subscribe((data: any) => {
        this.productWithOffers = data;

        this.favoruiteProducts.forEach(element => {
          let offerData = this.productWithOffers.find(offer => offer.productCode === element.product_Code);
          if (offerData) {
            element.offerDetails = offerData.offerDetails;
            element.offers = offerData.offers;
          }
        });

        // this.getProductWithStock();
        // this.getWebOrderDeliverySetUpList();
      });
  }

  addToCart2(product: Product) {
    // this.productService.addToCart(product);
    const products: Product[] = JSON.parse(this.localStorage.getItem("avct_item")) || [];
    console.log(products);
    console.log(product);

    if (product?.cartProductType == "Make To Order") {
      this.navRoute.navigate(["/make-product", product.product_Code]);
      if (products.some((temp) => temp.product_Code == product.product_Code)) {
        console.log("Not removed");
      } else {
        console.log("removed");
        this.removeFavourite(product.product_Code);
      }
    } else {
      this.pincode = JSON.parse(this.localStorage.getItem("pincode_item"));
      console.log(this.pincode);
      if (this.pincode == null) {
        const dialogRef = this.dialog.open(PinDialogComponent, {
          width: "300px",
          data: product,
          panelClass: "pin-dialog-container",
        });

        dialogRef.afterClosed().subscribe((result) => {
          if (result?.result == true) {
            // this.getGroupList();
            let selfLife = JSON.parse(this.localStorage.getItem("min_selflife"));
            if (product.shelf_Life_In_Hours >= selfLife) {
              this.productService.addToCart(product);
              if (products.some((temp) => temp.product_Code == product.product_Code)) {
                console.log("Not removed");
              } else {
                console.log("removed");
                this.removeFavourite(product.product_Code);
              }
            } else {
              this.toastrService.showErrorToast("Cannot add to cart, shelf life is under " + selfLife + " hours.");
            }
          }
        });
      } else {
        let selfLife = JSON.parse(this.localStorage.getItem("min_selflife"));
        if (product.shelf_Life_In_Hours >= selfLife) {
          this.productService.addToCart(product);
          if (products.some((temp) => temp.product_Code == product.product_Code)) {
            console.log("Not removed");
          } else {
            console.log("removed");
            this.removeFavourite(product.product_Code);
          }
        } else {
          this.toastrService.showErrorToast("Cannot add to cart, shelf life is under " + selfLife + " hours.");
        }
      }
    }
  }

  addToCart(product: Product, goToCartPage: boolean) {
    this.openDeliveryTypeDialog(product, goToCartPage);
  }

  openDeliveryTypeDialog(product: Product, goToCartPage: boolean) {
    const products: Product[] = JSON.parse(this.localStorage.getItem("avct_item")) || [];

    if (product?.cartProductType == "Make To Order") {
      this.navRoute.navigate(["/make-product", product.product_Code]);
      if (products.some((temp) => temp.product_Code == product.product_Code)) {
        console.log("Not removed");
      } else {
        console.log("removed");
        this.removeFavourite(product.product_Code);
      }
    } else {
      let deliveryType = JSON.parse(this.localStorage.getItem('delivery_type'));
      console.log(deliveryType)
      if (!deliveryType) {
        const dialogRef = this.dialog.open(StorePickupDialogComponent, {
          width: '300px',
          autoFocus: false,
          panelClass: 'store-dialog-container'
        });

        dialogRef.afterClosed().subscribe(result => {
          console.log(result)
          if (result) {
            if (result === 'storePickup') {
              this.setStorePickup(product, goToCartPage);
            } else if (result === 'delivery') {
              this.openPinDialog(product, goToCartPage);
            }
          }
        });
      } else {
        if (deliveryType === 'storePickup') {
          this.setStorePickup(product, goToCartPage);
        } else if (deliveryType === 'delivery') {
          this.openPinDialog(product, goToCartPage);
        }
      }
    }
  }

  setStorePickup(product: Product, goToCartPage: boolean) {
    let selfLife = JSON.parse(this.localStorage.getItem('min_selflife'));
    if (product.shelf_Life_In_Hours >= selfLife) {
      product.cartProductType = 'Product';
      this.getDesignBySubGroupAndUrl(product, goToCartPage);
    } else {
      this.toastrService.showErrorToast("Cannot add to cart, shelf life is under " + selfLife + " hours.");
    }
  }

  openPinDialog(product: Product, goToCartPage: boolean) {
    this.pincode = this.localStorage.getItem("pincode_item");
    console.log(product);

    if (this.pincode == null) {
      const dialogRef = this.dialog.open(PinDialogComponent, {
        width: "300px",
        panelClass: "pin-dialog-container",
      });

      dialogRef.afterClosed().subscribe((result) => {
        if (result?.result == true) {
          let selfLife = JSON.parse(this.localStorage.getItem("min_selflife"));
          if (product.shelf_Life_In_Hours >= selfLife) {
            product.cartProductType = "Product";
            this.getProductWithStock(product, goToCartPage);
          } else {
            this.toastrService.showErrorToast("Cannot add to cart, shelf life is under " + selfLife + " hours.");
          }
        }
      });
    } else {
      let selfLife = JSON.parse(this.localStorage.getItem("min_selflife"));
      if (product.shelf_Life_In_Hours >= selfLife) {
        product.cartProductType = "Product";
        this.getProductWithStock(product, goToCartPage);
      } else {
        this.toastrService.showErrorToast("Cannot add to cart, shelf life is under " + selfLife + " hours.");
      }
    }
  }

  getProductWithStock(product: Product, goToCartPage: boolean) {
    let locId = JSON.parse(this.localStorage.getItem("location_code"));
    if (locId) {
      this.checkAllowStockSubscription = this.productService.checkAllowStockByLocAndUrl(this.apiFactory.checkAllowStockByLocAndUrl, locId, this.currentUrl).subscribe((data: any) => {
        if (data) {
          if (product.product_Code) {
            this.stockSubscription = this.productService.getStockDetails(this.apiFactory.getStockDetails, [product.product_Code], locId).subscribe((data: any) => {
              this.productWithStock = data;

              var arr = this.productWithStock.filter((p) => p.product_Code == product.product_Code);
              if (arr.length != 0) {
                product.systemStock = arr[0].systemStock;
              }
              if (product.cartProductType == 'Product' && product.minimum_Order_Quantity > product.systemStock) {
                this.toastrService.showErrorToast(product.product_Name + " is out of stock, stock available " + product.systemStock);
                return;
              }
              this.getDesignBySubGroupAndUrl(product, goToCartPage);
            },
              (error: HttpErrorResponse) => {
                this.toastrService.showErrorToast("Something wrong..");
              });
          } else {

          }
        } else {
          this.getDesignBySubGroupAndUrl(product, goToCartPage);
        }
      },
        (error: HttpErrorResponse) => {
          this.toastrService.showErrorToast("Something wrong..");
        })
    } else {
      this.toastrService.showErrorToast("Location can't place order");
    }
  }

  getDesignBySubGroupAndUrl(product: Product, goToCartPage: boolean) {
    const products: Product[] = JSON.parse(this.localStorage.getItem("avct_item")) || [];
    if (products.some((temp) => temp.product_Code == product.product_Code)) {
      if (goToCartPage) {
        this.productService.navigateToCart(product);
        return;
      }
      else {
        this.toastrService.showErrorToast(product.product_Name + ' is allready added to cart');
        return;
      }
    }
    this.getDesignSubscription = this.productService.getWebOrderDesigns(this.apiFactory.getWebOrderDesignBySubgroupAndUrl, product.productSubGroup.refNo, this.currentUrl).subscribe((data) => {
      console.log(data);
      if (data.length > 0) {
        this.designProducts = data;
        const cartProducts: Product[] = JSON.parse(this.localStorage.getItem("avct_item")) || [];
        let designIndex = cartProducts.findIndex(e => e.designForProductCode === product.product_Code);
        if (designIndex >= 0) {
          this.designProducts.map(e => e.is_Default = false);
          this.designProducts.filter(item => item.product_Code == cartProducts[designIndex].product_Code).map(e => e.is_Default = true);
        }
        this.openDesignDialog(product, this.designProducts, goToCartPage);
      } else {
        if (product.offerDetails?.length > 0)
          this.openOfferDialog(product, null, goToCartPage)
        else {
          this.productService.addToCart(product);
          if (products.some((temp) => temp.product_Code == product.product_Code)) {
            console.log("Not removed");
          } else {
            console.log("removed");
            this.removeFavourite(product.product_Code);
          }

          if (goToCartPage) {
            //Added to redirect to cart
            this.productService.navigateToCart(product);
          }
        }
      }
    });
  }

  openDesignDialog(product: Product, designProducts: Product[], goToCartPage: boolean) {
    const products: Product[] = JSON.parse(this.localStorage.getItem("avct_item")) || [];

    document.body.classList.add('dialog-open');
    const dialogRef = this.dialog.open(ProductDesignDialogComponent, {
      width: "430px",
      height: "410px",
      maxWidth: "90vw",
      autoFocus: false,
      data: designProducts,
      panelClass: "custom-dialog-container",
    });

    dialogRef.afterClosed().subscribe((result) => {
      document.body.classList.remove('dialog-open');
      console.log(result)
      if (result) {
        if (result?.data) {
          let design: Product = result?.data;
          design.designForProductCode = product.product_Code;
          design.cartProductType = "Product";
          if (product.offerDetails?.length > 0)
            this.openOfferDialog(product, design, goToCartPage)
          else {
            this.productService.addToCartDesign(product, design);
            if (products.some((temp) => temp.product_Code == product.product_Code)) {
              console.log("Not removed");
            } else {
              console.log("removed");
              this.removeFavourite(product.product_Code);
            }

            if (goToCartPage) {
              //Added to redirect to cart
              this.productService.navigateToCart(product);
            }
          }
        } else if (result?.noDesign) {
          if (product.offerDetails?.length > 0)
            this.openOfferDialog(product, null, goToCartPage)
          else {
            this.productService.addToCart(product);
            if (products.some((temp) => temp.product_Code == product.product_Code)) {
              console.log("Not removed");
            } else {
              console.log("removed");
              this.removeFavourite(product.product_Code);
            }

            if (goToCartPage) {
              //Added to redirect to cart
              this.productService.navigateToCart(product);
            }
          }
        }
      }
    });
  }

  openOfferDialog(product: Product, design: Product, goToCartPage: boolean) {
    console.log(product)
    const cartProducts: Product[] = JSON.parse(this.localStorage.getItem("avct_item")) || [];
    if (cartProducts.some((temp) => temp.product_Code == product.product_Code)) {
      if (goToCartPage) {
        this.productService.navigateToCart(product);
        return;
      }
      else {
        this.toastrService.showErrorToast(product.product_Name + ' is allready added to cart');
        return;
      }
    }
    let offers: ViewOfferSetUpDto[] = product.offerDetails;
    let appliedOfferId: string = product.offerId ? product.offerId : product.offerDetails[0].offerId;
    const dialogRef = this.dialog.open(OfferDialogComponent, {
      width: "500px",
      height: "300px",
      data: { offers, appliedOfferId },
    });

    dialogRef.afterClosed().subscribe((offerObj) => {
      console.log(offerObj);
      if (offerObj) {
        this.offerObj = offerObj;
        //conditions
        var orderQuantity;
        var freeByProductCode;
        var cartProductType;

        if (product) {
          product.orderQuantity = offerObj.minQty;
          orderQuantity = product.orderQuantity;
          freeByProductCode = product.product_Code;
          cartProductType = product.cartProductType;
          if (product.cartProductType == "Product") {
            product.basePremiumFlavourPrice = 0;
            product.premiumFlavourPrice = 0;
          }
        }
        console.log(product);

        if (offerObj.schemeType == "Buy 1 get 1 Free" || offerObj.schemeType == "Buy X get Y Free") {
          this.productOfferSubscription = this.productService.getProductOfferDetails(this.apiFactory.productOfferDetails, offerObj.offerId, orderQuantity)
            .subscribe(
              (data) => {
                // offer processing
                this.productOffer = data;
                //set order qty as product QTY IN CART
                this.productOffer.product_Name = this.productOffer.product_Name.concat("(").concat(offerObj.schemeType).concat(")");
                this.productOffer.orderQuantity = orderQuantity;
                this.productOffer.net_Sale_Price = offerObj.productPriceForCustomer;
                this.productOffer.sale_Price = offerObj.productPriceForCustomer;
                this.productOffer.discountAmt = 0;
                this.productOffer.discountRate = 0;
                this.productOffer.freeProductNetSalePrice = offerObj.netSalePrice;
                this.productOffer.freeByProductCode = freeByProductCode;
                this.productOffer.cartProductType = cartProductType;
                this.productOffer.product_Type = this.productOffer.product_Type;
                this.productOffer.product_Unit = this.productOffer.product_Unit;
                this.productOffer.food_Type = this.productOffer.food_Type;
                this.productOffer.hsn_Code = this.productOffer.hsn_Code;

                // main product tax and net amount calculations
                product.discountAmt = 0;
                product.discountRate = 0;
                var taxableAmt = product.sale_Price - (product.sale_Price * 0) / 100;
                taxableAmt = taxableAmt + product.basePremiumFlavourPrice;
                var cess = product.cess.cessRate;
                var cessAmt = (cess / 100) * taxableAmt;
                var cgst = (product.tax.taxRate / 2 / 100) * taxableAmt;
                var sgst = (product.tax.taxRate / 2 / 100) * taxableAmt;
                var tot = cgst + sgst;
                product.promotionOfferId = offerObj.offerId;
                product.offerId = offerObj.offerId;
                product.taxAmount = tot; //Tax Amount
                product.cgstAmt = cgst;
                product.sgstAmt = sgst;
                product.cessAmount = cessAmt;
                product.net_Sale_Price = taxableAmt + tot + cessAmt;
                console.log(product);
                this.productService.addToCartWithOffer(product, this.productOffer, design);
                if (cartProducts.some((temp) => temp.product_Code == product.product_Code)) {
                  console.log("Not removed");
                } else {
                  console.log("removed");
                  this.removeFavourite(product.product_Code);
                }

                if (goToCartPage) {
                  //Added to redirect to cart
                  this.productService.navigateToCart(product);
                }
              },
              (err) => {
                if (err.error) {
                  this.toastrService.showErrorToast(err.error.message);
                }
              }
            );
        } else {
          if (offerObj.schemeType == "Buy x get x percent off") {
            this.productDiscountSubscription = this.productService.getProductDiscountDetails(this.apiFactory.productDiscountDetails, offerObj.offerId, orderQuantity)
              .subscribe(
                (data) => {
                  this.discountOffer = data;
                  product.discountAmt = (this.discountOffer / 100) * product.sale_Price;
                  product.discountRate = this.discountOffer;
                  var taxableAmt = product.sale_Price - (product.sale_Price * this.discountOffer) / 100;
                  taxableAmt = taxableAmt + product.basePremiumFlavourPrice;
                  var cess = product.cess.cessRate;
                  var cessAmt = (cess / 100) * taxableAmt;
                  var cgst = (product.tax.taxRate / 2 / 100) * taxableAmt;
                  var sgst = (product.tax.taxRate / 2 / 100) * taxableAmt;
                  var tot = cgst + sgst;
                  product.promotionOfferId = offerObj.offerId;
                  product.offerId = offerObj.offerId;
                  product.taxAmount = tot; //Tax Amount
                  product.cgstAmt = cgst;
                  product.sgstAmt = sgst;
                  product.cessAmount = cessAmt;
                  product.net_Sale_Price = taxableAmt + tot + cessAmt;
                  this.productService.addToCartWithOffer(product, null, design);
                  if (cartProducts.some((temp) => temp.product_Code == product.product_Code)) {
                    console.log("Not removed");
                  } else {
                    console.log("removed");
                    this.removeFavourite(product.product_Code);
                  }

                  if (goToCartPage) {
                    //Added to redirect to cart
                    this.productService.navigateToCart(product);
                  }
                },
                (err) => {
                  if (err.error) {
                    this.toastrService.showErrorToast(err.error.message);
                  }
                }
              );
          } else {
            this.productBulkDiscountSubscription = this.productService.getProductBulkDiscountDetails(this.apiFactory.productBulkOfferDetails, offerObj.offerId)
              .subscribe((data) => {
                this.bulkDiscountOffer = data;
                product.discountAmt = (this.bulkDiscountOffer.discount / 100) * product.sale_Price;
                product.discountRate = this.bulkDiscountOffer.discount;
                var taxableAmt = product.sale_Price - (product.sale_Price * this.bulkDiscountOffer.discount) / 100;
                taxableAmt = taxableAmt + product.basePremiumFlavourPrice;
                var cess = product.cess.cessRate;
                var cessAmt = (cess / 100) * taxableAmt;
                var cgst = (product.tax.taxRate / 2 / 100) * taxableAmt;
                var sgst = (product.tax.taxRate / 2 / 100) * taxableAmt;
                var tot = cgst + sgst;
                product.promotionOfferId = offerObj.offerId;
                product.offerId = offerObj.offerId;
                product.taxAmount = tot; //Tax Amount
                product.cgstAmt = cgst;
                product.sgstAmt = sgst;
                product.cessAmount = cessAmt;
                product.net_Sale_Price = taxableAmt + tot + cessAmt;
                this.productService.addToCartWithOffer(product, null, design);
                if (cartProducts.some((temp) => temp.product_Code == product.product_Code)) {
                  console.log("Not removed");
                } else {
                  console.log("removed");
                  this.removeFavourite(product.product_Code);
                }

                if (goToCartPage) {
                  //Added to redirect to cart
                  this.productService.navigateToCart(product);
                }
              });
          }
        }
      } else {
        // if (design)
        //   this.productService.addToCartDesign(product, design);
        // else
        //   this.productService.addToCart(product);
      }
    });
  }

  viewOfferDetail(offers: ViewOfferSetUpDto[]) {
    console.log(offers)
    let dialogRef = this.dialog.open(OfferDetailsDialogComponent, {
      width: '550px',
      minHeight: '18%',
      maxWidth: '90%',
      maxHeight: '70%',
      autoFocus: false,
      data: { offerData: offers }
    });

    dialogRef.afterClosed().subscribe(res => {

    })
  }

  goShopping() {
    this.navRoute.navigate(["/products"]);
  }


  @ViewChild('action') action!: NgxScannerQrcodeComponent;
  isScannerVisible: boolean = false;

  openScanner() {
    this.isScannerVisible = true;
    const playDeviceFacingBack = (devices: any[]) => {
      // front camera or back camera check here!
      const device = devices.find((f) =>
        /back|rear|environment/gi.test(f.label)
      ); // Default Back Facing Camera
      this.action.playDevice(device ? device.deviceId : devices[0].deviceId);
    };

    navigator.mediaDevices.enumerateDevices().then((devices) => {
      playDeviceFacingBack(devices);
      navigator.mediaDevices.getUserMedia({
        video: {
          deviceId: { exact: devices.find((f) => /back|rear|environment/gi.test(f.label))?.deviceId || devices[0].deviceId }
        }
      }).then((stream) => {
        this.action.start();
      }).catch((error) => {
        console.error("Error accessing media devices.", error);
      });
    });
  }

  public onEvent(e: ScannerQRCodeResult[], action?: any): void {
    if (e && e.length > 0) {
      const result = e[0].value; // Assuming you want the first result
      alert(`QR Code Result: ${result}`);
      if (action) {
        action.pause();
        this.closeScanner(); // Pause the scanner after a successful scan
      }
    }
  }

  closeScanner() {
    this.isScannerVisible = false;
    if (this.action) {
      this.action.stop();
      // Stop the scanner
    }
    this.action = null;
  }

  camerPermission1() {
    document.title = "1 Bake Shop | Online Bakery";
    navigator.mediaDevices.getUserMedia({ video: true })
      .then((stream) => {
        // Permission granted, you can now use the stream
        alert('Camera permission granted.');
        // Do something with the stream, e.g., display it in a video element
        const video = document.querySelector('video');
        if (video) {
          video.srcObject = stream;
        }
      })
      .catch((err) => {
        // Permission denied or an error occurred
        alert('Camera permission denied or an error occurred: ' + err);
      });
  }

  cameraPermission() {
    document.title = "1 Bake Shop | Online Bakery";

    navigator.permissions.query({ name: 'camera' as any }).then((permissionStatus) => {
      if (permissionStatus.state === 'granted') {
        // Permission already granted
        // alert('Camera permission already granted.');
      } else if (permissionStatus.state === 'prompt') {
        // Permission not yet requested
        this.requestCameraPermission();
      } else {
        // Permission denied
        alert('Camera permission denied.');
      }
    }).catch((err) => {
      console.error('Error checking camera permission:', err);
      this.requestCameraPermission(); // Fallback to requesting permission
    });
  }

  requestCameraPermission() {
    navigator.mediaDevices.getUserMedia({ video: true })
      .then((stream) => {
        // Permission granted, you can now use the stream
        alert('Camera permission granted.');
      })
      .catch((err) => {
        // Permission denied or an error occurred
        alert('Camera permission denied or an error occurred: ' + err);
      });
  }

  jisScannerVisible: boolean = false;
  scannedResult: string | null = null;
  availableDevices: MediaDeviceInfo[] = [];
  selectedDevice: MediaDeviceInfo | undefined;
  isLoading: boolean = false;
  // Request camera permission on initialization
  jrequestCameraPermission1(): void {
    this.isLoading = true; // Show loading indicator

    if (navigator.mediaDevices && navigator.mediaDevices.enumerateDevices) {
      navigator.mediaDevices.enumerateDevices().then((devices) => {
        this.availableDevices = devices.filter(
          (device) => device.kind === 'videoinput'
        );

        // If no video device is available
        if (this.availableDevices.length === 0) {
          alert('No camera device found');
          this.isLoading = false;
        } else {
          this.selectDevice();
        }
      });
    } else {
      alert('Camera access is not supported by your browser.');
      this.isLoading = false;
    }
  }

  // Select back camera if available, otherwise use the first available device
  selectDevice(): void {
    const backCamera = this.availableDevices.find((device) =>
      /back|rear|environment/gi.test(device.label)
    );
    this.selectedDevice = backCamera || this.availableDevices[0];
    this.isLoading = false; // Hide loading indicator when ready
  }

  jopenScanner(): void {
    this.jisScannerVisible = true;

    // Check for camera permissions
    navigator.permissions.query({ name: 'camera' as PermissionName }).then((result) => {
      if (result.state === 'granted') {
        // Permission is already granted, start the camera
        this.startCamera();
      } else if (result.state === 'prompt') {
        // Permission has not been granted yet, request it
        this.jrequestCameraPermission();
      } else if (result.state === 'denied') {
        // Permission is denied, show an alert
        alert('Camera access is denied. Please allow camera access in your browser settings.');
        this.jisScannerVisible = false;
      }
    }).catch((error) => {
      console.error('Error checking camera permissions:', error);
      alert('Unable to access camera permissions.');
      this.jisScannerVisible = false;
    });
  }

  private startCamera(): void {
    navigator.mediaDevices
      .getUserMedia({
        video: { deviceId: { exact: this.selectedDevice?.deviceId } },
      })
      .then((stream) => {
        alert('Camera access granted');
        console.log('Camera access granted');
      })
      .catch((err) => {
        console.error('Error accessing the camera: ', err);
        alert('Unable to start the camera. Please check your device settings.');
      });
  }

  private jrequestCameraPermission(): void {
    navigator.mediaDevices
      .getUserMedia({ video: true })
      .then((stream) => {
        console.log('Permission granted, starting the camera.');
        this.startCamera();
      })
      .catch((error) => {
        console.error('Permission to access the camera was denied:', error);
        alert('Camera access denied. Please allow camera access to use this feature.');
        this.jisScannerVisible = false;
      });
  }


  jcloseScanner(): void {
    this.jisScannerVisible = false;
    this.scannedResult = null; // Reset scanned result
  }

  onCodeResult(result: string): void {
    this.scannedResult = result;
    alert(`Scanned QR Code: ${result}`);
    this.jcloseScanner(); // Close scanner after successful scan
  }

  latitudeMap: number | null = null;
  longitudeMap: number | null = null;
  private setCurrentLocation(): void {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          this.latitudeMap = position.coords.latitude;
          this.longitudeMap = position.coords.longitude;
          const accuracy = position.coords.accuracy; // Accuracy in meters
          console.log(
            'Latitude:', this.latitudeMap,
            'Longitude:', this.longitudeMap,
            'Accuracy:', accuracy, 'meters'
          );
          this.toastrService.showSuccessToast('Location accuracy is low. Please try again in an open area. ' + accuracy.toString() + ' meters'
          );

          if (accuracy <= 50) { // Optional: Use data only if accuracy is within 50 meters
            // this.getLocationByLatAndLng();
          } else {
            this.toastrService.showErrorToast(
              'Location accuracy is low. Please try again in an open area. ' + accuracy.toString() + ' meters'
            );
          }
        },
        (error) => {
          switch (error.code) {
            case error.PERMISSION_DENIED:
              this.toastrService.showErrorToast('Location permission denied. Please allow location access in your browser settings.');
              break;
            case error.POSITION_UNAVAILABLE:
              this.toastrService.showErrorToast('Location information is unavailable. Please try again later.');
              break;
            case error.TIMEOUT:
              this.toastrService.showErrorToast('The request to get user location timed out. Please try again.');
              break;
            default:
              this.toastrService.showErrorToast('An unknown error occurred. Please try again.');
              break;
          }
        },
        {
          enableHighAccuracy: true,
          timeout: 30000, // Increased timeout
          maximumAge: 0 // Avoid using cached location
        }
      );
    } else {
      this.toastrService.showErrorToast('Geolocation is not supported by your browser.');
    }

    fetch('https://www.googleapis.com/geolocation/v1/geolocate?key=AIzaSyBjYCnVP9NRGpgBuDVfMUU5nb2a94sHErg', {
      method: 'POST',
      body: JSON.stringify({}),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log('Location from Google API:', data.location);
        console.log('Accuracy:', data.accuracy, 'meters');
      })
      .catch((error) => console.error('Error:', error));

  }
}
