import { Component, Inject, OnInit } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA, } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { Subscription } from "rxjs";
import { APIFactoryEndPoints } from "src/app/common/apifactory-end-points";
import { LocalStorageService } from "src/app/common/LocalStorageService";
import { CartDetails } from "src/app/shared/models/cartdetails";
import { FBCustomer } from "src/app/shared/models/fbcustomer";
import { GoogleCustomer } from "src/app/shared/models/googlecustomer";
import { Product } from "src/app/shared/models/product";
import { WishListDetails } from "src/app/shared/models/wishlistdetails";
import { CommonService } from "src/app/shared/services/CommonService";
import { ContactService } from "src/app/shared/services/contact.service";
import { ToastService } from "src/app/shared/services/toastr.service";
import { UserService } from "src/app/shared/services/user.service";
import { getWindow } from "ssr-window";
import { SignupDialogComponent } from "../signup-dialog/signup-dialog.component";
import { NavbarComponent } from "../navbar/navbar.component";
import { HttpErrorResponse } from "@angular/common/http";
import { LocationDto } from "src/app/shared/models/locationdto";

@Component({
  selector: "app-phone-number-dialog",
  templateUrl: "./phone-number-dialog.component.html",
  styleUrls: ["./phone-number-dialog.component.scss"],
})
export class PhoneNumberDialogComponent implements OnInit {

  createPhoneSetUpForm: UntypedFormGroup;
  googleCustomer = new GoogleCustomer();
  fbCustomer = new FBCustomer();

  phoneNo: string;
  existingPhoneNo: string;
  checkLoggedIn: string;

  googleCustomerLocal = new GoogleCustomer();
  cartDetails: CartDetails = new CartDetails();
  mtoCartDetails: CartDetails = new CartDetails();

  cartList: Product[] = [];
  wishList: Product[] = [];
  wishlistDetails: WishListDetails = new WishListDetails();
  location: LocationDto = new LocationDto();

  showHideSendOtpDiv: boolean = true;
  showHideOtpDiv: boolean = false;
  showHideSubmitDiv: boolean = false;
  isShowWait: boolean = false;

  randomString: string = "";
  currentUrl: string;



  //Subscription
  private onGoogleSocialSetUpSubscription: Subscription;
  private onFBSocialSetUpSubscription: Subscription;
  private getProfileSubscription: Subscription;
  private cartSetUpSubscription: Subscription;
  private wishListSubscription: Subscription;
  private validateCustomerSubscription: Subscription;
  private whatsAppOptInByMobileNoSubscription: Subscription;
  private whatsAppOTPByMobileNoSubscription: Subscription;
  private checkNumberVerfiedSubscription: Subscription;
  private getLocationSubscription: Subscription;

  constructor(
    private localStorage: LocalStorageService,
    public dialogRef: MatDialogRef<PhoneNumberDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public LoggedInData: any,
    private formBuilder: UntypedFormBuilder,
    private apiFactory: APIFactoryEndPoints,
    private toastService: ToastService,
    private commonService: CommonService,
    private contactService: ContactService,
    private navRoute: Router,
    private navbarComponent: NavbarComponent,
    private userService: UserService,
    public dialog: MatDialog
  ) {
    dialogRef.disableClose = true;
  }

  ngOnInit() {
    this.currentUrl = getWindow().location.hostname;
    this.onCreateSetUp();
    console.log(this.LoggedInData?.email)
    if (this.LoggedInData?.email) {
      this.getProfileDetails();
      this.getLocationByName();
      this.getCartProduct();
      this.getWishlistProduct();
    }
  }

  getProfileDetails() {
    this.getProfileSubscription = this.userService.getProfiledetails(this.apiFactory.getGoogleCustomer, this.LoggedInData.email, this.currentUrl).subscribe((data) => {
      if (data) {
        if (!data.customerStatus || data.customerStatus != 'Inactive') {
          this.createPhoneSetUpForm.patchValue({
            phoneNo: data.phoneNo,
          });
          console.log(this.createPhoneSetUpForm.get("phoneNo").value);
          this.existingPhoneNo = data.phoneNo;
          //Commented for wp verification disable
          this.checkOTPSentOrNot(this.createPhoneSetUpForm.get("phoneNo").value);
        } else if (data.customerStatus == 'Inactive') {
          this.toastService.showErrorToast("User deleted can't sign in");
          setTimeout(() => {
            this.navbarComponent.logout(false);
            this.dialogRef.close();
          }, 1000);
        }
      }
      else {
        this.isShowWait = true;
      }
    });
  }

  getLocationByName() {
    this.getLocationSubscription = this.commonService.getLocationByName(this.apiFactory.getLocationByLocName, this.currentUrl).subscribe((data) => {
      if (data) {
        this.location = data;
      }
    });
  }

  onCreateSetUp() {
    this.createPhoneSetUpForm = this.formBuilder.group({
      phoneNo: ['', [Validators.required, Validators.pattern(/^[6-9]\d{9}$/)]],
    });
  }

  getWhatsAppProfileDetails(phoneNo: string) {
    this.getProfileSubscription = this.userService.getProfiledetails(this.apiFactory.getCustomerByPhoneNo, phoneNo, this.currentUrl)
      .subscribe((data) => {
        console.log(data);
        if (data != null) {
          localStorage.setItem("google_email", JSON.stringify(data.email));
          localStorage.setItem("google_item", JSON.stringify(data.name));
          localStorage.setItem("google_id", JSON.stringify(data.email));
          localStorage.setItem("google_provider", JSON.stringify(data.provider));

          this.googleCustomer = new GoogleCustomer();

          this.googleCustomer.id = data.id;
          this.googleCustomer.name = data.name;
          this.googleCustomer.email = data.email;
          this.googleCustomer.firstName = data.firstName;
          this.googleCustomer.lastName = data.lastName;
          this.googleCustomer.provider = data.provider;
          this.googleCustomer.phoneNo = data.phoneNo;

          console.log(this.googleCustomer);
          this.localStorage.setItem("google_loggedIn_details", JSON.stringify(this.googleCustomer));

          this.toastService.showSuccessToast("Sucessfully Logged In");
          this.dialogRef.close();
          this.checkLoggedIn = this.userService.getCheckLoggedIn();

          if (this.checkLoggedIn == "checkout") {
            this.navRoute.navigate(["/delivery-address"]);
          } else if (this.checkLoggedIn == "customized-product") {
            this.navRoute.navigate(["/customized-product"]);
          } else {
            this.navRoute.navigate(["/"]);
          }
          this.saveCartProducts();
          this.saveWishListProducts();
          // window.location.reload();
        } else {
          this.openSignupDialog(phoneNo);
        }
      });
  }

  openSignupDialog(mobileNo: string) {
    const dialogRef = this.dialog.open(SignupDialogComponent, {
      width: "400px",
      data: mobileNo,
    });
    dialogRef.afterClosed().subscribe((result: GoogleCustomer) => {
      console.log("The dialog was closed", result);
      if (result) {
        this.googleCustomer = result;
        console.log(this.googleCustomer);
        this.localStorage.setItem("google_loggedIn_details", JSON.stringify(this.googleCustomer));

        this.toastService.showSuccessToast("Sucessfully Logged In");
        this.dialogRef.close();
        this.checkLoggedIn = this.userService.getCheckLoggedIn();

        if (this.checkLoggedIn == "checkout") {
          this.navRoute.navigate(["/delivery-address"]);
        }
        else if (this.checkLoggedIn == "customized-product") {
          this.navRoute.navigate(["/customized-product"]);
        } else {
          this.navRoute.navigate(["/"]);
        }
        this.saveCartProducts();
        this.saveWishListProducts();
      }
    });
  }

  sendOtpClick() {
    this.randomString = this.generateUniqueID();
    this.phoneNo = this.createPhoneSetUpForm.get("phoneNo").value;

    // console.log(this.randomString)
    // this.showHideOtpDiv = true;
    // this.showHideSendOtpDiv = false;

    if (this.phoneNo.length == 10) {
      this.validateCustomerSubscription = this.userService.validateCustomer(this.apiFactory.validateCustomer, this.LoggedInData?.email, this.phoneNo)
        .subscribe((validate: any) => {
          if (validate) {
            this.whatsAppOptInByMobileNoSubscription = this.userService.sendWhatsAppOptInByMobileNo(this.apiFactory.sendWhatsAppOptInByMobileNo, this.currentUrl, this.phoneNo)
              .subscribe((data: any) => {
                if (data) {
                  this.whatsAppOptInByMobileNoSubscription = this.userService.sendWhatsAppOTPByMobileNo(this.apiFactory.sendWhatsAppOTPByMobileNo, this.currentUrl, this.phoneNo, this.randomString)
                    .subscribe((data: any) => {
                      if (data) {
                        console.log(data.eventType)
                        if (data.eventType && (data.eventType != "FAILED" || data.status == 'success')) {
                          this.showHideSendOtpDiv = false;
                          this.showHideOtpDiv = true;
                          this.toastService.showSuccessToast("OTP Sent");
                        } else {
                          this.showHideSendOtpDiv = true;
                          this.showHideOtpDiv = false;
                          this.toastService.showErrorToast("OTP Not Sent");
                        }
                      }
                    });
                }
              });
          }
        });
    } else {
      this.toastService.showErrorToast("Invalid Mobile No");
    }
  }

  reSendOtpClick() {
    this.whatsAppOptInByMobileNoSubscription = this.userService.sendWhatsAppOTPByMobileNo(this.apiFactory.sendWhatsAppOTPByMobileNo, this.currentUrl, this.phoneNo, this.randomString)
      .subscribe((data: any) => {
        if (data) {
          this.toastService.showSuccessToast("OTP Resent");
        }
      });
  }

  checkOtp(value: any) {
    // console.log(this.randomString);
    if (value.length == 6) {
      if (this.randomString == value) {
        this.showHideOtpDiv = false;
        this.showHideSubmitDiv = false;
        this.onPhoneSetUpClick();
        this.toastService.showSuccessToast("OTP Verfied");
      } else {
        this.showHideOtpDiv = true;
        this.showHideSubmitDiv = false;
        this.toastService.showErrorToast("Invalid OTP");
      }
    }
  }

  validateNumber(event: KeyboardEvent) {
    const input = event.key;
    const mobileNoControl = this.createPhoneSetUpForm.get('phoneNo');

    // Allow only digits (0-9)
    if (!/^\d$/.test(input)) {
      event.preventDefault();
      return;
    }

    // Prevent input if already 10 digits
    if (mobileNoControl?.value && mobileNoControl.value.length >= 10) {
      event.preventDefault();
    }
  }

  checkMobileNo(value: any) {
    if (value.length == 10) {
      if (this.existingPhoneNo == value) {
        this.showHideSendOtpDiv = false;
        this.showHideOtpDiv = false;
        this.showHideSubmitDiv = true;
      } else {
        this.showHideSendOtpDiv = true;
        this.showHideOtpDiv = false;
        this.showHideSubmitDiv = false;
      }
    }
  }

  checkOTPSentOrNot(value: any) {

    if (value.length == 10) {
      this.checkNumberVerfiedSubscription = this.contactService.checkOTPSentOrNot(this.apiFactory.checkWhatsAppVerifiedOrNot, value)
        .subscribe((data: string) => {
          this.isShowWait = true;
          if (data == "Yes") {
            this.showHideSendOtpDiv = false;
            this.showHideOtpDiv = false;
            this.showHideSubmitDiv = true;
          }
          if (data == "No") {
            this.showHideSendOtpDiv = true;
            this.showHideOtpDiv = false;
            this.showHideSubmitDiv = false;
            // this.sendOtpClick();
          }
        },
          (error: HttpErrorResponse) => {
            this.isShowWait = true;
          });
    }
  }

  onPhoneSetUpClick() {
    if (this.createPhoneSetUpForm.invalid) {
      this.toastService.showErrorToast("Invalid form");
      return;
    }
    this.phoneNo = this.createPhoneSetUpForm.get("phoneNo").value;
    this.checkLoggedIn = this.userService.getCheckLoggedIn();

    this.googleCustomer = new GoogleCustomer();
    if (this.LoggedInData?.provider == "GOOGLE" || this.LoggedInData?.provider == "FACEBOOK") {
      this.googleCustomer.id = this.LoggedInData.id;
      this.googleCustomer.name = this.LoggedInData.name;
      this.googleCustomer.email = this.LoggedInData.email;
      this.googleCustomer.firstName = this.LoggedInData.firstName;
      this.googleCustomer.lastName = this.LoggedInData.lastName;
      this.googleCustomer.idToken = this.LoggedInData.idToken;
      this.googleCustomer.authToken = this.LoggedInData.authToken;
      this.googleCustomer.provider = this.LoggedInData.provider;
      this.googleCustomer.phoneNo = this.phoneNo;
      this.googleCustomer.stateCode = this.location?.stateCode ?? 21;
      this.googleCustomer.locationCode = this.location?.refNo ?? 1;
      this.googleCustomer.source = this.checkLoggedIn == 'qr' ? 'QR' : 'Cart';

      this.onGoogleSocialSetUpSubscription = this.userService.onGoogleSetUp(this.apiFactory.insertGoogleCustomer, this.googleCustomer).subscribe((data: any) => {
        console.log(data);
        this.googleCustomer.refNo = data.refNo;
        this.localStorage.setItem("google_loggedIn_details", JSON.stringify(this.googleCustomer));
        this.toastService.showSuccessToast("Successfully Logged In");
        this.dialogRef.close();
        if (this.checkLoggedIn === "checkout") {
          this.navRoute.navigate(["/delivery-address"]);
        } else if (this.checkLoggedIn === "customized-product") {
          this.navRoute.navigate(["/customized-product"]);
        } else {
          this.navRoute.navigate(["/"]);
        }
        this.saveCartProducts();
        this.saveWishListProducts();
      });
    } else if (this.LoggedInData?.provider == "FACEBOOK2") {
      this.fbCustomer.id = this.LoggedInData.id;
      this.fbCustomer.name = this.LoggedInData.name;
      this.fbCustomer.email = this.LoggedInData.email;
      this.fbCustomer.firstName = this.LoggedInData.firstName;
      this.fbCustomer.lastName = this.LoggedInData.lastName;
      this.fbCustomer.authToken = this.LoggedInData.authToken;
      this.fbCustomer.provider = this.LoggedInData.provider;
      this.fbCustomer.phoneNo = this.phoneNo;

      this.localStorage.setItem("fb_loggedIn_details", JSON.stringify(this.fbCustomer));

      this.onFBSocialSetUpSubscription = this.userService.onFbSetUp(this.apiFactory.insertFBCustomer, this.fbCustomer).subscribe((data: any) => {
        this.toastService.showSuccessToast("Sucessfully Logged In");
        if (this.checkLoggedIn == "checkout") {
          this.navRoute.navigate(["/delivery-address"]);
        } else if (this.checkLoggedIn == "customized-product") {
          this.navRoute.navigate(["/customized-product"]);
          // this.navRoute.navigate(["/order-summary"]);
        } else {
          this.navRoute.navigate(["/"]);
        }
        this.saveCartProducts();
        this.saveWishListProducts();
      });
    } else if (this.LoggedInData?.provider == "WHATSAPP") {
      this.getWhatsAppProfileDetails(this.createPhoneSetUpForm.get("phoneNo").value);
    }
  }

  saveCartProducts() {
    this.googleCustomerLocal = JSON.parse(this.localStorage.getItem("google_loggedIn_details"));
    let locationCode: number = JSON.parse(this.localStorage.getItem("location_code"));

    const a: Product[] = JSON.parse(this.localStorage.getItem("avct_item")) || [];
    var updatedCart: Product[] = [];

    a.forEach((x) => {
      var isCheck: boolean = false;
      this.cartList.forEach((y) => {
        if (x.product_Code == y.product_Code) {
          isCheck = true;
          // updatedCart = updatedCart.concat(x);
        }
      });
      if (!isCheck) {
        updatedCart = a.concat(this.cartList);
      }
    });

    var productList: any = [];
    var mtoList: any = [];

    if (this.googleCustomerLocal != null) {
      updatedCart.forEach((element) => {
        if (element.cartProductType == "Product") {
          productList.push(element);
        } else {
          mtoList.push(element);
        }
      });

      this.cartDetails.status = "Not placed";
      this.cartDetails.locationCode = locationCode;
      this.cartDetails.logInCustomerEmail = this.googleCustomer.email;
      this.cartDetails.product = productList;

      this.mtoCartDetails.status = "Not placed";
      this.mtoCartDetails.locationCode = locationCode;
      this.mtoCartDetails.logInCustomerEmail = this.googleCustomer.email;
      this.mtoCartDetails.product = mtoList;
    }

    if (productList.length != 0) {
      this.cartSetUpSubscription = this.userService.insertCartSetUp(this.apiFactory.insertProductCart, this.cartDetails)
        .subscribe((data: any) => { });
    }

    if (mtoList.length != 0) {
      this.cartSetUpSubscription = this.userService.insertCartSetUp(this.apiFactory.insertMakeToOrderCart, this.mtoCartDetails)
        .subscribe((data: any) => { });
    }
    if (a.length != 0) {
      this.localStorage.setItem("avct_item", JSON.stringify(updatedCart));
    } else {
      this.localStorage.setItem("avct_item", JSON.stringify(this.cartList));
    }
  }

  getCartProduct() {
    this.googleCustomerLocal = JSON.parse(this.localStorage.getItem("google_loggedIn_details"));
    var productCart: Product[] = [];
    var mtoCart: Product[] = [];
    this.cartList = [];

    this.cartSetUpSubscription = this.userService.getProductCart(this.apiFactory.getProductCart, this.LoggedInData.email)
      .subscribe((data: any) => {
        if (data != null) {
          productCart = data.product;
        }
      });

    setTimeout(() => {
      this.cartSetUpSubscription = this.userService.getProductCart(this.apiFactory.getMakeToOrderCart, this.LoggedInData.email)
        .subscribe((data: any) => {
          if (data != null) {
            mtoCart = data?.product;
            this.cartList = mtoCart?.concat(productCart);
          } else {
            this.cartList = productCart;
          }
          // this.localStorage.setItem("avct_item", JSON.stringify(this.cartList));
        });
    }, 500);
  }

  getWishlistProduct() {
    this.googleCustomer = JSON.parse(this.localStorage.getItem("google_loggedIn_details"));

    this.wishList = [];

    this.wishListSubscription = this.userService.getProductCart(this.apiFactory.getProductWishlist, this.LoggedInData.email)
      .subscribe((data: any) => {
        if (data != null) {
          this.wishList = data.product;
          // this.localStorage.setItem("avf_item", JSON.stringify(this.wishList));
        }
      });
  }

  saveWishListProducts() {
    this.googleCustomerLocal = JSON.parse(this.localStorage.getItem("google_loggedIn_details"));

    const a: Product[] = JSON.parse(this.localStorage.getItem("avf_item")) || [];
    var updatedCart: Product[] = [];

    a.forEach((x) => {
      var isCheck: boolean = false;
      this.wishList.forEach((y) => {
        if (x.product_Code == y.product_Code) {
          isCheck = true;
          //updatedCart = updatedCart.concat(x);
        }
      });
      if (!isCheck) {
        updatedCart = a.concat(this.wishList);
      }
    });

    this.wishlistDetails.product = updatedCart;

    if (this.googleCustomerLocal != null) {
      this.wishlistDetails.status = "Not placed";
      this.wishlistDetails.logInCustomerEmail = this.googleCustomer.email;
    }

    if (this.wishlistDetails.product.length != 0) {
      this.wishListSubscription = this.userService.insertWishlistSetUp(this.apiFactory.insertWishlist, this.wishlistDetails)
        .subscribe((data: any) => { });
    }

    if (a.length != 0) {
      this.localStorage.setItem("avf_item", JSON.stringify(updatedCart));
    } else {
      this.localStorage.setItem("avf_item", JSON.stringify(this.wishList));
    }
  }

  generateUniqueID() {
    var s = [];
    var hexDigits = "0123456789";
    for (var i = 0; i < 6; i++) {
      let num: number = Math.floor(1 + Math.random() * 9);
      s[i] = hexDigits.substr(num, 1);
    }
    var uuid = s.join("");
    return uuid;
  }
}
