import { Component, OnInit } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { APIFactoryEndPoints } from "src/app/common/apifactory-end-points";
import { GstSaleInvCumBosDto } from "src/app/shared/models/gstsaleinvcumbosdto";
import { ProductOrderSummary } from "src/app/shared/models/productordersummary";
import { AddressService } from "src/app/shared/services/address.service";
import { ProductOrderSummaryService } from "src/app/shared/services/product-order-summary.service";
import { ProductService } from "src/app/shared/services/product.service";
import { ToastService } from "src/app/shared/services/toastr.service";
import { environment } from "src/environments/environment";
import * as moment from "moment";
import { Subscription } from "rxjs";
import { WebOrderMinimumDateTimeSetup } from "src/app/shared/models/weborderminimumdatetimesetup";
import { } from "src/app/shared/models/productswithweborderoffers";
import { ViewWebOrderDeliverySetUpDto } from "src/app/shared/models/viewweborderdeliverysetUpdto";
import { ProductCartDto } from "src/app/shared/models/productcartdto";
import { ViewWebOrderCouponSetUpDto } from "src/app/shared/models/viewwebordercouponsetupdto";
import { PrivacyPolicyComponent } from "../privacy-policy/privacy-policy.component";
import { MatDialog } from "@angular/material/dialog";
import { WebOrderMinimumValueSetup } from "src/app/shared/models/weborderminimumvaluesetup";
import { LocalStorageService } from "src/app/common/LocalStorageService";
import { getWindow } from "ssr-window";
import { HttpErrorResponse } from "@angular/common/http";
import { WebOrderDeliveryTimeSlotSetup } from "src/app/shared/models/web-order-delivery-time-slot-setup";
import { ViewOfferSetUpDto } from "src/app/shared/models/viewoffersetupdto";
import { WebOrderDeliveryCheckDto } from "src/app/shared/models/web-order-delivery-check-dto";
import { GstinDialogComponent } from "../gstin-dialog/gstin-dialog.component";
import { CustomerDeliveryAddress } from "src/app/shared/models/customerdeliveryaddress";
import { WebOrderDeliverySetupDto } from "src/app/shared/models/web-order-delivery-setup-dto";
import { DeliveryTimeDialogComponent } from "../delivery-time-dialog/delivery-time-dialog.component";
import { Product } from "src/app/shared/models/product";
import { WebOrderLocationSetup } from "src/app/shared/models/weborderlocationsetup";
import { DomSanitizer } from "@angular/platform-browser";

@Component({
  selector: "app-product-order-summary",
  templateUrl: "./product-order-summary.component.html",
  styleUrls: ["./product-order-summary.component.scss"],
})
export class ProductOrderSummaryComponent implements OnInit {

  webUrl: string;
  productOrderSummary: ProductOrderSummary = new ProductOrderSummary();
  gstSaleInvcumBOS: GstSaleInvCumBosDto = new GstSaleInvCumBosDto();
  webOrderMinimumDateTimeList: WebOrderMinimumDateTimeSetup;
  groupProduct: any = [];
  groupProduct2: any = [];

  couponCode: string;
  couponSetUp: ViewWebOrderCouponSetUpDto;

  googleId: string;
  fbId: string;

  timeSlot2: string[][] = [];
  timeSlotAvl: string[] = [];

  timeSlot: string[] = [];
  webOrderDeliverySetupDto: WebOrderDeliverySetupDto = new WebOrderDeliverySetupDto();
  deliverySetup: ViewWebOrderDeliverySetUpDto[] = [];
  webOrderDeliveryTimeSlotSetup: WebOrderDeliveryTimeSlotSetup[] = [];

  allCartTotal = 0;
  deliveryCharge: number = 0;
  grandTotal: number = 0;
  totalNetAmt = 0;
  totalSaleAmt = 0;
  totDiscount: number = 0;
  gstAmt: number = 0;
  cessAmt: number = 0;
  totTaxValue: number = 0;
  totBasePremiumFlavourPrice: number = 0;
  totPremiumFlavourPrice: number = 0;
  roundedOffAmount: number = 0;
  netAmountBeforeRoundedOff: number = 0;

  slotDiscDeliveryCharge: number = 0;
  slotPremiumDeliveryCharge: number = 0;
  distanceDeliveryCharge: number = 0;
  valueDeliveryCharge: number = 0;

  companyCode: number;
  isAgreedTerms: boolean = false;
  minimumOrdeSetup: WebOrderMinimumValueSetup;
  isAnimate: boolean = false;
  isAllAllowed: boolean = true;
  todayDate: Date = new Date();
  currentUrl: string;
  serverUrl: string;
  isShowCheckOut: boolean = false;
  allowOffer: string;
  checkout: string;
  deliveryType: string;
  customerDeliveryAddress: CustomerDeliveryAddress;
  storeLocation: WebOrderLocationSetup;
  mesageForm: UntypedFormGroup;
  note: string;

  private webOrderMinimumDateTimeSubcription: Subscription;
  private webOrderDeliverySubcription: Subscription;
  private webOrderTimeSlotSubcription: Subscription;
  private couponSubcription: Subscription;
  private webOrderMinimumOrderSubcription: Subscription;
  private processSubscription: Subscription;
  private checkServicesAllowedOrNotSubcription: Subscription;

  constructor(
    private localStorage: LocalStorageService,
    private formBuilder: UntypedFormBuilder,
    private addressService: AddressService,
    public dialog: MatDialog,
    private apiFactory: APIFactoryEndPoints,
    private toastService: ToastService,
    private productService: ProductService,
    private navRoute: Router,
    private productOrderSummaryService: ProductOrderSummaryService,
    private route: ActivatedRoute,
    private sanitizer: DomSanitizer
  ) {
    // this.serverUrl = "https://1bakeshop.com/mobitrixcartserver/";
    this.serverUrl = this.apiFactory.getServerUrl;
  }

  ngOnInit() {
    this.currentUrl = getWindow().location.hostname
    this.webUrl = environment.cartApiUrl;
    this.companyCode = JSON.parse(this.localStorage.getItem("company_code"));
    this.deliveryType = JSON.parse(this.localStorage.getItem("delivery_type"));
    this.storeLocation = JSON.parse(this.localStorage.getItem('pickup_location'));
    this.route.params.subscribe((data) => {
      this.checkout = data["id"];
    });
    this.getWebOrders();
    this.getWebOrderMinimumOrderSetUpList();
    this.onMessageInput();
  }

  onMessageInput() {
    this.mesageForm = this.formBuilder.group({
      note: ['']
    })
  }

  getWebOrders() {
    this.gstSaleInvcumBOS = this.productOrderSummaryService.getWebOrder();
  }
  messageInput(key: string, product: Product) {
    console.log(product)
    product.note = this.mesageForm.get('note').value;
  }
  getWebOrderMinimumOrderSetUpList() {
    let productOrderSummary = this.productOrderSummaryService.getOrderSummary();
    console.log(productOrderSummary);
    if (!productOrderSummary) {
      this.navRoute.navigate(["/cart-items"]);
    } else {
      console.log(this.deliveryType)
      if (this.deliveryType == 'delivery') {
        this.webOrderMinimumOrderSubcription = this.productService.getMinimumOrderList(this.currentUrl).subscribe((data) => {
          this.minimumOrdeSetup = data;
          this.getWebOrderDeliverySetUpList();
        });
      } else if (this.deliveryType == 'storePickup') {
        this.minimumOrdeSetup = new WebOrderMinimumValueSetup();
        this.minimumOrdeSetup.orderMinValue = this.storeLocation?.minSelfLife;
        this.getWebOrderDeliverySetUpList();
      } else {
        this.getWebOrderDeliverySetUpList();
      }
      // this.webOrderMinimumOrderSubcription = this.productService.getMinimumOrderList(this.currentUrl).subscribe((data) => {
      //   this.minimumOrdeSetup = data;

      //   this.getWebOrderDeliverySetUpList();
      // });
    }
  }

  getWebOrderDeliverySetUpList() {
    let locId: number = JSON.parse(this.localStorage.getItem("location_code"));
    let customerDeliveryAddress: CustomerDeliveryAddress = this.productOrderSummaryService.getCustomerDeliveryAddress();
    this.customerDeliveryAddress = customerDeliveryAddress;
    if (!customerDeliveryAddress && !this.checkout) {
      this.navRoute.navigate(["/cart-items"]);
    }
    if (!this.checkout) {
      let longitude: number = Number(customerDeliveryAddress.longitude);
      let latitude: number = Number(customerDeliveryAddress.latitude);

      this.webOrderDeliverySubcription = this.productService.getDeliveryList(this.currentUrl, locId, longitude, latitude).subscribe((data) => {
        this.webOrderDeliverySetupDto = data;
        this.deliverySetup = data.viewWebOrderDeliverySetUpDto;
        this.getWebOrderTimeSlotSetUpList();
      });
    } else {
      this.getWebOrderTimeSlotSetUpList();
    }
  }

  getWebOrderTimeSlotSetUpList() {
    const apiUrl = this.deliveryType === 'storePickup' ? this.apiFactory.getTimeSlotDetailsByUrlAndLocId : this.apiFactory.getTimeSlotDetailsByUrl;
    const locId = this.deliveryType === 'storePickup' ? this.storeLocation?.locationCode : null;
    this.webOrderTimeSlotSubcription = this.productService.getTimeSlotList(apiUrl, this.currentUrl, locId)
      .subscribe((data) => {
        this.webOrderDeliveryTimeSlotSetup = data;
        if (this.deliveryType == 'storePickup') {
          this.webOrderDeliveryTimeSlotSetup.forEach(e => {
            e.deliveryDiscount = null;
            e.deliveryPremium = null;
          })
        }
        data.forEach((element, index) => {
          let arr: string[] = [];
          arr.push(element.fromTime);
          arr.push(element.toTime);

          this.timeSlot2.push(arr);
          this.webOrderDeliveryTimeSlotSetup[index].timeSlot = element.fromTime + "-" + element.toTime;
        });
        this.timeSlot2.forEach((e) => {
          let slotHourFrom = e[0].split(":")[0];
          let slotMinFrom = e[0].split(":")[1];
          let slotHourTo = e[1].split(":")[0];
          let slotMinTo = e[1].split(":")[1];

          let tSlot = slotHourFrom + ":" + slotMinFrom + "-" + slotHourTo + ":" + slotMinTo;
          this.timeSlotAvl.push(tSlot);
        });
        console.log(this.webOrderDeliveryTimeSlotSetup)
        this.getProductOrderSummaryDetails();
      });
  }

  getProductOrderSummaryDetails() {
    this.productOrderSummary = this.productOrderSummaryService.getOrderSummary();
    if (this.productOrderSummary == undefined) {
      this.navRoute.navigate(["/cart-items"]);
    } else {
      var maxDeliveryDate: number = 0;
      var date: any;
      var mtoOrder: boolean = false;

      this.productOrderSummary.cartProducts.forEach((element) => {
        element.defaultDate = element.deliveryDate;
        element.defaultTimeSlotAvl = element.timeSlotAvl;

        if (element.cartProductType == "Make To Order") {
          mtoOrder = true;
          element.taxAmount = element.orderQuantityKgs * element?.taxAmount;
          element.cessAmount = element.orderQuantityKgs * element?.cessAmount;
        } else {
          element.taxAmount = element.orderQuantity * element?.taxAmount;
          element.cessAmount = element.orderQuantity * element?.cessAmount;
          if (element.designForProductCode) {
            let product = this.productOrderSummary.cartProducts.find(e => e.product_Code == element.designForProductCode);
            product.designTax = element.tax;
            product.designTaxAmount = element.taxAmount;
            product.designCessAmount = element.cessAmount;
          }
        }
        date = new Date(maxDeliveryDate);
      });
      this.localStorage.setItem("mto_order", JSON.stringify(mtoOrder));
      setTimeout(() => {
        this.sortProductByDate(false);
      }, 1000);
    }
  }

  sortProductByDate(dateChanged: boolean) {
    this.allCartTotal = 0;
    let arrayProducts: any = JSON.parse(JSON.stringify(this.productOrderSummary.cartProducts));

    // this gives an object with dates as keys
    const groups = arrayProducts.reduce(
      (groups, game) => {
        const date = game.deliveryDate;
        const dateTime = date.concat(game.deliveryTime);
        if (!groups[dateTime]) {
          groups[dateTime] = [];
        }
        groups[dateTime].push(game);
        return groups;
      },
      {}
    );

    // Edit: to add it in the array format instead
    const groupArrays = Object.keys(groups).map((dateTime) => {
      this.totalNetAmt = 0;
      this.totalSaleAmt = 0;
      this.totDiscount = 0;
      this.totTaxValue = 0;
      this.gstAmt = 0;
      this.cessAmt = 0;
      this.totBasePremiumFlavourPrice = 0;
      this.totPremiumFlavourPrice = 0;
      this.roundedOffAmount = 0;
      this.netAmountBeforeRoundedOff = 0;

      groups[dateTime].forEach((element) => {
        // let hour = Number(element.deliveryTime.split(":")[0]);
        if (dateChanged == false) {
          element.selectedDate = element.defaultDate;
        }

        let currTime = moment(new Date()).format("HH:mm");
        let hour = Number(currTime.split(":")[0]);
        let totTime: number = Number(hour * 60) + Number(currTime.split(":")[1].split(" ")[0]);

        element.timeSlotAvl = [];

        if (element.defaultDate < element.selectedDate) {
          this.timeSlot2.forEach((e) => {
            let slotHourFrom = e[0].split(":")[0];
            let slotMinFrom = e[0].split(":")[1];
            let slotHourTo = e[1].split(":")[0];
            let slotMinTo = e[1].split(":")[1];

            let tSlot = slotHourFrom + ":" + slotMinFrom + "-" + slotHourTo + ":" + slotMinTo;
            element.timeSlotAvl.push(tSlot);
          });
        } else {
          this.timeSlot2.forEach((e) => {
            let slotHourFrom = e[0].split(":")[0];
            let slotMinFrom = e[0].split(":")[1];
            let slotHourTo = e[1].split(":")[0];
            let slotMinTo = e[1].split(":")[1];

            let fromSlotTime = Number(slotHourFrom) * 60;
            let toSlotTime = Number(slotHourTo) * 60;

            let currDateMto = element.deliveryDate;
            let currDate = moment(new Date().getDate() + element.minimum_Order_Time).format("YYYY-MM-DD");

            if (currDateMto == currDate) {
              if (totTime >= fromSlotTime && totTime <= toSlotTime) {
              } else if (totTime <= fromSlotTime) {
                let tSlot: any = slotHourFrom + ":" + slotMinFrom + "-" + slotHourTo + ":" + slotMinTo;
                element.timeSlotAvl.push(tSlot);
              }
            } else {
              if (element.cartProductType == "Make To Order") {
                if (element.freeByProductCode) {
                  element.totalAmount = element.subTotal;
                }
                this.todayDate.setDate(new Date().getDate() + element.minimum_Order_Time);
                let todayDate = moment(this.todayDate).format("YYYY-MM-DD");
                if (todayDate == currDateMto) {
                  if (totTime >= fromSlotTime && totTime <= toSlotTime) {
                    // let tSlot: any = slotHourFrom + ":" + slotMinFrom + "-" + slotHourTo + ":" + slotMinTo;
                    // element.timeSlotAvl.push(tSlot);
                    // console.log('Same date ===>', tSlot)
                  } else if (totTime <= fromSlotTime) {
                    let tSlot: any = slotHourFrom + ":" + slotMinFrom + "-" + slotHourTo + ":" + slotMinTo;
                    element.timeSlotAvl.push(tSlot);
                  }
                } else {
                  let tSlot = slotHourFrom + ":" + slotMinFrom + "-" + slotHourTo + ":" + slotMinTo;
                  element.timeSlotAvl.push(tSlot);
                }
              } else {
                let todayDate = moment(currDateMto).format("YYYY-MM-DD");
                if (todayDate == currDateMto) {
                  if (totTime >= fromSlotTime && totTime <= toSlotTime) {
                    // let tSlot: any = slotHourFrom + ":" + slotMinFrom + "-" + slotHourTo + ":" + slotMinTo;
                    // element.timeSlotAvl.push(tSlot);
                  } else if (totTime <= fromSlotTime) {
                    let tSlot: any = slotHourFrom + ":" + slotMinFrom + "-" + slotHourTo + ":" + slotMinTo;
                    element.timeSlotAvl.push(tSlot);
                  }
                } else {
                  let tSlot = slotHourFrom + ":" + slotMinFrom + "-" + slotHourTo + ":" + slotMinTo;
                  element.timeSlotAvl.push(tSlot);
                }
              }
            }
          });
        }

        if (element.orderQuantityKgs == null) {
          this.totalSaleAmt += Number((element.orderQuantity * element.sale_Price).toFixed(2));
          if (element.discountAmt) {
            this.totDiscount += Number((element?.discountAmt * element.orderQuantity).toFixed(2));
          }
          if (element.taxAmount) {
            this.gstAmt += Number((element?.taxAmount).toFixed(2));
          }
          if (element.cessAmount) {
            this.cessAmt += Number((element?.cessAmount).toFixed(2));
          }
          this.totTaxValue = (this.totalSaleAmt + this.totBasePremiumFlavourPrice) - this.totDiscount;
          this.totalNetAmt = this.totTaxValue + this.gstAmt + this.cessAmt;
        } else {
          this.totalSaleAmt += Number((element.orderQuantityKgs * element.sale_Price).toFixed(2));
          this.totBasePremiumFlavourPrice += Number((element.basePremiumFlavourPrice * element.orderQuantityKgs).toFixed(2));
          this.totPremiumFlavourPrice += Number((element.premiumFlavourPrice * element.orderQuantityKgs).toFixed(2));
          if (element.discountAmt) {
            this.totDiscount += Number((element?.discountAmt * element.orderQuantityKgs).toFixed(2));
          }
          if (element.taxAmount) {
            this.gstAmt += Number((element?.taxAmount).toFixed(2));
          }
          if (element.cessAmount) {
            this.cessAmt += Number((element?.cessAmount).toFixed(2));
          }
          this.totTaxValue = (this.totalSaleAmt + this.totBasePremiumFlavourPrice) - this.totDiscount;
          this.totalNetAmt = this.totTaxValue + this.gstAmt + this.cessAmt;
        }

        this.deliveryCharge = 0;
        this.distanceDeliveryCharge = 0;
        this.slotDiscDeliveryCharge = 0;
        this.slotPremiumDeliveryCharge = 0;
        this.valueDeliveryCharge = 0;
        this.deliverySetup.forEach((c) => {
          if (Number(this.totalNetAmt.toFixed(0)) >= c.fromValue && Number(this.totalNetAmt.toFixed(0)) <= c.toValue) {
            this.deliveryCharge = Number((c.deliveryCharge).toFixed(2)) + Number((this.webOrderDeliverySetupDto?.deliveryChargeByDistance).toFixed(2));
            this.distanceDeliveryCharge = Number((this.webOrderDeliverySetupDto?.deliveryChargeByDistance).toFixed(2));
            this.valueDeliveryCharge = Number((c.deliveryCharge).toFixed(2));
          }
        });
        this.webOrderDeliveryTimeSlotSetup.forEach(w => {
          if (this.deliveryType === 'storePickup') {
            // For storePickup, do not apply any discounts or premiums, just allow the offer
            this.allowOffer = "1";
          } else if (this.deliveryType === 'delivery' && element.deliveryTime == w.timeSlot) {
            // Apply discounts/premiums only in delivery mode when timeSlot matches
            this.slotDiscDeliveryCharge = Number(((this.deliveryCharge * w.deliveryDiscount) / 100).toFixed(0));
            this.slotPremiumDeliveryCharge = Number(((this.deliveryCharge * w.deliveryPremium) / 100).toFixed(0));
            this.deliveryCharge = this.deliveryCharge + this.slotPremiumDeliveryCharge - this.slotDiscDeliveryCharge;
            this.allowOffer = w.allowOffer;
          }
        });

        this.roundedOffAmount = Math.round(this.totalNetAmt) - this.totalNetAmt;
        this.netAmountBeforeRoundedOff = this.totalNetAmt;
        this.totalNetAmt = Math.round(this.totalNetAmt);
        this.grandTotal = this.totalNetAmt + this.deliveryCharge;
      });

      return {
        dateTime,
        games: groups[dateTime],
        totalSaleAmt: this.totalSaleAmt,
        totBasePremiumFlavourPrice: this.totBasePremiumFlavourPrice,
        totPremiumFlavourPrice: this.totPremiumFlavourPrice,
        totDiscount: this.totDiscount,
        totTaxValue: this.totTaxValue,
        gstAmt: this.gstAmt,
        cessAmt: this.cessAmt,
        totalNetAmt: this.totalNetAmt,
        roundedOffAmount: this.roundedOffAmount,
        netAmountBeforeRoundedOff: this.netAmountBeforeRoundedOff,
        deliveryCharge: this.deliveryCharge,
        valueDeliveryCharge: this.valueDeliveryCharge,
        slotDiscDeliveryCharge: this.slotDiscDeliveryCharge,
        slotPremiumDeliveryCharge: this.slotPremiumDeliveryCharge,
        distanceDeliveryCharge: this.distanceDeliveryCharge,
        deliveryAddressRefNo: this.productOrderSummary.deliveryAddressRefNo,
        allowOffer: this.allowOffer,
        grandTotal: this.grandTotal,
      };
    });
    this.groupProduct = groupArrays;
    console.log(this.groupProduct);

    this.isAllAllowed = true;
    // this.isShowCheckOut = true;
    this.groupProduct.forEach(element => {
      element.dateTime = element.dateTime.substring(0, 10) + " " + element.games[0].deliveryTime;
      this.allCartTotal += element.grandTotal;
      if (element.totalNetAmt < this.minimumOrdeSetup.orderMinValue) {
        this.isAllAllowed = false;
      }
      // this.processClick(element);
    });
    this.processClick(this.groupProduct);
    this.sanitizeProductImage();

    return this.groupProduct.sort((a, b) => {
      return <any>new Date(a.date) - <any>new Date(b.date);
    });
  }

  parseDate(dateString: string, code: number) {
    // this.selectedDate = dateString;
    this.productOrderSummary.cartProducts.forEach((element) => {
      if (element.product_Code == code) {
        element.deliveryDate = dateString;
        element.selectedDate = dateString;
      }
      else if (element.freeByProductCode == code) {
        element.deliveryDate = dateString;
        element.selectedDate = dateString;
      }
      else if (element.designForProductCode == code) {
        element.deliveryDate = dateString;
        element.selectedDate = dateString;
      }

    });
    this.sortProductByDate(true);
  }

  onTimeChange(timeSlot: string, code: number) {
    this.productOrderSummary.cartProducts.forEach((element) => {
      if (element.product_Code == code) {
        element.deliveryTime = timeSlot;
      }
      else if (element.freeByProductCode == code) {
        element.deliveryTime = timeSlot;
      }
      else if (element.designForProductCode == code) {
        element.deliveryTime = timeSlot;
      }
    });
    this.sortProductByDate(true);
  }

  sanitizeProductImage() {
    this.groupProduct.forEach(cart => {
      cart.games.forEach(product => {
        if (product.image && product.image.changingThisBreaksApplicationSecurity) {
          // Create a new sanitized property instead of replacing the original
          product.sanitizedImageUrl = this.sanitizer.bypassSecurityTrustUrl(
            product.image.changingThisBreaksApplicationSecurity
          );
        }
      });
    });
  }

  onPayClick(cart: any) {
    console.log(cart)
    if (this.isAgreedTerms) {
      // Call the checkDeliveryTime method
      const isDeliveryTimeInvalid = this.checkDeliveryTime(cart);

      if (isDeliveryTimeInvalid) {
        // If delivery time is in the past or current, show error toast
        this.toastService.showErrorToast('Delivery time is in the past. Please select a future delivery slot.');
        return;
      }

      if (this.deliveryType == 'delivery') {
        let deliveryCheckDto: WebOrderDeliveryCheckDto = new WebOrderDeliveryCheckDto();
        if (cart.length == undefined) {
          deliveryCheckDto.deliveryDateTime.push(cart.dateTime);
          deliveryCheckDto.deliveryDates.push(cart.dateTime.split(' ')[0]);
          deliveryCheckDto.deliverySlots.push(cart.dateTime.split(' ')[1]);
          deliveryCheckDto.url = this.currentUrl;
          deliveryCheckDto.locId = JSON.parse(this.localStorage.getItem("location_code"));
        } else {
          cart.forEach((element1) => {
            deliveryCheckDto.deliveryDateTime.push(element1.dateTime);
            deliveryCheckDto.deliveryDates.push(element1.dateTime.split(' ')[0]);
            deliveryCheckDto.deliverySlots.push(element1.dateTime.split(' ')[1]);
            deliveryCheckDto.url = this.currentUrl;
            deliveryCheckDto.locId = JSON.parse(this.localStorage.getItem("location_code"));
          });
        }
        this.checkServicesAllowedOrNotSubcription = this.productService.checkServiceAllowedOrNot(this.apiFactory.checkServicesAllowedOrNotByDeliveryTime, deliveryCheckDto)
          .subscribe((data) => {
            if (data && data == 'Success') {
              this.getDeliveryAddress(cart);
            } else {
              this.toastService.showErrorToast(data);
            }
          });
      } else if (this.deliveryType == 'storePickup') {
        this.getDeliveryAddress(cart);
      } else {
      }
    } else {
      this.isAnimate = true;
      this.toastService.showErrorToast("Please agreed to the terms and conditions");
      setTimeout(() => {
        this.isAnimate = false;
      }, 500);
    }
  }

  getDeliveryAddress(cart: any) {
    console.log(cart);
    this.gstSaleInvcumBOS = new GstSaleInvCumBosDto();
    this.productOrderSummaryService.setWebOrder(this.gstSaleInvcumBOS);

    let gstSaleInvcumBOSArray: GstSaleInvCumBosDto[] = [];
    this.productOrderSummaryService.setWebOrderArray(gstSaleInvcumBOSArray);

    let currTime = moment(new Date()).format("HH:mm");

    if (cart.length == undefined) {
      this.gstSaleInvcumBOS.notes = "Bill From Order";
      this.gstSaleInvcumBOS.invoiceType = "B2C";
      this.gstSaleInvcumBOS.billType = "Web Order";
      this.gstSaleInvcumBOS.status = "Confirmed";
      this.gstSaleInvcumBOS.shippingCharge = cart.deliveryCharge;
      this.gstSaleInvcumBOS.offerId = cart.offerId;

      var arr = new Array();
      cart.games.forEach((element) => {
        if (!element.freeByProductCode) {
          let productCartDto: ProductCartDto = new ProductCartDto();
          productCartDto.promotionOfferId = element.promotionOfferId;
          productCartDto.offerId = element.offerId;
          productCartDto.productCode = element.product_Code;
          productCartDto.productName = element.product_Name;
          if (element.cartProductType == "Make To Order") {
            productCartDto.qty = element.orderQuantityKgs;
          } else {
            productCartDto.qty = element.orderQuantity;
          }
          productCartDto.basePremiumFlavourPrice = element.basePremiumFlavourPrice;
          productCartDto.premiumFlavourPrice = element.premiumFlavourPrice;
          productCartDto.price = element.sale_Price + element.basePremiumFlavourPrice;
          productCartDto.total = productCartDto.qty * productCartDto.price;
          if (element.discountRate === undefined || element.discountRate == null) {
            productCartDto.discPercent = 0.0;
            productCartDto.discAmt = 0.0;
          } else {
            productCartDto.discPercent = element.discountRate;
            productCartDto.discAmt = element.discountAmt;
          }
          if (element.offerDiscRate) {
            productCartDto.offerDiscRate = element.offerDiscRate;
            productCartDto.offerDiscAmt = element.offerDiscAmt;
          }

          this.productOrderSummary.cartProducts.forEach((e) => {
            if (e.product_Code == element.product_Code) {
              productCartDto.deliveryDate = e.deliveryDate;
              productCartDto.deliveryTime = e.deliveryTime;
            }
          });

          productCartDto.baseAmt = productCartDto.total - productCartDto.discAmt;
          productCartDto.netAmt = element.net_Sale_Price * productCartDto.qty + productCartDto.premiumFlavourPrice * productCartDto.qty;
          productCartDto.sgstRate = element.tax?.taxRate / 2;
          productCartDto.cgstRate = element.tax?.taxRate / 2;
          productCartDto.igstRate = 0.0;
          productCartDto.ugstRate = 0.0;
          productCartDto.cessRate = element.cess?.cessRate;

          productCartDto.taxRate = element.tax?.taxRate;
          productCartDto.discountRate = element.discountRate;
          productCartDto.cartProductType = element.cartProductType;
          productCartDto.color = element.color;
          productCartDto.shape = element.shape;
          productCartDto.composition = element.composition;
          productCartDto.dimension = element.dimension;
          productCartDto.note = element.note;
          productCartDto.productUnit = element.product_Unit;
          productCartDto.productType = element.product_Type;
          productCartDto.maxDiscountPercentage = element.max_Discount_Percentage;
          productCartDto.hsnCode = element.hsn_Code;
          productCartDto.subGroupCode = productCartDto.subGroupCode = element.productSubGroup ? element.productSubGroup.refNo : element.subGroupCode;
          productCartDto.fileNames = element.fileNames;
          productCartDto.productPicture = element.product_Picture;
          if (element.deliveryTime.substring(0, 5) <= currTime.substring(0, 5)) {
            let currTime = moment(new Date().setMinutes(1)).format("HH:mm");
            productCartDto.dateOfDelivery = moment(new Date(element.deliveryDate)).format("YYYY-MM-DD") + " " + currTime.substring(0, 5) + ":00";
          }
          else
            productCartDto.dateOfDelivery = moment(new Date(element.deliveryDate)).format("YYYY-MM-DD") + " " + element.deliveryTime.substring(0, 5) + ":00";

          arr.push(productCartDto);
        } else {
          let productCartDto: ProductCartDto = new ProductCartDto();
          if (element.cartProductType == "Make To Order") {
            productCartDto.promotionOfferId = element.promotionOfferId;
            productCartDto.offerId = element.offerId;
            productCartDto.freeByProductCode = element.freeByProductCode;
            productCartDto.productCode = element.product_Code;
            productCartDto.productName = element.product_Name;
            productCartDto.cartProductType = element.cartProductType;
            productCartDto.productUnit = element.product_Unit;
            productCartDto.productType = element.product_Type;
            productCartDto.hsnCode = element.hsn_Code;
            productCartDto.subGroupCode = element.subGroupCode;
            productCartDto.qty = element.orderQuantity;
            productCartDto.price = element.rate;
            productCartDto.total = productCartDto.qty * productCartDto.price;
            productCartDto.discPercent = 0.0;
            productCartDto.discAmt = 0.0;
            productCartDto.baseAmt = productCartDto.total - productCartDto.discAmt;
            productCartDto.netAmt = element.net_Sale_Price;
            productCartDto.taxRate = 0.0;
            productCartDto.sgstRate = 0.0;
            productCartDto.cgstRate = 0.0;
            productCartDto.igstRate = 0.0;
            productCartDto.ugstRate = 0.0;
            productCartDto.cessRate = 0.0;
            productCartDto.fileNames = element.fileNames;
            productCartDto.productPicture = element.product_Picture;
            productCartDto.rate = element.rate;
            productCartDto.maxDiscountPercentage = element.max_Discount_Percentage;
            productCartDto.netAmountForLocation = element.netAmountForLocation;
            productCartDto.totalAmount = element.subTotal;
            this.productOrderSummary.cartProducts.forEach((e) => {
              if (e.product_Code == element.product_Code) {
                productCartDto.deliveryDate = e.deliveryDate;
                productCartDto.deliveryTime = e.deliveryTime;
              }
            });
            if (element.deliveryTime.substring(0, 5) <= currTime.substring(0, 5)) {
              let currTime = moment(new Date().setMinutes(1)).format("HH:mm");
              productCartDto.dateOfDelivery = moment(new Date(element.deliveryDate)).format("YYYY-MM-DD") + " " + currTime.substring(0, 5) + ":00";
            }
            else
              productCartDto.dateOfDelivery = moment(new Date(element.deliveryDate)).format("YYYY-MM-DD") + " " + element.deliveryTime.substring(0, 5) + ":00";

            arr.push(productCartDto);
          } else {
            productCartDto.promotionOfferId = element.promotionOfferId;
            productCartDto.offerId = element.offerId;
            productCartDto.freeByProductCode = element.freeByProductCode;
            productCartDto.productCode = element.product_Code;
            productCartDto.productName = element.product_Name;
            productCartDto.cartProductType = element.cartProductType;
            productCartDto.productUnit = element.product_Unit;
            productCartDto.productType = element.product_Type;
            productCartDto.hsnCode = element.hsn_Code;
            productCartDto.subGroupCode = productCartDto.subGroupCode = element.productSubGroup ? element.productSubGroup.refNo : element.subGroupCode;
            productCartDto.qty = element.orderQuantity;
            productCartDto.price = element.sale_Price;
            productCartDto.total = productCartDto.qty * productCartDto.price;
            productCartDto.discPercent = 0.0;
            productCartDto.discAmt = 0.0;
            productCartDto.baseAmt = productCartDto.total - productCartDto.discAmt;
            productCartDto.netAmt = element.net_Sale_Price;
            productCartDto.taxRate = 0.0;
            productCartDto.sgstRate = 0.0;
            productCartDto.cgstRate = 0.0;
            productCartDto.igstRate = 0.0;
            productCartDto.ugstRate = 0.0;
            productCartDto.cessRate = 0.0;
            productCartDto.fileNames = element.fileNames;
            productCartDto.productPicture = element.product_Picture;
            this.productOrderSummary.cartProducts.forEach((e) => {
              if (e.product_Code == element.product_Code) {
                productCartDto.deliveryDate = e.deliveryDate;
                productCartDto.deliveryTime = e.deliveryTime;
              }
            });
            if (element.deliveryTime.substring(0, 5) <= currTime.substring(0, 5)) {
              let currTime = moment(new Date().setMinutes(1)).format("HH:mm");
              productCartDto.dateOfDelivery = moment(new Date(element.deliveryDate)).format("YYYY-MM-DD") + " " + currTime.substring(0, 5) + ":00";
            }
            else
              productCartDto.dateOfDelivery = moment(new Date(element.deliveryDate)).format("YYYY-MM-DD") + " " + element.deliveryTime.substring(0, 5) + ":00";

            arr.push(productCartDto);
          }
        }
      });
      this.gstSaleInvcumBOS.details = arr;
      this.gstSaleInvcumBOS.customerDeliveryAddress = this.productOrderSummaryService.getCustomerDeliveryAddress();
      this.gstSaleInvcumBOS.shippingTaxRate = this.webOrderDeliverySetupDto?.viewWebOrderGstSetupDto?.gstRate;
      this.gstSaleInvcumBOS.shippingCessRate = this.webOrderDeliverySetupDto?.viewWebOrderGstSetupDto?.cessRate;
      this.gstSaleInvcumBOS.shippingHsnCode = this.webOrderDeliverySetupDto?.viewWebOrderGstSetupDto?.hsnCode;
      this.gstSaleInvcumBOS.shippingType = this.webOrderDeliverySetupDto?.viewWebOrderGstSetupDto?.productType;
      this.gstSaleInvcumBOS.shippingUnit = this.webOrderDeliverySetupDto?.viewWebOrderGstSetupDto?.productUnit;
      this.gstSaleInvcumBOS.shippingMaxDiscountPercentage = this.webOrderDeliverySetupDto?.viewWebOrderGstSetupDto?.maxDiscountPercentage;
      this.gstSaleInvcumBOS.netAmountBeforeRoundedOff = cart.netAmountBeforeRoundedOff;
      this.gstSaleInvcumBOS.roundedOffAmount = cart.roundedOffAmount;
      this.productOrderSummaryService.setWebOrder(this.gstSaleInvcumBOS);
      this.productOrderSummary.shippingCharge = cart.deliveryCharge;
      this.productOrderSummary.totalPrice = cart.grandTotal;
    } else {
      cart.forEach((element1) => {
        var gstSale: GstSaleInvCumBosDto = new GstSaleInvCumBosDto();
        gstSale.notes = "Bill From Order";
        gstSale.invoiceType = "B2C";
        gstSale.billType = "Web Order";
        gstSale.status = "Confirmed";
        gstSale.shippingCharge = element1.deliveryCharge;
        gstSale.grandTotal = element1.grandTotal;
        gstSale.offerId = element1.offerId;

        var arr = new Array();
        element1.games.forEach((element) => {
          if (!element.freeByProductCode) {
            let productCartDto: ProductCartDto = new ProductCartDto();
            productCartDto.promotionOfferId = element.promotionOfferId;
            productCartDto.offerId = element.offerId;
            productCartDto.designForProductCode = element.designForProductCode;
            productCartDto.productCode = element.product_Code;
            productCartDto.productName = element.product_Name;
            if (element.cartProductType == "Make To Order") {
              productCartDto.qty = element.orderQuantityKgs;
            } else {
              productCartDto.qty = element.orderQuantity;
            }
            productCartDto.basePremiumFlavourPrice = element.basePremiumFlavourPrice;
            productCartDto.premiumFlavourPrice = element.premiumFlavourPrice;
            productCartDto.price = element.sale_Price + element.basePremiumFlavourPrice;
            productCartDto.total = productCartDto.qty * productCartDto.price;
            if (element.discountRate === undefined || element.discountRate == null) {
              productCartDto.discPercent = 0.0;
              productCartDto.discAmt = 0.0;
            } else {
              productCartDto.discPercent = element.discountRate;
              productCartDto.discAmt = element.discountAmt;
            }
            if (element.offerDiscRate) {
              productCartDto.offerDiscRate = element.offerDiscRate;
              productCartDto.offerDiscAmt = element.offerDiscAmt;
            }

            this.productOrderSummary.cartProducts.forEach((e) => {
              if (e.product_Code == element.product_Code) {
                productCartDto.deliveryDate = e.deliveryDate;
                productCartDto.deliveryTime = e.deliveryTime;
              }
            });

            productCartDto.baseAmt = productCartDto.total - productCartDto.discAmt;
            productCartDto.netAmt = element.net_Sale_Price * productCartDto.qty + productCartDto.premiumFlavourPrice * productCartDto.qty;
            productCartDto.sgstRate = element.tax?.taxRate / 2;
            productCartDto.cgstRate = element.tax?.taxRate / 2;
            productCartDto.igstRate = 0.0;
            productCartDto.ugstRate = 0.0;
            productCartDto.cessRate = element.cess?.cessRate;

            productCartDto.taxRate = element.tax?.taxRate;
            productCartDto.discountRate = element.discountRate;
            productCartDto.cartProductType = element.cartProductType;
            productCartDto.color = element.color;
            productCartDto.shape = element.shape;
            productCartDto.composition = element.composition;
            productCartDto.dimension = element.dimension;
            productCartDto.note = element.note;
            productCartDto.productUnit = element.product_Unit;
            productCartDto.productType = element.product_Type;
            productCartDto.maxDiscountPercentage = element.max_Discount_Percentage;
            productCartDto.hsnCode = element.hsn_Code;
            productCartDto.subGroupCode = productCartDto.subGroupCode = element.productSubGroup ? element.productSubGroup.refNo : element.subGroupCode;
            productCartDto.fileNames = element.fileNames;
            productCartDto.productPicture = element.product_Picture;
            if (element.deliveryTime.substring(0, 5) <= currTime.substring(0, 5)) {
              let currTime = moment(new Date().setMinutes(1)).format("HH:mm");
              productCartDto.dateOfDelivery = moment(new Date(element.deliveryDate)).format("YYYY-MM-DD") + " " + currTime.substring(0, 5) + ":00";
            }
            else
              productCartDto.dateOfDelivery = moment(new Date(element.deliveryDate)).format("YYYY-MM-DD") + " " + element.deliveryTime.substring(0, 5) + ":00";

            arr.push(productCartDto);
          } else {
            let productCartDto: ProductCartDto = new ProductCartDto();
            productCartDto.promotionOfferId = element.promotionOfferId;
            productCartDto.offerId = element.offerId;
            productCartDto.freeByProductCode = element.freeByProductCode;
            productCartDto.designForProductCode = element.designForProductCode;
            productCartDto.productCode = element.product_Code;
            productCartDto.productName = element.product_Name;
            productCartDto.cartProductType = element.cartProductType;
            productCartDto.productUnit = element.product_Unit;
            productCartDto.productType = element.product_Type;
            productCartDto.hsnCode = element.hsn_Code;
            productCartDto.subGroupCode = productCartDto.subGroupCode = element.productSubGroup ? element.productSubGroup.refNo : element.subGroupCode;
            productCartDto.qty = element.orderQuantity;
            productCartDto.price = element.sale_Price;
            productCartDto.total = productCartDto.qty * productCartDto.price;
            productCartDto.discPercent = 0.0;
            productCartDto.discAmt = 0.0;
            productCartDto.baseAmt = productCartDto.total - productCartDto.discAmt;
            productCartDto.netAmt = element.net_Sale_Price;
            productCartDto.taxRate = 0.0;
            productCartDto.sgstRate = 0.0;
            productCartDto.cgstRate = 0.0;
            productCartDto.igstRate = 0.0;
            productCartDto.ugstRate = 0.0;
            productCartDto.cessRate = 0.0;
            productCartDto.fileNames = element.fileNames;
            productCartDto.productPicture = element.product_Picture;
            this.productOrderSummary.cartProducts.forEach((e) => {
              if (e.product_Code == element.product_Code) {
                productCartDto.deliveryDate = e.deliveryDate;
                productCartDto.deliveryTime = e.deliveryTime;
              }
            });
            if (element.deliveryTime.substring(0, 5) <= currTime.substring(0, 5)) {
              let currTime = moment(new Date().setMinutes(1)).format("HH:mm");
              productCartDto.dateOfDelivery = moment(new Date(element.deliveryDate)).format("YYYY-MM-DD") + " " + currTime.substring(0, 5) + ":00";
            }
            else
              productCartDto.dateOfDelivery = moment(new Date(element.deliveryDate)).format("YYYY-MM-DD") + " " + element.deliveryTime.substring(0, 5) + ":00";

            arr.push(productCartDto);
          }
        });
        gstSale.details = arr;
        gstSale.customerDeliveryAddress = this.productOrderSummaryService.getCustomerDeliveryAddress();
        gstSale.shippingTaxRate = this.webOrderDeliverySetupDto?.viewWebOrderGstSetupDto?.gstRate;
        gstSale.shippingCessRate = this.webOrderDeliverySetupDto?.viewWebOrderGstSetupDto?.cessRate;
        gstSale.shippingHsnCode = this.webOrderDeliverySetupDto?.viewWebOrderGstSetupDto?.hsnCode;
        gstSale.shippingType = this.webOrderDeliverySetupDto?.viewWebOrderGstSetupDto?.productType;
        gstSale.shippingUnit = this.webOrderDeliverySetupDto?.viewWebOrderGstSetupDto?.productUnit;
        gstSale.shippingMaxDiscountPercentage = this.webOrderDeliverySetupDto?.viewWebOrderGstSetupDto?.maxDiscountPercentage;
        gstSale.netAmountBeforeRoundedOff = element1.netAmountBeforeRoundedOff;
        gstSale.roundedOffAmount = element1.roundedOffAmount;
        gstSaleInvcumBOSArray.push(gstSale);
      });
      this.productOrderSummaryService.setWebOrderArray(gstSaleInvcumBOSArray);
      this.productOrderSummary.shippingCharge = this.deliveryCharge;
      this.productOrderSummary.totalPrice = this.allCartTotal;
    }

    this.dialog.open(GstinDialogComponent, {
      width: "350px",
      autoFocus: false,
    });

    // this.googleId = JSON.parse(this.localStorage.getItem("google_id"));
    // this.fbId = JSON.parse(this.localStorage.getItem("fb_id"));

    // if (this.googleId != null || this.fbId != null) {
    //   this.navRoute.navigate(["/delivery-address"]);
    // } else {
    //   this.navRoute.navigate(["/login", "checkout"]);
    // }


  }

  processClick(cartList: any) {
    this.isShowCheckOut = false;
    let offerCartList: any[] = [];
    this.allCartTotal = 0;
    cartList.forEach(cart => {
      if (cart.allowOffer && cart.allowOffer == '1') {
        cart.discount = cart.totDiscount;
        cart.totalPaid = 0;
        cart.details = cart.games.filter(e => e.cartProductType == 'Product');
        cart.subTotal = 0;
        cart.details.forEach(element => {
          element.productCode = element.product_Code;
          element.productName = element.product_Name;
          element.netAmt = element.net_Sale_Price * element.orderQuantity;
          element.qty = element.orderQuantity;
          element.discAmt = element.discountAmt;
          element.discPercent = element.discountRate;
          element.total = element.sale_Price * element.orderQuantity;
          element.sgstRate = (element.tax?.taxRate ?? 0.0) / 2;
          element.cgstRate = (element.tax?.taxRate ?? 0.0) / 2;
          element.igstRate = 0.0;
          element.ugstRate = 0.0;
          element.cessRate = (element.cess?.cessRate ?? 0.0) / 2;
          cart.subTotal = cart.subTotal + element.total;
        });
        offerCartList.push(cart);
        this.allCartTotal += cart.grandTotal;
      } else {
        this.totalNetAmt = 0;
        this.totalSaleAmt = 0;
        this.totDiscount = 0;
        this.totTaxValue = 0;
        this.gstAmt = 0;
        this.cessAmt = 0;
        this.totBasePremiumFlavourPrice = 0;
        this.totPremiumFlavourPrice = 0;
        this.roundedOffAmount = 0;
        this.netAmountBeforeRoundedOff = 0;

        cart.games.forEach(element => {
          let appliedOffer: ViewOfferSetUpDto = element.offerDetails?.find(temp => temp.offerId == element.offerId);
          if (appliedOffer) {
            if (appliedOffer.schemeType == "Buy 1 get 1 Free" || appliedOffer.schemeType == "Buy X get Y Free") {
              let offerIndex = cart.games.findIndex(e => e.freeByProductCode === element.product_Code);
              if (offerIndex >= 0)
                cart.games.splice(offerIndex, 1);
            }
          }

          element.offers = null;
          element.discountAmt = 0;
          element.discountRate = 0;
          element.discPercent = 0.0;
          element.discAmt = 0.0;
          element.promotionOfferId = null;
          element.offerId = null;

          var taxableAmt = element.sale_Price + element.basePremiumFlavourPrice;
          var cgst = (element.tax.taxRate / 2 / 100) * taxableAmt;
          element.cgstAmt = cgst;
          var sgst = (element.tax.taxRate / 2 / 100) * taxableAmt;
          element.sgstAmt = sgst;
          element.taxAmount = cgst + sgst;
          element.cessAmount = (element.cess.cessRate / 100) * taxableAmt;


          if (element.orderQuantityKgs == null) {
            this.totalSaleAmt += Number((element.orderQuantity * element.sale_Price).toFixed(2));
            if (element.discountAmt) {
              this.totDiscount += Number((element?.discountAmt * element.orderQuantity).toFixed(2));
            }
            if (element.taxAmount) {
              this.gstAmt += Number((element.orderQuantity * element?.taxAmount).toFixed(2));
            }
            if (element.cessAmount) {
              this.cessAmt += Number((element.orderQuantity * element?.cessAmount).toFixed(2));
            }
            this.totTaxValue = (this.totalSaleAmt + this.totBasePremiumFlavourPrice) - this.totDiscount;
            this.totalNetAmt = this.totTaxValue + this.gstAmt + this.cessAmt;
          } else {
            this.totalSaleAmt += Number((element.orderQuantityKgs * element.sale_Price).toFixed(2));
            this.totBasePremiumFlavourPrice += Number((element.basePremiumFlavourPrice * element.orderQuantityKgs).toFixed(2));
            this.totPremiumFlavourPrice += Number((element.premiumFlavourPrice * element.orderQuantityKgs).toFixed(2));
            if (element.discountAmt) {
              this.totDiscount += Number((element?.discountAmt * element.orderQuantityKgs).toFixed(2));
            }
            if (element.taxAmount) {
              this.gstAmt += Number((element.orderQuantityKgs * element?.taxAmount).toFixed(2));
            }
            if (element.cessAmount) {
              this.cessAmt += Number((element.orderQuantityKgs * element?.cessAmount).toFixed(2));
            }
            this.totTaxValue = (this.totalSaleAmt + this.totBasePremiumFlavourPrice) - this.totDiscount;
            this.totalNetAmt = this.totTaxValue + this.gstAmt + this.cessAmt;
          }

          this.deliveryCharge = 0;
          this.deliverySetup.forEach((c) => {
            if (Number(this.totalNetAmt.toFixed(0)) >= c.fromValue && Number(this.totalNetAmt.toFixed(0)) <= c.toValue) {
              this.deliveryCharge = Number((c.deliveryCharge).toFixed(2)) + Number((this.webOrderDeliverySetupDto?.deliveryChargeByDistance).toFixed(2));
            }
          });
          this.webOrderDeliveryTimeSlotSetup.forEach(w => {
            if (this.deliveryType === 'storePickup') {
              this.allowOffer = "1";
            } else if (this.deliveryType === 'delivery' && element.deliveryTime == w.timeSlot) {
              this.deliveryCharge = this.deliveryCharge + Number(((this.deliveryCharge * w.deliveryPremium) / 100).toFixed(0)) - Number(((this.deliveryCharge * w.deliveryDiscount) / 100).toFixed(0));
              this.allowOffer = w.allowOffer;
            }
          });
          this.roundedOffAmount = Math.round(this.totalNetAmt) - this.totalNetAmt;
          this.netAmountBeforeRoundedOff = this.totalNetAmt;
          this.totalNetAmt = Math.round(this.totalNetAmt);
          this.grandTotal = this.totalNetAmt + this.deliveryCharge;
        });

        cart.totalSaleAmt = this.totalSaleAmt;
        cart.totBasePremiumFlavourPrice = this.totBasePremiumFlavourPrice;
        cart.totPremiumFlavourPrice = this.totPremiumFlavourPrice;
        cart.totDiscount = this.totDiscount;
        cart.totTaxValue = this.totTaxValue;
        cart.gstAmt = this.gstAmt;
        cart.cessAmt = this.cessAmt;
        cart.totalNetAmt = this.totalNetAmt;
        cart.roundedOffAmount = this.roundedOffAmount;
        cart.netAmountBeforeRoundedOff = this.netAmountBeforeRoundedOff;
        cart.deliveryCharge = this.deliveryCharge;
        cart.allowOffer = this.allowOffer;
        cart.grandTotal = this.grandTotal;
        this.allCartTotal += cart.grandTotal;

      }
    });
    if (offerCartList.length > 0) {
      this.processSubscription = this.productService.cashRegProcess(this.apiFactory.summaryOfferOperation, offerCartList).subscribe((dataList: any) => {
        this.isShowCheckOut = true;
        dataList.forEach((data, index) => {
          if (data) {
            let cart = cartList.filter(e => e.allowOffer == '1')[index];
            if (data.discount) {
              let totTaxAmt = 0;
              let totCessAmt = 0;
              cart.details?.forEach(element => {
                if (data.details.some(temp => temp.productCode == element.productCode)) {
                  let discProduct = data.details.find(e => e.productCode == element.productCode);
                  element.offerDiscAmt = discProduct.offerDiscAmt;
                  element.offerDiscRate = discProduct.offerDiscRate;
                  element.cgstRate = discProduct.cgstRate;
                  element.sgstRate = discProduct.sgstRate;
                  element.taxableValue = discProduct.taxableValue;
                }
                element.cessAmt = (element.cessRate / 100) * element.taxableValue;
                element.cgstAmt = (element.cgstRate / 100) * element.taxableValue;
                element.sgstAmt = (element.sgstRate / 100) * element.taxableValue;
                element.taxAmount = element.cgstAmt + element.sgstAmt;
                totTaxAmt += Number((element?.taxAmount).toFixed(2));
                totCessAmt += Number((element?.cessAmount).toFixed(2));
              });

              cart.offerId = data.offerId;
              cart.discountApplied = ' (' + 'Applied: ' + data.cartOffers[0]?.schemeName + ')';
              cart.offerDiscAmt = Number((data?.discount).toFixed(2));
              cart.offerDiscRate = data?.offerDiscRate;
              cart.totDiscount = Number((cart.discount + cart.offerDiscAmt).toFixed(2));
              cart.totTaxValue = (cart.totalSaleAmt + cart.totBasePremiumFlavourPrice) - cart.totDiscount;
              cart.gstAmt = totTaxAmt;
              cart.cessAmt = totCessAmt;
              cart.totalNetAmt = cart.totTaxValue + cart.gstAmt + cart.cessAmt;

              cart.deliveryCharge = 0;
              this.deliverySetup.forEach((c) => {
                if (cart.totalNetAmt >= c.fromValue && cart.totalNetAmt <= c.toValue) {
                  cart.deliveryCharge = Number((c.deliveryCharge).toFixed(2)) + Number((this.webOrderDeliverySetupDto?.deliveryChargeByDistance).toFixed(2));
                }
              });
              this.webOrderDeliveryTimeSlotSetup.forEach(w => {
                if (cart.dateTime.split(' ')[1] == w.timeSlot) {
                  cart.deliveryCharge = cart.deliveryCharge + Number(((cart.deliveryCharge * w.deliveryPremium) / 100).toFixed(0)) - Number(((cart.deliveryCharge * w.deliveryDiscount) / 100).toFixed(0));
                  cart.allowOffer = w.allowOffer;
                }
              });

              // cart.deliveryCharge = 0;
              // this.deliverySetup.forEach((c) => {
              //   if (cart.totalNetAmt >= c.fromValue && cart.totalNetAmt <= c.toValue) {
              //     cart.deliveryCharge = Number((c.deliveryCharge).toFixed(2)) + Number((c.deliveryChargeByDistance).toFixed(2));
              //   }
              // });
              cart.roundedOffAmount = Math.round(cart.totalNetAmt) - cart.totalNetAmt;
              cart.netAmountBeforeRoundedOff = cart.totalNetAmt;
              cart.totalNetAmt = Math.round(cart.totalNetAmt);
              this.allCartTotal = this.allCartTotal - cart.grandTotal;
              cart.grandTotal = cart.totalNetAmt + cart.deliveryCharge;
              this.allCartTotal = cart.grandTotal + this.allCartTotal;
            } else if (data.details.length > cart.details?.length) {
              let freeItem = data.details.find(e => e.baseAmt == 0 && !e.productName.includes('Buy X get Y Free') && !e.productName.includes('Buy 1 get 1 Free'))
              freeItem.product_Code = freeItem.productCode;
              freeItem.product_Name = freeItem.productName + ' (' + data.cartOffers[0]?.schemeType + ')';
              freeItem.product_Unit = freeItem.productUnit;
              freeItem.sale_Price = freeItem.netAmt;
              freeItem.net_Sale_Price = freeItem.netAmt;
              freeItem.basePremiumFlavourPrice = 0;
              freeItem.premiumFlavourPrice = 0;
              freeItem.orderQuantity = 1;
              freeItem.qty = 1;
              freeItem.tax = freeItem.productdetail?.tax;
              freeItem.cess = freeItem.productdetail?.cess;
              freeItem.food_Type = freeItem.productdetail?.food_Type;
              freeItem.hsn_Code = freeItem.productdetail?.hsn_Code;
              freeItem.max_Discount_Percentage = freeItem.productdetail?.max_Discount_Percentage;
              freeItem.product_Type = freeItem.productdetail?.product_Type;
              let anyCartItem = cart.details.find(e => e.sale_Price > 0);
              freeItem.defaultDate = anyCartItem.defaultDate;
              freeItem.deliveryDate = anyCartItem.deliveryDate;
              freeItem.deliveryTime = anyCartItem.deliveryTime;
              freeItem.cartProductType = anyCartItem.cartProductType;

              cart.offerId = data.offerId;
              cart.details.push(freeItem);
              cart.games.push(freeItem);
            }
          }
        });
      },
        (error: HttpErrorResponse) => {
          this.isShowCheckOut = true;
        });
    } else
      this.isShowCheckOut = true;

  }

  // Method to check if the cart's delivery time is in the past
  checkDeliveryTime(cart: any): boolean {
    // Check if cart is an array
    if (Array.isArray(cart)) {
      // Handle array case, iterate over each element (if needed)
      for (let element of cart) {
        const cartDateTime = element.dateTime; // Assuming dateTime is available in the element
        const cartDateTimeParts = cartDateTime.split(' '); // Split into [date, timeRange]
        const date = cartDateTimeParts[0]; // "2024-12-02"
        const timeRange = cartDateTimeParts[1]; // "19:00-21:00"
        const [startTime, endTime] = timeRange.split('-'); // Split into ["19:00", "21:00"]

        // Construct a full datetime string for the start time (e.g., "2024-12-02 19:00")
        const startDateTime = `${date} ${startTime}`;

        // Get current datetime
        const currentDateTime = moment(); // Moment will default to the current date and time
        const cartStartTime = moment(startDateTime, "YYYY-MM-DD HH:mm");

        // Compare the start time of the cart with the current time
        if (cartStartTime.isBefore(currentDateTime) || cartStartTime.isSame(currentDateTime)) {
          // If any of the cart start times are less than or equal to current time, return true
          return true;
        }
      }
    } else {
      // Handle object case (cart is a single object)
      const cartDateTime = cart.dateTime;
      const cartDateTimeParts = cartDateTime.split(' '); // Split into [date, timeRange]
      const date = cartDateTimeParts[0]; // "2024-12-02"
      const timeRange = cartDateTimeParts[1]; // "19:00-21:00"
      const [startTime, endTime] = timeRange.split('-'); // Split into ["19:00", "21:00"]

      // Construct a full datetime string for the start time (e.g., "2024-12-02 19:00")
      const startDateTime = `${date} ${startTime}`;

      // Get current datetime
      const currentDateTime = moment(); // Moment will default to the current date and time
      const cartStartTime = moment(startDateTime, "YYYY-MM-DD HH:mm");

      // Compare the start time of the cart with the current time
      if (cartStartTime.isBefore(currentDateTime) || cartStartTime.isSame(currentDateTime)) {
        // If the cart start time is less than or equal to current time, return true
        return true;
      }
    }

    // Return false if all delivery times are valid (future time)
    return false;
  }

  applayCoupon() {
    this.couponSubcription = this.productService.getCouponAmountByCouponCode(this.currentUrl, this.couponCode).subscribe((data) => {
      this.couponSetUp = data;
      if (data != null) {
        if (this.grandTotal >= this.couponSetUp.billingAmount) {
          // this.onDiscountSelect(this.couponSetUp.couponAmount);
        } else {
          this.toastService.showErrorToast("Coupon applicable must be purchase " + this.couponSetUp.billingAmount);
        }
      } else {
        this.toastService.showErrorToast("Invalid Coupon");
      }
    });
  }

  openPrivacyPolicyDialog() {
    const dialogRef = this.dialog.open(PrivacyPolicyComponent, {
      width: "700px",
      height: "750px",
      data: "generalTerms",
      autoFocus: false,
      panelClass: ["animate__animated", "animate__slideInUp"],
    });

    dialogRef.afterClosed().subscribe((result) => { });
  }

  onAgreedTermsChange(checked: boolean) {
  }

  openDeliveryTimeDialog(product: Product) {
    const dialogRef = this.dialog.open(DeliveryTimeDialogComponent, {
      width: '430px',
      height: '410px',
      maxWidth: '90vw',
      autoFocus: false,
      data: { product: JSON.parse(JSON.stringify(product)), deliveryTimeSlotSetup: this.webOrderDeliveryTimeSlotSetup },
      panelClass: 'custom-dialog-container',
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log(result)
      if (result) {
        this.parseDate(result.data.deliveryDate, result.data.product_Code);
        this.onTimeChange(result.data.deliveryTime, result.data.product_Code);
      }
    });
  }

  convertToAmPm(timeRange: string): string {
    if (timeRange.includes('-')) {
      const times = timeRange.split('-');
      const startTime = this.formatTime(times[0]);
      const endTime = this.formatTime(times[1]);
      return `${startTime} - ${endTime}`;
    } else {
      return timeRange;
    }
  }

  formatTime(time: string): string {
    const [hour, minute] = time.split(':');
    const date = new Date();
    date.setHours(+hour);
    date.setMinutes(+minute);
    return new Intl.DateTimeFormat('en-US', {
      hour: 'numeric',
      minute: 'numeric',
      hour12: true
    }).format(date);
  }

  getDeliveryChargeTooltip(cart: any): string {
    let tooltip = '';

    if (cart.valueDeliveryCharge > 0) {
      tooltip += `Delivery Charge for Value: ${cart.valueDeliveryCharge}\n`;
    }
    if (cart.distanceDeliveryCharge > 0) {
      tooltip += `Delivery Charge for Distance: ${cart.distanceDeliveryCharge}\n`;
    }
    if (cart.slotDiscDeliveryCharge > 0) {
      tooltip += `Discount on Delivery Charge: ${cart.slotDiscDeliveryCharge}\n`;
    }
    if (cart.slotPremiumDeliveryCharge > 0) {
      tooltip += `Premium on Delivery Charge: ${cart.slotPremiumDeliveryCharge}`;
    }

    return tooltip.trim(); // Remove any trailing newline characters
  }


}
