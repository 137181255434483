import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, Subscription } from "rxjs";
import { APIFactoryEndPoints } from "src/app/common/apifactory-end-points";
import { BulkDiscountDto } from "../models/bulkdiscountdto";
import { CartDetails } from "../models/cartdetails";
import { GoogleCustomer } from "../models/googlecustomer";
import { MakeProductDto } from "../models/makeproductdto";
import { MakeToOrderDetails } from "../models/maketoorderdetails";
// import {
//   AngularFireDatabase,
//   AngularFireList,
//   AngularFireObject,
// } from "@angular/fire/database";
import { Product } from "../models/product";
import { ProductGroupWithIdAndName } from "../models/productgroupwithidandname";
import { ProductSubGroupDtoWithIdName } from "../models/productsubgroupdtowithidname";
import { ProductsWithWebOrderOffers } from "../models/productswithweborderoffers";
import { ViewWebOrderCouponSetUpDto } from "../models/viewwebordercouponsetupdto";
import { ViewWebOrderDeliverySetUpDto } from "../models/viewweborderdeliverysetUpdto";
import { WebOrderCustomizeGroupSetup } from "../models/webordercustomizegroupsetup";
import { WebOrderGstSetup } from "../models/webordergstsetup";
import { WebOrderMinimumValueSetup } from "../models/weborderminimumvaluesetup";
import { WishListDetails } from "../models/wishlistdetails";
import { AuthService } from "./auth.service";
import { ToastService } from "./toastr.service";
import { UserService } from "./user.service";
import * as moment from "moment";
import { AdvanceSearchDTO } from "../models/advance-search-dto";
import { catchError } from "rxjs/operators";
import { HandleError, HttpErrorHandlerService, } from "./http-error-handler.service";
import { LocalStorageService } from "src/app/common/LocalStorageService";
import { MatrixDto } from "../models/matrix-dto";
import { ProductSearchDetail } from "../models/product-search-detail";
import { B2bCustomer } from "../models/b2b-customer";
import { WebOrderMinimumDateTimeSetup } from "../models/weborderminimumdatetimesetup";
import { WebOrderDeliverySetupDto } from "../models/web-order-delivery-setup-dto";
import { Router } from "@angular/router";

@Injectable({
  providedIn: "root",
})
export class ProductService {



  public cartListSubject = new BehaviorSubject([]);
  public toggleCartSubject = new BehaviorSubject(false);

  cartDetails: CartDetails = new CartDetails();
  mtoCartDetails: CartDetails = new CartDetails();

  wishlistDetails: WishListDetails = new WishListDetails();
  shoppingCartData: Product[] = [];
  // products: AngularFireList<Product>;
  // product: AngularFireObject<Product>;

  // favouriteProducts
  // favouriteProducts: AngularFireList<FavouriteProduct>;
  // cartProducts: AngularFireList<FavouriteProduct>;

  // constructor(
  //   private db: AngularFireDatabase,
  //   private authService: AuthService,
  //   private toastrService: ToastService
  // ) {}

  makeProduct: MakeProductDto;
  googleCustomer: GoogleCustomer;
  cartList: Product[] = [];

  private handleError: HandleError;

  private cartSetUpSubscription: Subscription;
  private wishlistSetUpSubscription: Subscription;

  constructor(
    private localStorage: LocalStorageService,
    // private db: AngularFireDatabase,
    private authService: AuthService,
    private toastrService: ToastService,
    private apiFactory: APIFactoryEndPoints,
    private http: HttpClient,
    private userService: UserService,
    httpErrorHandler: HttpErrorHandlerService,
    private navRoute: Router,
  ) {
    this.handleError = httpErrorHandler.createHandleError("ProductService");
  }

  // private initData() {
  //   this.loadShoppingCart();
  // }

  // getProducts() {
  //   // this.products = this.db.list("products");
  //   // return this.products;
  // }
  allProductListMto(currentUrl: string) {
    return this.http.get<string>(this.apiFactory.getWebOrderCustomizeDetails + "/" + currentUrl);
  }
  getMTODetailsForSEO(currentUrl: string) {
    return this.http.get<string>(this.apiFactory.getMTODetailsForSEO + "/" + currentUrl);
  }

  getWebOrderCustomizeList(currentUrl: string) {
    return this.http.get<WebOrderCustomizeGroupSetup[]>(this.apiFactory.getWebOrderCustomizeDetails + "/" + currentUrl);
  }

  getProductCodeDetails(currentUrl: string) {
    return this.http.get<number[]>(this.apiFactory.getProductCodeDetails + "/" + currentUrl);
  }

  // getProductDetailsByProductCode(url: string, productCode: number[]) {
  //   return this.http.get<Product[]>(url + '/' + productCode)
  // }

  getProductDetailsByProductCode(url: string, currentUrl: string) {
    return this.http.get<Product[]>(url + "/" + currentUrl);
  }

  getRelevantProductDetailsByProductCode(url: string) {
    return this.http.get<Product[]>(url);
  }

  getRelevantProductByCartProductCode(url: string, productCode: number[], currentUrl: string) {
    return this.http.get<Product[]>(url + "/" + productCode + "/" + currentUrl);
  }

  getFilterRelevantProductDetailsByProductCode(productCode: number[]) {
    return this.http.get<Product[]>(this.apiFactory.getFilterRelevantProductDetailsByProductCode + "/" + productCode);
  }

  getMakeToOrderList(url: string) {
    return this.http.get<MakeToOrderDetails>(this.apiFactory.getMakeToOrder + "/" + url);
  }

  getMatrixList(currentUrl: string) {
    return this.http.get<MatrixDto[]>(this.apiFactory.getMatrixDetails + "/" + currentUrl);
  }

  getMtoMatrixList(currentUrl: string) {
    return this.http.get<MatrixDto[]>(this.apiFactory.getMtoMatrixDetails + "/" + currentUrl);
  }

  getGroupListByMatrix(url: string, currentUrl: string, matrixCode: number) {
    return this.http.get<ProductGroupWithIdAndName[]>(url + "/" + currentUrl + "/" + matrixCode);
  }

  getGroupList(currentUrl: string) {
    return this.http.get<ProductGroupWithIdAndName[]>(this.apiFactory.getGroupDetails + "/" + currentUrl);
  }

  getProducts(pageNo: number) {
    return this.http.get<Product[]>(this.apiFactory.getAllProducts + "/pageno/" + pageNo + "/pagesize/" + 20);
  }

  getProductById(id: number): Observable<any> {
    return this.http.get(this.apiFactory.getProductById + "/" + id);
  }

  getSubGroupDetailsByGroupCode(url: string, prodGroupId: number) {
    return this.http.get<ProductSubGroupDtoWithIdName[]>(
      url + "/" + prodGroupId
    );
  }

  getProductDetailsBySubGroupCode(url: string, prodSubGroupId: number) {
    return this.http.get<Product[]>(url + "/" + prodSubGroupId);
  }

  getProductDetailsByMatrix(url: string, prodSubGroupId: number, currentUrl: string) {
    return this.http.get<Product[]>(url + "/" + prodSubGroupId + "/" + currentUrl);
  }

  getOfferAllowFor(url: string, currentUrl: string, type: string) {
    return this.http.get<string>(url + "/" + currentUrl + "/" + type);
  }

  getOfferDetails(url: string, locId: any, productCode: number[], currentUrl: string) {
    return this.http.get<any>(
      url + "/" + locId + "/" + productCode + "/" + currentUrl
    );
  }

  getStockDetails(url: string, productCode: number[], locId: number) {
    return this.http.get<any>(
      url + "/" + productCode + "/" + locId
    );
  }

  checkPincode(url: string, pincode: number, currentUrl: string) {
    return this.http.get<boolean>(url + "/" + pincode + "/" + currentUrl);
  }

  getCompanyByLocId(url: string, locId: number) {
    return this.http.get<any>(url + "/" + locId);
  }

  checkAllowStockByLocAndUrl(url: string, locId: number, currUrl: string) {
    return this.http.get<any>(url + "/" + locId + "/" + currUrl);
  }

  getCouponAmountByCouponCode(currentUrl: string, couponCode: string): Observable<any> {
    return this.http.get<ViewWebOrderCouponSetUpDto>(
      this.apiFactory.getCouponAmountByCouponCode + "/" + currentUrl + "/" + couponCode);
  }

  getProductOfferDetails(url: string, offerId: string, qty: number) {
    return this.http.get<Product>(url + "/" + offerId + "/" + qty);
  }

  getProductDiscountDetails(url: string, offerId: string, qty: number) {
    return this.http.get<number>(url + "/" + offerId + "/" + qty);
  }

  getProductBulkDiscountDetails(url: string, offerId: string) {
    return this.http.get<BulkDiscountDto>(url + "/" + offerId);
  }

  getGstList(currentUrl: string) {
    return this.http.get<WebOrderGstSetup>(
      this.apiFactory.getGstDetails + "/" + currentUrl
    );
  }

  getMinimumOrderList(currentUrl: string) {
    return this.http.get<WebOrderMinimumValueSetup>(
      this.apiFactory.getMinimumOrderDetails + "/" + currentUrl
    );
  }

  getDeliveryList(currentUrl: string, locId: number, longitude: number, latitude: number) {
    return this.http.get<WebOrderDeliverySetupDto>(
      this.apiFactory.getDeliverySetupDetails + "/" + currentUrl + "/" + locId + "/" + longitude + "/" + latitude
    );
  }

  getTimeSlotList(url: string, currentUrl: string, locId?: number) {
    let finalUrl = locId ? `${url}/${currentUrl}/${locId}` : `${url}/${currentUrl}`;
    return this.http.get<any[]>(finalUrl);
  }

  getProductSearchByName(url: string, currentUrl: string, name: string) {
    return this.http.get<Product[]>(url + "/" + currentUrl + "/" + name);
  }

  cashRegProcess(url: string, posData: any) {
    return this.http.post(url, posData);
  }

  checkServiceAllowedOrNot(url: string, posData: any) {
    return this.http.post<string>(url, posData);
  }

  // createProduct(data: Product, callback: () => void) {
  //   this.products.push(data);
  //   callback();
  // }

  // getProductById(key: string) {
  //   this.product = this.db.object("products/" + key);
  //   return this.product;
  // }

  // updateProduct(data: Product) {
  //   this.products.update(data.$key, data);
  // }

  // deleteProduct(key: string) {
  //   this.products.remove(key);
  // }

  /*
   ----------  Favourite Product Function  ----------
  */

  // Get Favourite Product based on userId
  // async getUsersFavouriteProduct() {
  //   const user = await this.authService.user$.toPromise();
  //   this.favouriteProducts = this.db.list("favouriteProducts", (ref) =>
  //     ref.orderByChild("userId").equalTo(user.$key)
  //   );
  //   return this.favouriteProducts;
  // }

  // Adding New product to favourite if logged else to localStorage
  addFavouriteProduct(data: Product): void {
    this.googleCustomer = JSON.parse(this.localStorage.getItem("google_loggedIn_details"));
    let locationCode: number = JSON.parse(this.localStorage.getItem("location_code"));

    const a: Product[] = JSON.parse(this.localStorage.getItem("avf_item")) || [];

    if (a.some((temp) => temp.product_Code == data.product_Code)) {
      this.toastrService.showErrorToast(data.product_Name + " is allready added to wishlist");
    } else {
      a.push(data);

      //save Wishlist Data to Mongo DB
      this.wishlistDetails.product = a;

      if (this.googleCustomer != null) {
        this.wishlistDetails.status = "Not placed";
        this.wishlistDetails.logInCustomerEmail = this.googleCustomer.email;
        this.wishlistDetails.locationCode = locationCode;

        this.wishlistSetUpSubscription = this.userService.insertWishlistSetUp(this.apiFactory.insertWishlist, this.wishlistDetails)
          .subscribe((data: any) => { });
        this.toastrService.wait("Adding Product", data.product_Name + " added to wishlist");
        setTimeout(() => {
          this.localStorage.setItem("avf_item", JSON.stringify(a));
        }, 1500);
      } else {
        setTimeout(() => {
          this.localStorage.setItem("avf_item", JSON.stringify(a));
        }, 1500);
      }
    }
  }

  addFavouriteProducts(products: Product[]): void {
    this.googleCustomer = JSON.parse(this.localStorage.getItem("google_loggedIn_details"));
    let locationCode: number = JSON.parse(this.localStorage.getItem("location_code"));

    const wishlist: Product[] = JSON.parse(this.localStorage.getItem("avf_item")) || [];
    let newProducts: Product[] = [];

    products.forEach((product) => {
      if (wishlist.some((temp) => temp.product_Code == product.product_Code)) {
        this.toastrService.showErrorToast(product.product_Name + " is already added to wishlist");
      } else {
        wishlist.push(product);
        newProducts.push(product);
      }
    });

    if (newProducts.length > 0) {
      this.wishlistDetails.product = wishlist;

      if (this.googleCustomer != null) {
        this.wishlistDetails.status = "Not placed";
        this.wishlistDetails.logInCustomerEmail = this.googleCustomer.email;
        this.wishlistDetails.locationCode = locationCode;

        this.wishlistSetUpSubscription = this.userService.insertWishlistSetUp(this.apiFactory.insertWishlist, this.wishlistDetails)
          .subscribe((data: any) => {
            this.toastrService.wait("Adding Products", newProducts.length + " products added to wishlist");
            this.localStorage.setItem("avf_item", JSON.stringify(wishlist));
          });
      } else {
        this.toastrService.wait("Adding Products", newProducts.length + " products added to wishlist");
        this.localStorage.setItem("avf_item", JSON.stringify(wishlist));
      }
    }
  }

  // Fetching unsigned users favourite proucts
  getLocalFavouriteProducts(): Product[] {
    const products: Product[] = JSON.parse(this.localStorage.getItem("avf_item")) || [];

    return products;
  }

  // Removing Favourite Product from Database
  // removeFavourite(key: string) {
  //   this.favouriteProducts.remove(key);
  // }

  // Removing Favourite Product from this.localStorage
  removeLocalFavourite(product: any) {
    this.googleCustomer = JSON.parse(this.localStorage.getItem("google_loggedIn_details"));
    const products: Product[] = JSON.parse(this.localStorage.getItem("avf_item"));

    for (let i = 0; i < products.length; i++) {
      if (products[i].product_Code === product) {
        products.splice(i, 1);
        break;
      }
    }

    if (this.googleCustomer != null) {
      this.cartSetUpSubscription = this.userService.removeProductCart(this.apiFactory.removeWishlist, this.googleCustomer.email, product)
        .subscribe((data: any) => { });
    }
    // ReAdding the products after remove
    this.localStorage.setItem("avf_item", JSON.stringify(products));
  }

  // private getthis.localStorage(key: string) {
  //   return JSON.parse(this.localStorage.getItem(key));
  // }

  // private loadShoppingCart() {
  //   if (this.getthis.localStorage("avct_item")) {
  //     this.shoppingCartData = this.getthis.localStorage("avct_item");
  //   }
  //   console.log('SC Data from this.localStorage', this.shoppingCartData);
  // }

  /*
   ----------  Cart Product Function  ----------
  */

  // Adding new Product to cart db if logged in else localStorage
  addToCart(data: Product): void {
    console.log(data);

    this.googleCustomer = JSON.parse(this.localStorage.getItem("google_loggedIn_details"));

    let locationCode: number = JSON.parse(this.localStorage.getItem("location_code"));

    // this.getCartProduct(data);
    // console.log(this.cartList)

    const a: Product[] = JSON.parse(this.localStorage.getItem("avct_item")) || [];

    if (a.some((temp) => temp.product_Code == data.product_Code && temp.cartProductType == "Make To Order")) {
      let itemIndex = a.findIndex((item) => item.product_Code == data.product_Code);
      data.orderQuantity = 1;
      data.discountAmt = 0;
      var taxableAmt = data.sale_Price - (data.sale_Price * data.discountAmt) / 100;
      taxableAmt = taxableAmt + data.basePremiumFlavourPrice;
      var cgst = (data.tax.taxRate / 2 / 100) * taxableAmt;
      data.cgstAmt = cgst;
      var sgst = (data.tax.taxRate / 2 / 100) * taxableAmt;
      data.sgstAmt = sgst;
      data.taxAmount = cgst + sgst;
      data.cessAmount = (data.cess.cessRate / 100) * taxableAmt;
      a[itemIndex] = data;
      console.log(a);
      this.localStorage.setItem("avct_item", JSON.stringify(a));

      var mtoList1: any = [];

      if (this.googleCustomer != null) {
        a.forEach((element) => {
          if (element.cartProductType == "Make To Order") {
            element.status = "Not placed";
            mtoList1.push(element);
          }
        });

        this.mtoCartDetails.status = "Not placed";
        this.mtoCartDetails.logInCustomerEmail = this.googleCustomer.email;
        this.mtoCartDetails.locationCode = locationCode;
        this.mtoCartDetails.product = mtoList1;
      }

      if (mtoList1.length != 0) {
        this.cartSetUpSubscription = this.userService.addProductToCartWithFiles(this.apiFactory.insertMakeToOrderCart, this.mtoCartDetails)
          .subscribe((data: any) => { });
      }
      this.toastrService.showSuccessToast(data.product_Name + " updated");
    } else {
      if (a.some((temp) => temp.product_Code == data.product_Code && !temp.freeByProductCode)) {
        // this.toastrService.showErrorToast(data.product_Name + " is allready added to cart");
      } else {
        data.orderQuantity = 1;
        data.discountAmt = 0;
        if (data.cartProductType == "Product") {
          data.basePremiumFlavourPrice = 0;
          data.premiumFlavourPrice = 0;
        }
        var taxableAmt = data.sale_Price - (data.sale_Price * data.discountAmt) / 100;
        taxableAmt = taxableAmt + data.basePremiumFlavourPrice;
        var cgst = (data.tax.taxRate / 2 / 100) * taxableAmt;
        data.cgstAmt = cgst;
        var sgst = (data.tax.taxRate / 2 / 100) * taxableAmt;
        data.sgstAmt = sgst;
        data.taxAmount = cgst + sgst; //Tax Amount
        data.cessAmount = (data.cess.cessRate / 100) * taxableAmt;
        a.push(data);
        //save Cart Data to Mongo DB
        // this.cartDetails.product = a;

        var productList: any = [];
        var mtoList: any = [];

        if (this.googleCustomer != null) {
          a.forEach((element) => {
            element.status = "Not placed";
            if (element.cartProductType == "Product") {
              productList.push(element);
            } else {
              mtoList.push(element);
            }
          });

          this.cartDetails.status = "Not placed";
          this.cartDetails.logInCustomerEmail = this.googleCustomer.email;
          this.cartDetails.locationCode = locationCode;
          this.cartDetails.product = productList;

          this.mtoCartDetails.status = "Not placed";
          this.mtoCartDetails.logInCustomerEmail = this.googleCustomer.email;
          this.mtoCartDetails.locationCode = locationCode;
          this.mtoCartDetails.product = mtoList;
        }

        if (productList.length != 0) {
          this.cartSetUpSubscription = this.userService.insertCartSetUp(this.apiFactory.insertProductCart, this.cartDetails)
            .subscribe((data: any) => { });
        }

        if (mtoList.length != 0) {
          this.cartSetUpSubscription = this.userService.addProductToCartWithFiles(this.apiFactory.insertMakeToOrderCart, this.mtoCartDetails)
            .subscribe((data: any) => { });
        }

        this.toastrService.wait("Adding Product to Cart", data.product_Name + " added to cart");
        setTimeout(() => {
          this.localStorage.setItem("avct_item", JSON.stringify(a));
          // this.getCartProduct(data);
        }, 500);
      }
    }
  }

  addToCartDesign(data: Product, design: Product): void {
    this.googleCustomer = JSON.parse(this.localStorage.getItem("google_loggedIn_details"));
    let locationCode: number = JSON.parse(this.localStorage.getItem("location_code"));

    const cartProducts: Product[] = JSON.parse(this.localStorage.getItem("avct_item")) || [];

    // let index = cartProducts.findIndex(e => e.product_Code === data.product_Code && !e.designForProductCode);
    // if (index >= 0)
    //   cartProducts.splice(index, 1);
    let designIndex = cartProducts.findIndex(e => e.designForProductCode === data.product_Code);
    if (designIndex >= 0)
      cartProducts.splice(designIndex, 1);

    if (cartProducts.some((temp) => temp.product_Code == data.product_Code && !temp.freeByProductCode)) {
      // this.toastrService.showSuccessToast(data.product_Name + " is updated");
      //design extra variable
      let product = cartProducts.find(temp => temp.product_Code === data.product_Code);
      product.designProductCode = design.product_Code;
      product.designProductName = design.product_Name;
      product.designProductPicture = design.product_Picture;
      product.designProductNetSalePrice = design.net_Sale_Price;
    } else {
      data.orderQuantity = 1;
      data.discountAmt = 0;
      if (data.cartProductType == "Product") {
        data.basePremiumFlavourPrice = 0;
        data.premiumFlavourPrice = 0;
      }
      var taxableAmt = data.sale_Price - (data.sale_Price * data.discountAmt) / 100;
      taxableAmt = taxableAmt + data.basePremiumFlavourPrice;
      var cgst = (data.tax.taxRate / 2 / 100) * taxableAmt;
      data.cgstAmt = cgst;
      var sgst = (data.tax.taxRate / 2 / 100) * taxableAmt;
      data.sgstAmt = sgst;
      data.taxAmount = cgst + sgst; //Tax Amount
      data.cessAmount = (data.cess.cessRate / 100) * taxableAmt;

      //design extra variable
      data.designProductCode = design.product_Code;
      data.designProductName = design.product_Name;
      data.designProductPicture = design.product_Picture;
      data.designProductNetSalePrice = design.net_Sale_Price;
      cartProducts.push(data);
    }
    //Design
    if (design.designForProductCode) {
      design.orderQuantity = 1;
      design.discountAmt = 0;
      if (design.cartProductType == "Product") {
        design.basePremiumFlavourPrice = 0;
        design.premiumFlavourPrice = 0;
      }
      var taxableAmt = design.sale_Price - (design.sale_Price * design.discountAmt) / 100;
      taxableAmt = taxableAmt + design.basePremiumFlavourPrice;
      var cgst = (design.tax.taxRate / 2 / 100) * taxableAmt;
      design.cgstAmt = cgst;
      var sgst = (design.tax.taxRate / 2 / 100) * taxableAmt;
      design.sgstAmt = sgst;
      design.taxAmount = cgst + sgst; //Tax Amount
      design.cessAmount = (design.cess.cessRate / 100) * taxableAmt;
      let index = cartProducts.findIndex(e => e.product_Code === data.product_Code && !e.designForProductCode);
      let offerIndex = cartProducts.findIndex(e => e.freeByProductCode === data.product_Code);
      if (offerIndex >= 0)
        cartProducts.splice(index + 2, 0, design);
      else
        cartProducts.splice(index + 1, 0, design);
    }

    var productList: any = [];
    if (this.googleCustomer != null) {
      cartProducts.forEach((element) => {
        element.status = "Not placed";
        if (element.cartProductType == "Product") {
          productList.push(element);
        }
      });
    }
    console.log(productList);

    this.cartDetails.status = "Not placed";
    this.cartDetails.logInCustomerEmail = this.googleCustomer?.email;
    this.cartDetails.locationCode = locationCode;
    this.cartDetails.product = productList;

    if (productList.length != 0 && this.googleCustomer != null) {
      this.cartSetUpSubscription = this.userService.insertCartSetUp(this.apiFactory.insertProductCart, this.cartDetails)
        .subscribe((data: any) => {
        });
    }
    this.toastrService.wait("Adding Product to Cart", data.product_Name + " added to cart");
    this.localStorage.setItem("avct_item", JSON.stringify(cartProducts));
  }

  addToCartOffer(cartProducts: Product[]): void {
    this.googleCustomer = JSON.parse(this.localStorage.getItem("google_loggedIn_details"));
    let locationCode: number = JSON.parse(this.localStorage.getItem("location_code"));

    console.log(cartProducts);

    var productList: any = [];
    var mtoList: any = [];

    if (this.googleCustomer != null) {
      cartProducts.forEach((element) => {
        element.status = "Not placed";
        if (element.cartProductType == "Product") {
          productList.push(element);
        } else {
          mtoList.push(element);
        }
      });
    }
    console.log(productList);

    this.cartDetails.status = "Not placed";
    this.cartDetails.logInCustomerEmail = this.googleCustomer?.email;
    this.cartDetails.locationCode = locationCode;
    this.cartDetails.product = productList;

    this.mtoCartDetails.status = "Not placed";
    this.mtoCartDetails.logInCustomerEmail = this.googleCustomer.email;
    this.mtoCartDetails.locationCode = locationCode;
    this.mtoCartDetails.product = mtoList;

    if (productList.length != 0 && this.googleCustomer != null) {
      this.cartSetUpSubscription = this.userService.insertCartSetUp(this.apiFactory.insertProductCart, this.cartDetails)
        .subscribe((data: any) => {
        });
    }
    if (mtoList.length != 0 && this.googleCustomer != null) {
      this.cartSetUpSubscription = this.userService.addProductToCartWithFiles(this.apiFactory.insertMakeToOrderCart, this.mtoCartDetails)
        .subscribe((data: any) => { });
    }
    this.localStorage.setItem("avct_item", JSON.stringify(cartProducts));

  }

  addToCartWithOffer(product: Product, offerProduct: Product, design: Product): void {
    this.googleCustomer = JSON.parse(this.localStorage.getItem("google_loggedIn_details"));
    let locationCode: number = JSON.parse(this.localStorage.getItem("location_code"));

    const cartProducts: Product[] = JSON.parse(this.localStorage.getItem("avct_item")) || [];

    if (cartProducts.some((temp) => temp.product_Code == product.product_Code)) {
      this.toastrService.showErrorToast(product.product_Name + " is allready added to cart");
      return;
    } else {
      //design extra variable
      if (design) {
        product.designProductCode = design.product_Code;
        product.designProductName = design.product_Name;
        product.designProductPicture = design.product_Picture;
        product.designProductNetSalePrice = design.net_Sale_Price;
      }
      cartProducts.push(product);
      if (offerProduct)
        cartProducts.push(offerProduct);

      //Design
      if (design && design.designForProductCode) {
        design.orderQuantity = 1;
        design.discountAmt = 0;
        if (design.cartProductType == "Product") {
          design.basePremiumFlavourPrice = 0;
          design.premiumFlavourPrice = 0;
        }
        var taxableAmt = design.sale_Price - (design.sale_Price * design.discountAmt) / 100;
        taxableAmt = taxableAmt + design.basePremiumFlavourPrice;
        var cgst = (design.tax.taxRate / 2 / 100) * taxableAmt;
        design.cgstAmt = cgst;
        var sgst = (design.tax.taxRate / 2 / 100) * taxableAmt;
        design.sgstAmt = sgst;
        design.taxAmount = cgst + sgst; //Tax Amount
        design.cessAmount = (design.cess.cessRate / 100) * taxableAmt;
        let index = cartProducts.findIndex(e => e.product_Code === product.product_Code && !e.designForProductCode);
        let offerIndex = cartProducts.findIndex(e => e.freeByProductCode === product.product_Code);
        if (offerIndex >= 0)
          cartProducts.splice(index + 2, 0, design);
        else
          cartProducts.splice(index + 1, 0, design);
      }
    }

    var productList: any = [];
    if (this.googleCustomer != null) {
      cartProducts.forEach((element) => {
        element.status = "Not placed";
        if (element.cartProductType == "Product") {
          productList.push(element);
        }
      });
    }
    console.log(productList);

    this.cartDetails.status = "Not placed";
    this.cartDetails.logInCustomerEmail = this.googleCustomer?.email;
    this.cartDetails.locationCode = locationCode;
    this.cartDetails.product = productList;

    if (productList.length != 0 && this.googleCustomer != null) {
      this.cartSetUpSubscription = this.userService.insertCartSetUp(this.apiFactory.insertProductCart, this.cartDetails)
        .subscribe((data: any) => {
        });
    }
    this.toastrService.wait("Adding Product to Cart", product.product_Name + " added to cart");
    this.localStorage.setItem("avct_item", JSON.stringify(cartProducts));
  }

  addToCartWithFiles(data: Product, offerProduct?: Product, files?: File[]): void {
    console.log(data);
    console.log(offerProduct)

    this.googleCustomer = JSON.parse(this.localStorage.getItem("google_loggedIn_details"));
    let locationCode: number = JSON.parse(this.localStorage.getItem("location_code"));

    const a: Product[] = JSON.parse(this.localStorage.getItem("avct_item")) || [];

    if (a.some((temp) => temp.product_Code == data.product_Code && temp.cartProductType == "Make To Order")) {
      let itemIndex = a.findIndex((item) => item.product_Code == data.product_Code);
      data.orderQuantity = 1;
      data.discountAmt = 0;
      var taxableAmt = data.sale_Price - (data.sale_Price * data.discountAmt) / 100;
      taxableAmt = taxableAmt + data.basePremiumFlavourPrice;
      var cgst = (data.tax.taxRate / 2 / 100) * taxableAmt;
      data.cgstAmt = cgst;
      var sgst = (data.tax.taxRate / 2 / 100) * taxableAmt;
      data.sgstAmt = sgst;
      data.taxAmount = cgst + sgst;
      data.cessAmount = (data.cess.cessRate / 100) * taxableAmt;
      a[itemIndex] = data;
      this.localStorage.setItem("avct_item", JSON.stringify(a));

      var mtoList1: any = [];

      if (this.googleCustomer != null) {
        a.forEach((element) => {
          if (element.cartProductType == "Make To Order") {
            element.status = "Not placed";
            mtoList1.push(element);
          }
        });

        this.mtoCartDetails.status = "Not placed";
        this.mtoCartDetails.logInCustomerEmail = this.googleCustomer.email;
        this.mtoCartDetails.locationCode = locationCode;
        this.mtoCartDetails.product = mtoList1;
      }

      if (mtoList1.length != 0) {
        this.cartSetUpSubscription = this.userService.addProductToCartWithFiles(this.apiFactory.insertMakeToOrderCart, this.mtoCartDetails, files)
          .subscribe((data: any) => { });
      }
      this.toastrService.showSuccessToast(data.product_Name ? data.product_Name : 'Custom Cake' + " updated");
    } else {
      if (a.some((temp) => temp.product_Code == data.product_Code && !temp.freeByProductCode)) {
      } else {
        data.orderQuantity = 1;
        data.discountAmt = 0;
        if (data.discountRate) {
          data.discountAmt = (data.discountRate / 100) * data.sale_Price;
        }
        if (data.cartProductType == "Product") {
          data.basePremiumFlavourPrice = 0;
          data.premiumFlavourPrice = 0;
        }
        var taxableAmt = data.sale_Price - (data.sale_Price * data.discountAmt) / 100;
        taxableAmt = taxableAmt + data.basePremiumFlavourPrice;
        var cgst = (data.tax.taxRate / 2 / 100) * taxableAmt;
        data.cgstAmt = cgst;
        var sgst = (data.tax.taxRate / 2 / 100) * taxableAmt;
        data.sgstAmt = sgst;
        data.taxAmount = cgst + sgst; //Tax Amount
        data.cessAmount = (data.cess.cessRate / 100) * taxableAmt;
        a.push(data);
        if (offerProduct) {
          a.push(offerProduct)
        }

        var productList: any = [];
        var mtoList: any = [];

        if (this.googleCustomer != null) {
          a.forEach((element) => {
            element.status = "Not placed";
            if (element.cartProductType == "Product") {
              productList.push(element);
            } else {
              mtoList.push(element);
            }
          });

          this.cartDetails.status = "Not placed";
          this.cartDetails.logInCustomerEmail = this.googleCustomer.email;
          this.cartDetails.locationCode = locationCode;
          this.cartDetails.product = productList;

          this.mtoCartDetails.status = "Not placed";
          this.mtoCartDetails.logInCustomerEmail = this.googleCustomer.email;
          this.mtoCartDetails.locationCode = locationCode;
          this.mtoCartDetails.product = mtoList;
        }

        if (productList.length != 0) {
          this.cartSetUpSubscription = this.userService.insertCartSetUp(this.apiFactory.insertProductCart, this.cartDetails)
            .subscribe((data: any) => { });
        }

        if (mtoList.length != 0) {
          this.cartSetUpSubscription = this.userService.addProductToCartWithFiles(this.apiFactory.insertMakeToOrderCart, this.mtoCartDetails, files)
            .subscribe((data: any) => { });
        }

        this.toastrService.wait("Adding Product to Cart", data.product_Name ? data.product_Name : 'Custom Cake' + " added to cart");
        setTimeout(() => {
          this.localStorage.setItem("avct_item", JSON.stringify(a));
        }, 500);
      }
    }
  }

  getCartProduct(productData: Product) {
    var productCart: Product[] = [];
    var mtoCart: Product[] = [];
    this.cartList = [];

    this.cartSetUpSubscription = this.userService.getProductCart(this.apiFactory.getProductCart, this.googleCustomer.email)
      .subscribe((data: any) => {
        if (data != null) {
          productCart = data.product;
          console.log(productCart);
        }
      });

    setTimeout(() => {
      this.cartSetUpSubscription = this.userService.getProductCart(this.apiFactory.getMakeToOrderCart, this.googleCustomer.email)
        .subscribe((data: any) => {
          if (data != null) {
            mtoCart = data?.product;
            this.cartList = mtoCart?.concat(productCart);
            console.log(this.cartList);
          } else {
            this.cartList = productCart;
            console.log(this.cartList);
          }
          productData.orderQuantity = 1;
          productData.discountAmt = 0;
          var taxableAmt = productData.sale_Price - (productData.sale_Price * productData.discountAmt) / 100;
          taxableAmt = taxableAmt + productData.basePremiumFlavourPrice;
          var cgst = (productData.tax.taxRate / 2 / 100) * taxableAmt;
          productData.cgstAmt = cgst;
          var sgst = (productData.tax.taxRate / 2 / 100) * taxableAmt;
          productData.sgstAmt = sgst;
          productData.taxAmount = cgst + sgst; //Tax Amount
          productData.cessAmount = (productData.cess.cessRate / 100) * taxableAmt;
          this.cartList.push(productData);
          console.log(this.cartList);
          this.localStorage.setItem("avct_item", JSON.stringify(this.cartList));
        });
    }, 500);
  }

  // Removing cart from local
  removeLocalCartProduct(product: Product) {
    this.googleCustomer = JSON.parse(this.localStorage.getItem("google_loggedIn_details"));
    console.log(product);
    const products: Product[] = JSON.parse(this.localStorage.getItem("avct_item"));

    // for (let i = 0; i < products.length; i++) {
    //   if (products[i].product_Code === product.product_Code) {
    //     products.splice(i, 1);
    //   } else if (products[i].designForProductCode === product.product_Code) {
    //     console.log(products[i]);
    //     products.splice(i, 1);
    //     console.log(products[i]);
    //   }
    // }

    let index = products.findIndex(e => e.product_Code === product.product_Code && !e.designForProductCode && !e.freeByProductCode);
    if (index >= 0)
      products.splice(index, 1);
    let designIndex = products.findIndex(e => e.designForProductCode === product.product_Code);
    if (designIndex >= 0)
      products.splice(designIndex, 1);
    let offerIndex = products.findIndex(e => e.freeByProductCode === product.product_Code);
    if (offerIndex >= 0)
      products.splice(offerIndex, 1);

    if (this.googleCustomer != null) {
      if (product.cartProductType == "Product") {
        this.cartSetUpSubscription = this.userService.removeProductCart(this.apiFactory.removeProductFromCart, this.googleCustomer.email, product.product_Code)
          .subscribe((data: any) => { });
      } else {
        this.cartSetUpSubscription = this.userService.removeProductCart(this.apiFactory.removeMtoProductFromCart, this.googleCustomer.email, product.product_Code)
          .subscribe((data: any) => { });
      }
    }

    // ReAdding the products after remove
    this.localStorage.setItem("avct_item", JSON.stringify(products));
  }

  removeDesignLocalCartProduct(product: Product) {
    this.googleCustomer = JSON.parse(this.localStorage.getItem("google_loggedIn_details"));
    console.log(product);
    const products: Product[] = JSON.parse(this.localStorage.getItem("avct_item"));

    let designIndex = products.findIndex(e => e.designForProductCode === product.product_Code);
    let design: Product = products[designIndex];
    if (designIndex >= 0)
      products.splice(designIndex, 1);

    if (this.googleCustomer != null) {
      if (product.cartProductType == "Product") {
        this.cartSetUpSubscription = this.userService.removeDesignProductCart(this.apiFactory.removeDesignProductFromCart, this.googleCustomer.email, design)
          .subscribe((data: any) => { });
      }
    }
    // ReAdding the products after remove
    this.localStorage.setItem("avct_item", JSON.stringify(products));
  }

  removeOfferLocalCartProduct(product: Product) {
    this.googleCustomer = JSON.parse(this.localStorage.getItem("google_loggedIn_details"));
    console.log(product);
    const products: Product[] = JSON.parse(this.localStorage.getItem("avct_item"));

    let offerIndex = products.findIndex(e => e.freeByProductCode === product.freeByProductCode);
    let offer: Product = products[offerIndex];
    if (offerIndex >= 0)
      products.splice(offerIndex, 1);

    if (this.googleCustomer != null) {
      if (product.cartProductType == "Product") {
        this.cartSetUpSubscription = this.userService.removeDesignProductCart(this.apiFactory.removeOfferProductFromCart, this.googleCustomer.email, offer)
          .subscribe((data: any) => { });
      }
    }
    // ReAdding the products after remove
    this.localStorage.setItem("avct_item", JSON.stringify(products));
  }

  removeAllProductsFromCart(removedProducts: Product[], updatedProducts: Product[]): void {
    this.googleCustomer = JSON.parse(this.localStorage.getItem("google_loggedIn_details"));

    if (this.googleCustomer) {
      const productIds = removedProducts.map(p => p.product_Code);

      this.cartSetUpSubscription = this.userService.removeAllProductCart(
        this.apiFactory.removeAllProductFromCart,
        this.googleCustomer.email,
        productIds
      ).subscribe((data: any) => {
        console.log("Removed products:", data);

        // After API call, update the local storage with the remaining valid products
        this.localStorage.setItem("avct_item", JSON.stringify(updatedProducts));
      });
    } else {
      // If not logged in, just update local storage with the valid products
      this.localStorage.setItem("avct_item", JSON.stringify(updatedProducts));
    }

    // Show an alert listing removed products
    this.toastrService.showWarningToast(`Some products were removed due to insufficient shelf life: ${removedProducts.map(p => p.product_Name).join(', ')}`);
  }

  // Fetching Locat CartsProducts
  getLocalCartProducts(): Product[] {
    const products: Product[] = JSON.parse(this.localStorage.getItem("avct_item")) || [];
    products.forEach((element) => {
      if (element.cartProductType == "Make To Order") {
        const today = new Date();
        if (new Date(element.deliveryDate) < today) {
          const tomorrow = new Date(today.setDate(today.getDate() + (element.minimum_Order_Time ? element.minimum_Order_Time : 0)));
          element.deliveryDate = moment(tomorrow.toString()).format("YYYY-MM-DD");
        }
      }
    });
    this.localStorage.setItem("avct_item", JSON.stringify(products));
    return products;
  }

  getLocalCartProductLength() {
    return this.getLocalCartProducts().filter(e => !e.designForProductCode).length;
  }

  toggleCart = () => {
    this.toggleCartSubject.next(!this.toggleCartSubject.getValue());
  };

  // addToCart = (cart: Product) => {
  //   let current = this.cartListSubject.getValue();
  //   let dup = current.find(c => c.product.title === cart.productName);
  //   if (dup) dup.quantity += cart.quantity;
  //   else current.push(cart);
  //   this.cartListSubject.next(current);
  // };
  reloadCart = (cartList: any[]) => {
    this.cartListSubject.next(cartList);
  };
  removeCart = (index: number) => {
    let current = this.cartListSubject.getValue();
    current.splice(index, 1);
    this.cartListSubject.next(current);
  };

  setMakeProduct(makeProductDto: MakeProductDto) {
    this.makeProduct = makeProductDto;
  }

  getMakeProduct() {
    return this.makeProduct;
  }

  getMinMaxPrice(url: string) {
    return this.http.get<any>(url);
  }

  getPromostionMaster(api: string, locationCode: number) {
    return this.http.get<any[]>(api + "/" + locationCode);
  }

  searchProductsByObj(url: string, obj: AdvanceSearchDTO) {
    return this.http.post(url, obj);
  }

  getWebOrderBestProductSetup(url: string, currentUrl: string) {
    return this.http.get<any[]>(url + "/" + currentUrl);
  }

  getWebOrderDesigns(url: string, subGroupCode: number, currentUrl: string) {
    return this.http.get<any>(
      url + "/" + subGroupCode + "/" + currentUrl
    );
  }

  getWebOrderMinimumDateTimeDetails(currentUrl: string) {
    return this.http.get<WebOrderMinimumDateTimeSetup>(
      this.apiFactory.getWebOrderMinimumDateTimeDetails + "/" + currentUrl
    );
  }

  getImage(url: string, img: string): any {
    return this.http
      .get<any>(url + img, { responseType: "arraybuffer" as "json" })
      .pipe(catchError(this.handleError("getImage", "SORRY")));
  }

  downloadImage(url: string, folderName: string, fileName: string): any {
    return this.http
      .get<any>(url + folderName + '/' + fileName, { responseType: "arraybuffer" as "json" })
      .pipe(catchError(this.handleError("getImage", "SORRY")));
  }

  getB2bCustomerByEmail(email: string) {
    return this.http.get<B2bCustomer>(this.apiFactory.getB2bCustomerByEmail + "/" + email);
  }

  navigateToCart(product: Product) {
    setTimeout(() => {
      this.navRoute.navigate(['/cart-items'], { queryParams: { code: product.product_Code } });
      return;
    }, 500);
  }
}

export class FavouriteProduct {
  product: Product;
  productId: string;
  userId: string;
}
