import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { GstSaleInvCumBosDto } from "../shared/models/gstsaleinvcumbosdto";

@Injectable({
  providedIn: "root",
})
export class APIFactoryEndPoints {

  readonly getServerUrl: string = environment.cartApiUrl;
  readonly getServerOldUrl: string = environment.oldApiUrl;

  readonly getUsersaveTokenIdLocId: string = environment.oldApiUrl + "common/User/saveTokenIdLocId";
  readonly getProductById: string = environment.apiUrl + "admin/master/view/product-details";
  readonly getMessageByMailChimp: string = environment.cartApiUrl + "get-message-by-mailchimp";
  readonly sendMessageByMailChimp: string = environment.cartApiUrl + "send-message-by-mailchimp";
  readonly insertGoogleCustomer: string = environment.cartApiUrl + "customer/insert-google";
  readonly getGoogleCustomer: string = environment.cartApiUrl + "customer/get-google";
  readonly getCustomerByPhoneNo: string = environment.cartApiUrl + "customer/get-customer-by-phone-no";
  readonly insertFBCustomer: string = environment.cartApiUrl + "customer/insert-fb";
  readonly updateCustomerStatus: string = environment.apiUrl + "customer/update-customer-status";
  readonly getStateList: string = environment.apiUrl + "admin/master/view/state/getstates";
  readonly getDistrictByStateCode: string = environment.apiUrl + "admin/master/view/location/get-position-by-state-code";
  readonly getCitiesByDistrictCode: string = environment.cartApiUrl + "get-cities-by-districtCode";
  readonly getCityByDistrictCode: string = environment.apiUrl + "admin/master/view/location/get-position-by-district-code";
  readonly getLocationByCityCode: string = environment.apiUrl + "admin/master/view/location/get-position-by-city-code";
  readonly getLocationWithPositionByLocationCode: string = environment.apiUrl + "location/get-position-by-location-code";
  readonly getCityWithPinPositionDetails: string = environment.apiUrl + "admin/master/view/location/get-pin-city-with-position";
  readonly getAllLocations: string = environment.apiUrl + "admin/master/view/location/getAllLocationsWithLatLong";
  readonly getLocationByLocationCode: string = environment.apiUrl + "admin/master/view/location/getLocationByLocId";
  readonly checkAllowStockByLocAndUrl: string = environment.apiUrl + "admin/transaction-setup/billing-setup/check-allow-stock-by-loc-and-url";
  readonly getDistrictListByStateCode: string = environment.apiUrl + "admin/master/view/location/get-by-state-code";
  readonly getCityListByDistrictCode: string = environment.apiUrl + "admin/master/view/location/get-by-district-code";

  //contact
  readonly getFeedbackSetupDetails: string = environment.oldApiUrl + "admin/feedback-setup/grievanceSetupByUrl";
  readonly insertContact: string = environment.cartApiUrl + "contact/insert";
  readonly insertFeedback: string = environment.cartApiUrl + "contact/insert-feedback";

  //franchise
  readonly insertFranchise: string = environment.cartApiUrl + "franchaise/insert";

  readonly getMatrixDetails: string = environment.apiUrl + "admin/master/view/productmatrix/getmatrixbyurl";
  readonly getMtoMatrixDetails: string = environment.apiUrl + "admin/master/view/productmatrix/getmtomatrixbyurl";
  readonly getGroupDetailsByMatrix: string = environment.apiUrl + "admin/master/view/productgroup/getproductgroupsbymatrixcode";
  readonly getMtoGroupDetailsByMatrix: string = environment.apiUrl + "admin/master/view/productgroup/getmtogroupsbymatrixcode";

  //group
  readonly getGroupDetails: string = environment.apiUrl + "admin/master/view/productgroup/getproductgroups";
  //subGroup
  readonly getSubGroupDetailsByGroupCode: string = environment.apiUrl + "admin/master/view/productsubgroup/getproductSubgroups";
  //product
  readonly getProductDetailsBySubGroupCode: string = environment.apiUrl + "admin/master/view/product-details-list";
  readonly getProductDetailsByGroupCode: string = environment.apiUrl + "admin/master/view/product-details-list-by-group";
  readonly getProductDetailsByMatrixCode: string = environment.apiUrl + "admin/master/view/product-details-list-by-matrix";

  readonly getBestProducts: string = environment.apiUrl + "admin/transaction-setup/get-web-order-best-products-by-companyid";
  readonly getBestMtoProducts: string = environment.apiUrl + "admin/transaction-setup/get-web-order-best-mto-products-by-companyid";

  //make-to-orderget-make-to-order-product-details
  readonly getMakeToOrderDetails: string = environment.apiUrl + "admin/master/view/productgroup/getMakeToOrderproductgroups";

  //check-pincode
  readonly getOfferAllowFor: string = environment.apiUrl + "admin/transaction-setup/offer-setup/get-offer-allow-for";
  readonly getOfferDetails: string = environment.cartApiUrl + "get-offer-details";

  //maketoorder(composition,color,product shape)
  readonly getMakeToOrder: string = environment.apiUrl + "admin/transaction-setup/make-to-order-setup/get-make-to-order-details";

  //couponAmountByCouponCode
  readonly getCouponAmountByCouponCode: string = environment.apiUrl + "admin/transaction-setup/coupon-setup/get-coupon-amount";

  //check-pincode
  readonly checkPincode: string = environment.apiUrl + "admin/transaction-setup/location-setup/check-correct-pincode";

  //GetCompanyByLocation
  readonly getCompanyByLocId: string = environment.oldApiUrl + "admin/master/view/company/getCompanyByLocationCode";

  //product-offer-details
  readonly productOfferDetails: string = environment.apiUrl + "admin/transaction-setup/location-setup/get-product-offers";

  //product-discount-details
  readonly productDiscountDetails: string = environment.apiUrl + "admin/transaction-setup/location-setup/get-discount-offers";

  readonly productBulkOfferDetails: string = environment.apiUrl + "admin/transaction-setup/location-setup/get-bulk-discount-offers";

  //Add Cart Details to mongo DB
  readonly insertProductCart: string = environment.cartApiUrl + "productcart/insert";
  readonly removeProductFromCart: string = environment.cartApiUrl + "productcart/remove-product";
  readonly removeDesignProductFromCart: string = environment.cartApiUrl + "productcart/remove-design-cart-product";
  readonly removeOfferProductFromCart: string = environment.cartApiUrl + "productcart/remove-offer-cart-product";
  readonly getProductCart: string = environment.cartApiUrl + "productcart/get-cart-product";
  readonly updateProductCart: string = environment.cartApiUrl + "productcart/update";

  readonly insertMakeToOrderCart: string = environment.cartApiUrl + "maketoordercart/insert";
  readonly removeMtoProductFromCart: string = environment.cartApiUrl + "maketoordercart/remove-mto-product";
  readonly removeMtoProductWithFileFromCart: string = environment.cartApiUrl + "maketoordercart/remove-mto-product-with-file";
  readonly getMakeToOrderCart: string = environment.cartApiUrl + "maketoordercart/get-cart-product";
  readonly removeAllProductFromCart: string = environment.cartApiUrl + "productcart/remove-all-product";
  readonly emptyProductCart: string = environment.cartApiUrl + "productcart/remove-all-cart-product";

  //Add Wishlist Details to mongo DB
  readonly insertWishlist: string = environment.cartApiUrl + "wishlist/insert";
  readonly getProductWishlist: string = environment.cartApiUrl + "wishlist/get-wishlist-product";
  readonly removeWishlist: string = environment.cartApiUrl + "wishlist/remove-product";

  readonly getLocationForBilling: string = environment.apiUrl + "admin/transaction-setup/billing-setup/get-billing-details-by-companyid";
  readonly sendUpiPayment: string = environment.oldApiUrl + "common/collect-pay-by-vpa-web";
  readonly getUpiTransactionDetails: string = environment.cartApiUrl + "get-upi-transaction-details";

  readonly getGstDetails: string = environment.apiUrl + "admin/transaction-setup/web-order-gst-setup/get-gst-setup-details-by-companyid";
  readonly getMinimumOrderDetails: string = environment.apiUrl + "admin/transaction-setup/web-order-min-value-setup/get-min-value-details-by-companyid";

  readonly getDeliverySetupDetails: string = environment.apiUrl + "admin/transaction-setup/delivery-setup/get-delivery-details-by-url";
  readonly getTimeSlotDetailsByUrl: string = environment.apiUrl + "admin/transaction-setup/web-order-delivery-time-slot-setup/get-time-slot-by-company-code";
  readonly getTimeSlotDetailsByUrlAndLocId: string = environment.apiUrl + "admin/transaction-setup/get-time-slot-by-url-and-locid";

  readonly getCareerSetupDetails: string = environment.apiUrl + "admin/transaction-setup/web-order-career-setup/get-career-details-by-companyid";

  readonly getFranchiseSetupDetails: string = environment.apiUrl + "admin/transaction-setup/web-order-franchise-setup/get-franchise-details-by-url";

  readonly getProductCodeDetails: string = environment.apiUrl + "admin/transaction-setup/web-order-product-setup/get-product-code";
  readonly getProductDetailsByProductCode: string = environment.apiUrl + "admin/master/view/get-product-details-list";
  readonly getWebOrderCustomizeDetails: string = environment.apiUrl + "admin/transaction-setup/web-order-customize-setup/get-web-order-customize-group-by-companyid";

  readonly getQrForUpi: string = environment.cartApiUrl + "get-qr-for-upi";

  readonly getRelevantProductDetailsByProductCode: string = environment.apiUrl + "admin/master/view/get-relevant-product-details-list";
  readonly getWebOrderMinimumDateTimeDetails: string = environment.apiUrl + "admin/transaction-setup/web-order-min-date-time-setup/get-min-date-time-details-by-companyid";

  readonly insertDeliveryDetails: string = environment.cartApiUrl + "customerDeliveryDetials/insert";
  readonly getDeliveryDetails: string = environment.cartApiUrl + "customerDeliveryDetials/get-delivery-details";
  readonly insertDeliveryDetailsApp: string = environment.oldApiUrl + "admin/master/customerdeliverydetails/insert-web";
  readonly getDeliveryDetailsByCustomerCode: string = environment.oldApiUrl + "admin/master/customerdeliverydetails/get-by-customer-code-and-pin-web";

  readonly insertWebOrder: string = environment.cartApiUrl + "insert-web-order";
  readonly insertWebOrderArray: string = environment.cartApiUrl + "insert-all-web-order";

  readonly getWebOrderInvoiceDetails: string = environment.cartApiUrl + "get-web-order-invoice-details";

  readonly getWebOrderUserSetUpDetails: string = environment.apiUrl + "admin/transaction-setup/web-order-user-setup/get-web-order-user-details";

  readonly sendMessageByMailChimpFromContact: string = environment.cartApiUrl + "send-message-by-mailchimp-from-contact";
  readonly sendMessageByMailChimpFromFranchaise: string = environment.cartApiUrl + "send-message-by-mailchimp-from-franchaise";

  readonly getWebOrderDetailsByLoggedInUser: string = environment.apiUrl + "order/web-orders/get-web-order-details-by-logged-user";
  readonly getWebOrderPaymentHistory: string = environment.apiUrl + "order/web-orders/get-payment-history";
  readonly getWebOrderFeedBackDetails: string = environment.apiUrl + "admin/transaction-setup/web-order-feed-back-setup/get-web-order-feed-back-details";
  readonly getWebOrderLocationDetails: string = environment.apiUrl + "admin/transaction-setup/location-setup/get-web-order-location-details-by";
  readonly getWebOrderAreaAndPin: string = environment.apiUrl + "admin/transaction-setup/location-setup/get-web-order-area-and-pin";
  readonly getWebOrderLocationDetailsByRefNo: string = environment.apiUrl + "admin/transaction-setup/location-setup/get-web-order-location-details-by-refno";
  readonly getFilterRelevantProductDetailsByProductCode: string = environment.cartApiUrl + "get-filter-relevant-product-by-code";
  readonly getFilterRelevantProductsByCartProduct: string = environment.cartApiUrl + "get-filter-relevant-products-by-cart-products";
  readonly insertBillingAddress: string = environment.cartApiUrl + "insertCustmerBillingAddress";
  readonly insertDeliveryAddress: string = environment.cartApiUrl + "insert-customer-delivery-details";
  readonly insertFeedBack: string = environment.oldApiUrl + "common/saveFeedBack";

  //Advance Search
  readonly productMinMaxPrice: string = environment.oldApiUrl + "admin/master/view/product/products/minmaxprice";
  readonly getPromotionMasterData: string = environment.oldApiUrl + "crm/promotions/getpromotiondataByLocForWeb";
  readonly searchProductsByObj: string = environment.oldApiUrl + "admin/master/view/product/searchProductsByObj";
  readonly getAllTax: string = environment.oldApiUrl + "admin/transaction-setup/get_tax";
  readonly getAllCess: string = environment.oldApiUrl + "admin/transaction-setup/get_cess";
  readonly getFoodType: string = environment.oldApiUrl + "admin/master/view/product/getfoodtype";

  //WhatsApp Otp
  readonly validateCustomer: string = environment.cartApiUrl + "customer/validate-customer";
  readonly sendWhatsAppOptInByMobileNo: string = environment.cartApiUrl + "sendWhatsAppOptInByMobileNo";
  readonly sendWhatsAppOTPByMobileNo: string = environment.cartApiUrl + "sendWhatsAppOTPByMobileNo";
  readonly checkOTPSentOrNot: string = environment.cartApiUrl + "checkOTPSentOrNot";
  readonly checkWhatsAppVerifiedOrNot: string = environment.apiUrl + "checkWhatsAppVerifiedOrNot";
  readonly checkOTPSentOrNotWithIpAddress: string = environment.cartApiUrl + "checkOTPSentOrNotWithIpAddress";
  readonly checkOTPSentOrNotWithIpAddressforContactus: string = environment.cartApiUrl + "checkOTPSentOrNotWithIpAddressForContactus";
  readonly getWhatsAppOTPResponseByMobileNo: string = environment.oldApiUrl + "common/integration/getWhatsAppOTPResponseByMobileNo";

  readonly ccAvenuePayment: string = environment.cartApiUrl + "api/v1/encryptData";
  readonly sendGatewayPaymentRequest: string = "https://1bs.mobitrix.net/api/v1/encryptAnyData";

  readonly insertWebOrderTemp: string = environment.cartApiUrl + "insert-weborder-temp";
  readonly getVisitorCount: string = environment.cartApiUrl + "get-visitor-count";

  //Wallet
  readonly getWalletSetupByUrl: string = environment.apiUrl + "admin/transaction-setup/web-order-wallet-offer-setup/get-web-order-wallet-offer-by-url";
  readonly insertWalletTransaction: string = environment.cartApiUrl + "insert-wallet-transaction";
  readonly getWalletTransactionByEmail: string = environment.cartApiUrl + "get-wallet-transaction-by-user-email";
  readonly getCheckedOutWalletOffers: string = environment.cartApiUrl + "wallet-offers/get-checked-out-wallet-offers";

  readonly initiateNotification: string = "https://1bs.mobitrix.net/notification/";
  readonly getClientIP: string = environment.cartApiUrl + "getClientIp";

  readonly getStockDetails: string = environment.cartApiUrl + "get-stock-details";

  readonly inserWebUserInfo: string = environment.apiUrl + "web-user-info/insert";

  readonly getWebOrderTemp: string = environment.cartApiUrl + "get-tempdata";
  readonly getGatewayPaymentSetup: string = environment.oldApiUrl + "common/admin/transaction-setup/gatewayPaymentSetupByCompanyAndProvider";

  readonly getProductByName: string = environment.apiUrl + "admin/master/view/get-product-by-name-cart";
  readonly getWebOrderDesignBySubgroupAndUrl: string = environment.apiUrl + "admin/transaction-setup/web-order-product-design-setup/get-design-by-subgroup-and-url";

  readonly getB2bCustomerByEmail: string = environment.oldApiUrl + "admin/master/view/customer/getcustomerbyemail";

  readonly cancelOrderByInvNo: string = environment.oldApiUrl + "order/order/mto/changeorderstatusandrefund";

  readonly summaryOfferOperation: string = environment.oldApiUrl + "sales/pos/cash-register/summaryOfferOperationWebOrder";

  readonly checkServicesAllowedOrNotByDeliveryTime: string = environment.apiUrl + "order/web-orders/check-services-allowed-or-not-by-delivery-time";
  readonly getMTODetailsForSEO: string = environment.apiUrl + "common/createSEOStaticContentForMTOProductsList";

  readonly getActiveStorePickupLocationByUrl: string = environment.apiUrl + "admin/transaction-setup/web-order/get-active-store-pickup-location-by-url";

  //My Coupon
  readonly getCouponDetails: string = environment.apiUrl + "crm/promotions/festival-coupon-customer/get-coupons";
  readonly getCouponHistory: string = environment.apiUrl + "crm/promotions/festival-coupon-customer/get-coupon-history";
  readonly getDynamicCouponCode: string = environment.apiUrl + "crm/promotions/festival-coupon-customer/get-dynamic-coupon-code";
  readonly getNearestLocationByLatAndLng: string = environment.apiUrl + "crm/promotions/festival-coupon-customer/get-nearest-location-by-lat-lng";
  readonly getNearestLocationsByLatAndLng: string = environment.apiUrl + "crm/promotions/festival-coupon-customer/get-nearest-location-list-by-lat-lng";
  readonly saveTermsAgreed: string = environment.apiUrl + "common/terms-agreed";
  readonly getCouponTermsAgreed: string = environment.apiUrl + "common/get-coupon-terms-agreed";
  readonly couponRatingInsert: string = environment.apiUrl + "crm/promotions/festival-coupon-customer/rating-insert";

  readonly insertCustomProduct: string = environment.cartApiUrl + "custom-product/insert";
  readonly deleteCustomProduct: string = environment.oldApiUrl + "custom-product/delete-by-refno";
  readonly getCustomProductByEmail: string = environment.apiUrl + "custom-product/get-by-email";
  readonly getCustomProductByRefNo: string = environment.apiUrl + "custom-product/get-by-refno";

  readonly makePaymentWebMisc: string = environment.apiUrl + "common/payment/make-payment-web-misc";

  readonly getLocationByLocName: string = environment.apiUrl + "admin/master/view/location/getLocationByLocName";
  readonly getFeedbackDetailsByEmail: string = environment.oldApiUrl + "crm/feedback-details/get-feedback-details-by-email";

  readonly insertB2bCustomerOnboardingDetails: string = environment.apiUrl + "admin/master/web-order/insertB2bCustomerDetails";



  readonly getAllProducts: string = environment.cartApiUrl + "get-all-products";
  readonly insertCustomerPaymentInformationDetails: string = environment.cartApiUrl + "customer-payment-information/insert";
  readonly getAssetWebOrderList: string = environment.cartApiUrl + "get-asset-web-order-list";

}
